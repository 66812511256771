import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_ORDER,ORDER_SUCCESS,EDIT_ORDER,SHOW_ORDER,CREATE_ORDER_STATUS} from './actionTypes';
import { orderSuccess, orderError,orderShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createOrder({ payload: { order } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'order/create', { 
                order_name_en : order.order_name_en,
                order_name_bn : order.order_name_bn,
                });
                if(response.success == false){
                    // alert(response.message['message']);
                    yield put(orderError(response.message.message));
                }else if(response.success == true){
                    yield put(orderSuccess(response.data.message));
                } 
          }
    } catch (error) {
        yield put(orderError(error));
    }
}

function* createOrderStatus({ payload: { orderStatus } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'order/status/create',orderStatus);
                if(response.success == false){
                    yield put(orderError(response.message.message));
                }else if(response.success == true){
                    yield put(orderSuccess(response.data.message));
                } 
          }
    } catch (error) {
        yield put(orderError(error));
    }
}


function* editOrder({ payload: { order } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'order/'+order.order_id, { 
                    order_name_en : order.order_name_en,
                    order_name_bn : order.order_name_bn,
                });
             yield put(orderSuccess(response));
          }
          
          
    } catch (error) {
        yield put(orderError(error));
    }
}


function* showOrder({ payload: { order_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'order/details/'+order_id);
             yield put(orderShow(response));
          }
          
          
    } catch (error) {
        yield put(orderError(error));
    }
}

export function* watchOrder() {
    yield takeEvery(EDIT_ORDER, editOrder)
    yield takeEvery(CREATE_ORDER, createOrder)
    yield takeEvery(SHOW_ORDER, showOrder)
    yield takeEvery(CREATE_ORDER_STATUS, createOrderStatus)
}

function* OrderSaga() {
    yield all([
        fork(watchOrder),
    ]);
}

export default OrderSaga;