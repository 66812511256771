import { CATEGORY_ERROR,CATEGORY_SUCCESS,EDIT_CATEGORY,SHOW_CATEGORY,CREATE_CATEGORY } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createCategory = (category) => {
    return {
        type: CREATE_CATEGORY,
        payload: { category }
    }
}

export const showCategory = (category_id) => {
    return {
        type: SHOW_CATEGORY,
        payload: { category_id }
    }
}

export const editCategory = (category) => {
    return {
        type: EDIT_CATEGORY,
        payload: { category }
    }
}



export const categorySuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: CATEGORY_SUCCESS,
        payload: msg
    }
}


export function categoryShow(category_id){
    return getApi().get('category/'+category_id)
}


export function categoryList(params){
    return getApi().get('category/list/tree',{
        params: params
    })
}

export function categoryTreeList(params){
    return getApi().get('category/list/tree',{
        params: params
    })
}

export const categoryError = (error) => {
    return {
        type: CATEGORY_ERROR,
        payload: error
    }
}

export function productCategoryImageUpload(params,image_id){
    console.log("image_id",image_id)
    if(image_id){
        return getApi().put('category/icon/edit/'+image_id,params)
    }else{
        return getApi().post('category/icon/upload',params);
    }
}