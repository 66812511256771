import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import TransactionSaga from './transaction/saga';
import CategorySaga from './category/saga';
import OrderSaga from './order/saga';
import PersonalLoanSaga from './personalLoan/saga';
import LoanTypeSaga from './loanType/saga';
import MemberSaga from './member/saga';
import PetrolPumpSaga from './petrolPump/saga';
import FuelPumpMapSaga from './fuelPumpMap/saga';
import FuelSaga from './fuel/saga';
import BrandSaga from './brand/saga';
import BannerSaga from './banner/saga';
import ProductSaga from './product/saga';
import LayoutSaga from './layout/saga';
import SupplierSaga from './productSupplier/saga';
import WarehouseSaga from "./warehouse/saga";
import WarehouseSupplierMapSaga from "./warehouseSupplierMap/saga";
import productStockSaga from "./productStock/saga";
import HomeFeaturesSaga from "./homefeatures/saga";
import Cart from './cart/saga';
import CheckoutSaga from './checkout/saga';
import BuySaga from './buy/saga';
import VoucherBuySaga from './EcommerceVoucher/saga';

export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        CategorySaga(),
        OrderSaga(),
        PersonalLoanSaga(),
        LoanTypeSaga(),
        MemberSaga(),
        ProfileSaga(),
        TransactionSaga(),
        PetrolPumpSaga(),
        FuelPumpMapSaga(),
        FuelSaga(),
        BrandSaga(),
        BannerSaga(),
        ProductSaga(),
        SupplierSaga(),
        WarehouseSaga(),
        WarehouseSupplierMapSaga(),
        productStockSaga(),
        LayoutSaga(),
        HomeFeaturesSaga(),
        Cart(),
        BuySaga(),
        CheckoutSaga(),
        VoucherBuySaga(),
    ])
}