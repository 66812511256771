import React, { Component } from 'react';
import { Link, useParams, withRouter } from "react-router-dom";
// import{useLocation} from 'react-router'
import { Modal, Alert, Container, Row, Col, Card, CardBody, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table, Media, InputGroup, InputGroupAddon, Input, CardImg } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import classnames from 'classnames';
//Import Star Ratings
import StarRatings from 'react-star-ratings';
import axios from 'axios';

//Import Product Images
import img4 from "../../assets/images/product/img-4.png";
import img6 from "../../assets/images/product/img-6.png";
import img7 from "../../assets/images/product/img-7.png";
import img8 from "../../assets/images/product/img-8.png";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { productDetails, products } from '../../store/ecommerceProducts/actions';
import { createCart } from '../../store/cart/actions';
import { createBuy } from '../../store/buy/actions';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { connect } from 'react-redux';
import { quantity } from 'chartist';
import NotificationDropdown from "../../components/CommonForBoth/TopbarDropdown/NotificationDropdown";
import { loginUser } from '../../store/auth/login/actions';
import Login from './Login';
import { Helmet } from 'react-helmet';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";

class EcommerceProductDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comments: [
                { id: 1, img: avatar2, name: "Brian", description: "If several languages coalesce, the grammar of the resulting language.", date: "5 hrs ago" },
                {
                    id: 2, img: avatar4, name: "Denver", description: "To an English person, it will seem like simplified English, as a skeptical Cambridge", date: "07 Oct, 2019",
                    childComment: [
                        { id: 1, img: avatar5, name: "Henry", description: "Their separate existence is a myth. For science, music, sport, etc.", date: "08 Oct, 2019" },
                    ]
                },
                { id: 3, img: "Null", name: "Neal", description: "Everyone realizes why a new common language would be desirable.", date: "05 Oct, 2019" },
            ],
            productQty: 1,
            activeTab: '0',
            cartItem: [],
            disable: true,

            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }

        }
        this.toggleTab = this.toggleTab.bind(this);
        this.imageShow = this.imageShow.bind(this);
        this.countUP.bind(this);
        this.countDown.bind(this);
    }
    tog_scroll = (banner) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.state.banner = banner;

        this.removeBodyCss();
    }


    removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    imageShow(img, id) {
        var expandImg = document.getElementById("expandedImg" + id);
        expandImg.src = img;
    }



    componentDidMount() {
        // this.fetchProducts();
        this.fetchProductsDetails();
    }



    fetchProductsDetails = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        const url = window.location.href;
        const product_slug = url.split("/").pop();

        productDetails(product_slug)
            .then((response) => {
                // console.log('product Details', response);
                this.setState({
                    productDetails: response.data,
                });
                this.state.productDetails = response.data;
                // console.log('get product', this.state.productDetails);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }

    reloadPage() {
        this.fetchProductsDetails();
    }


    addToCart = (e, products) => {
        // console.log(products);
        let quantity = this.state.productQty;
        let productsData = {
            f_action_status: 1,
            imei_no: 'web browser',
            productArray: [{
                product_id: products.product_id,
                product_map_id: 0,
                coupon_discount: 0,
                quantity: quantity
            }]
        }
        this.props.createCart(productsData)
    }

    countUP = () => {
        if (this.state.productQty < 10) {
            this.state.productQty += 1;
            this.setState({
                productQty: this.state.productQty,
                // disable: this.state.disable(false)
            });
        }
    }

    countDown = () => {
        if (this.state.productQty > 1) {
            this.state.productQty -= 1;
            this.setState({
                productQty: this.state.productQty
            });
        }
    }
    // handleValidSubmit(event, values) {
    //     this.props.loginUser(values);
    //     // this.fetchbannerList(); 
    //     this.form && this.form.reset();
    //     // window.location.reload();
    // }
    buyNow = (e, products) => {
        let quantity = this.state.productQty;
        let productsData = {
            total_quantity: quantity,
            productArray: [
                {
                    f_product_id: products.product_id,
                    product_map_id: 0,
                    quantity: quantity,
                    unit_price: products.product_sale_price,
                    unit_type: products.f_unit_id,
                    total_amount: (products.product_sale_price * quantity),
                    currency: "BDT",
                    discount_percen: products.discount,
                    discount_amount: products.discount,
                    total_discount: products.discount,
                    product_slug: products.product_slug,
                    f_action_status: 1
                }]
        }
        localStorage.setItem('buyNow', JSON.stringify(productsData));
        setTimeout(
            this.props.history.push('/buynow'), 1000
        );
    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let productImage = [];
        let productBrand = [];
        let productCategory = [];
        let productInfo = [];
        let similarProducts = [];
        // let productSingleImage;
        // let imageID;

        if (this.state.hasOwnProperty('productDetails')) {
            productImage = this.state.productDetails.products.feature_image;
            productBrand = this.state.productDetails.products.brand;
            productCategory = this.state.productDetails.products.category;
            productInfo = this.state.productDetails.products;
            similarProducts = this.state.productDetails.similar_product;


        }
        // console.log("sss", this.props.success)
        let cartUpdate = [];
        if (this.props.success != undefined || this.props.success != null || this.props.success != " ") {
            cartUpdate = JSON.parse(localStorage.getItem('cartData'));
        }
        cartUpdate = JSON.parse(localStorage.getItem('cartData'));


        const url = window.location.href;
        const product_slug = url.split("/").pop();
        let currentUrl = "http://autotbd.com/product-details/" + product_slug;
        let title = productInfo.product_name_en;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="csrf_token" content="" />
                    <meta property="type" content="website" />
                    <meta property="url" content={currentUrl} />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="_token" content="" />
                    <meta name="robots" content="noodp" />
                    <meta property="title" content={title} />
                    {/* <meta property="quote" content={quote} /> */}
                    {/* <meta name="description" content={description} />
                    <meta property="image" content={image} /> */}
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    {/* <meta property="og:quote" content={quote} />
                    <meta property="og:hashtag" content={hashtag} />*/}
                    <meta property="og:image" content="https://admin.autotbd.com/autot_api/public/upload/Products/2/featureImage/02e8ca2ad17d56e17e1fc2edcbee4d40_1635932439.png" /> 
                    <meta content="image/*" property="og:image:type" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:site_name" content={currentUrl} />
                    <meta property="og:description" content={productInfo.product_description} />



                    <meta name='description' content='explore and search Autot product info' />
                    <meta name='name' content='explore and search Autot product info' />
                    {/* <meta property="og:image" content={imageUrl} /> */}
                    <meta property="og:type" content="product List" />
                    <meta property="og:url" content={window.location.pathname} />
                    <meta rel="link" Link="https://autot.com.bd/" />
                    {/* <meta property="og:site_name" content={"https://autotbd.com/"+"product-details/" + product.product_slug} /> */}
                </Helmet>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner " role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <NotificationDropdown style={{ display: 'none' }} cartVal={cartUpdate} />

                <div className="page-content page-content-productDetails">
                    <Container fluid>
                        <Breadcrumbs title="Ecommerce" breadcrumbItem="Product Details" />
                        <Row id="p_details">
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xl="6">
                                                <div className="product-detail-imgs">
                                                    <Row>
                                                        <Col md="2" xs="3">
                                                            <Nav className="flex-column" pills>
                                                                {
                                                                    productImage.map((productImg, key) =>

                                                                        <NavItem>
                                                                            <NavLink
                                                                                className={classnames({ active: this.state.activeTab === key.toString() })}
                                                                                onClick={() => { this.toggleTab(key.toString()) }}
                                                                            >
                                                                                <img src={imageLink + 'upload/Products/' + productInfo.product_id + '/featureImage/' + productImg.feature_image} alt="" onClick={() => { this.imageShow((imageLink + 'upload/Products/' + productInfo.product_id + '/featureImage/' + productImg.feature_image), key.toString()) }} className="img-fluid mx-auto d-block rounded" />
                                                                                <Helmet>
                                                                                    <meta property="og:image" content={imageLink + 'upload/Products/' + productInfo.product_id + '/featureImage/' + productImg.feature_image} />
                                                                                    {/* <meta property="og:site_name" content={"https://autotbd.com/" + "product-details/" + productInfo.product_slug} /> */}
                                                                                </Helmet>
                                                                            </NavLink>
                                                                        </NavItem>
                                                                    )}

                                                            </Nav>
                                                        </Col>
                                                        <Col md={{ size: 7, offset: 1 }} xs="9">
                                                            <TabContent activeTab={this.state.activeTab}>
                                                                {
                                                                    productImage.map((productImg, key) =>
                                                                        <TabPane tabId={key.toString()}>
                                                                            <div>
                                                                                <TransformWrapper
                                                                                    initialScale={1}
                                                                                >
                                                                                    <TransformComponent>
                                                                                        <img src={imageLink + 'upload/Products/' + productInfo.product_id + '/featureImage/' + productImg.feature_image} alt="" id={"expandedImg" + key.toString()} className="img-fluid mx-auto d-block" />
                                                                                    </TransformComponent>
                                                                                </TransformWrapper>
                                                                            </div>
                                                                        </TabPane>
                                                                    )}


                                                            </TabContent>
                                                            {/* onClick={(e) => this.addToCart(e, productInfo)} */}
                                                            <div className="text-center">

                                                                {/* this.props.error && this.props.error ?<span className="btn btn-sm btn-primary waves-effect waves-light"
                                                                    >Login/Register</span>:  */}
                                                                {/* {
                                                                    productInfo.product_stock_balance > 0 ? */}
                                                                <Button type="button" color="primary" className="btn waves-effect waves-light mt-2 mr-1"
                                                                    data-toggle="modal" onClick={(e) => {

                                                                        if (!localStorage.getItem("authUser")) {
                                                                            this.tog_scroll(1);
                                                                        }
                                                                        else {
                                                                            this.addToCart(e, productInfo);
                                                                        }
                                                                    }}>
                                                                    <i className="bx bx-cart mr-2"></i> Add to cart
                                                                </Button>
                                                                {/* : 
                                                                        <Button type="button" color="primary" className="btn waves-effect waves-light mt-2 mr-1" disabled
                                                                            data-toggle="modal" onClick={(e) => {

                                                                                if (!localStorage.getItem("authUser")) {
                                                                                    this.tog_scroll(1);
                                                                                }
                                                                                else {
                                                                                    this.addToCart(e, productInfo);
                                                                                }
                                                                            }}>
                                                                            <i className="bx bx-cart mr-2"></i> Add to cart
                                                                        </Button>
                                                                } */}
                                                                {/* {
                                                                    productInfo.product_stock_balance > 0 ? */}
                                                                <Button type="button" color="success" className="ml-1 btn waves-effect  mt-2 waves-light" data-toggle="modal"
                                                                    onClick={(e) => {
                                                                        if (!localStorage.getItem("authUser")) {
                                                                            this.tog_scroll(1);
                                                                        }
                                                                        else {
                                                                            this.buyNow(e, productInfo);
                                                                        }
                                                                    }}>
                                                                    <i className="bx bx-shopping-bag mr-2"></i>Buy now
                                                                </Button>
                                                                {/* :
                                                                        <Button type="button" color="success" className="ml-1 btn waves-effect  mt-2 waves-light" data-toggle="modal" disabled
                                                                            onClick={(e) => {
                                                                                if (!localStorage.getItem("authUser")) {
                                                                                    this.tog_scroll(1);
                                                                                }
                                                                                else {
                                                                                    this.buyNow(e, productInfo);
                                                                                }
                                                                            }}>
                                                                            <i className="bx bx-shopping-bag mr-2"></i>Buy now
                                                                        </Button>

                                                                } */}




                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                            <Col xl="6">

                                                <div className="mt-4 mt-xl-3">
                                                    <Link to={"/category/" + productCategory.category_name_en} className="text-warning">{productCategory.category_name_en}</Link>
                                                    <h4 className="mt-1 mb-3 text-primary font-weight-bold" style={{ fontSize: '30px' }}>{productInfo.product_name_en}</h4>
                                                    <div dangerouslySetInnerHTML={{ __html: productInfo.product_description }} />

                                                    <div className="text-muted float-left mr-3 mb-3">
                                                        <StarRatings
                                                            rating={4}
                                                            starRatedColor="#F1B44C"
                                                            starEmptyColor="#2D363F"
                                                            numberOfStars={5}
                                                            name='rating'
                                                            starDimension="14px"
                                                            starSpacing="3px"
                                                        />
                                                    </div>
                                                    <p className="text-muted mb-4">( 152 Customers Review )</p>

                                                    <h6 className="text-success text-uppercase">{
                                                        !productInfo.discount == 0 || !productInfo.discount == null ?
                                                            <div>
                                                                {productInfo.discount + "%"}off
                                                            </div>
                                                            : null
                                                    }</h6>
                                                    {productInfo.product_stock_balance > 0 ?
                                                        <h2 className="font-weight-bold text-success">In Stock</h2> :
                                                        <h2 className="font-weight-bold text-danger">Out of Stock</h2>

                                                    }
                                                    {
                                                        !productInfo.product_market_price == 0 || !productInfo.product_market_price == null ?
                                                            <h5 className="mb-4 font-weight-bolder">Market Price : <span><b>৳{productInfo.product_market_price} </b></span></h5> : null

                                                    }
                                                    {
                                                        !productInfo.product_market_price == 0 || !productInfo.product_market_price == null ? (productInfo.product_market_price != productInfo.product_sale_price) ?
                                                            <h5 className="mb-4 font-weight-bolder" style={{ fontSize: '25px' }}>Price : <span className="text-danger mr-2"><del>৳{productInfo.product_market_price} </del></span> <b>৳{productInfo.product_sale_price} </b></h5>
                                                            :
                                                            <h5 className="mb-4 font-weight-bolder">Price :<span className="text-dark"><b>৳{productInfo.product_sale_price} </b></span></h5> : ''

                                                    }
                                                    {
                                                        !productInfo.is_delivery_charge_free == 1 ?
                                                        "":   <div class="row mb-3">
                                                        <div class="col-md-12">
                                                            <h3 class="animate-charcter"><i className="mdi mdi-truck-fast mr-1 text-success"/> Free Delivery</h3>
                                                        </div>
                                                    </div> 
                                                    }


                                                    <div style={{ width: "120px" }} className="mb-3">

                                                        <InputGroup>
                                                            <InputGroupAddon addonType="append">
                                                                <Button color={this.state.productQty == 1 ? "secondary" : "primary"} disabled={this.state.productQty == 1} onClick={() => { this.countDown() }}>-</Button>
                                                            </InputGroupAddon>
                                                            <Input type="text" className="text-center" value={this.state.productQty} name="demo_vertical" readOnly />
                                                            <InputGroupAddon addonType="prepend">
                                                                <Button color={this.state.productQty == 10 ? "secondary" : "primary"} disabled={this.state.productQty == 10} onClick={() => { this.countUP() }}>+</Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </div>
                                                    {/* <div className="mt-3">
                                                        <FacebookShareButton
                                                            // openShareDialogOnClick='true'
                                                            url={currentUrl}
                                                            app_id={145634995501895}
                                                            href={currentUrl}
                                                            quote={productInfo.product_name_en}
                                                            description={productInfo.product_description}
                                                            className="Demo__some-network__share-button"
                                                        >
                                                            <FacebookIcon size={32} round />
                                                        </FacebookShareButton>
                                                    </div> */}
                                                    {/* {this.props.error && this.props.error ?
                                                        <div className="position-relative" style={{ marginTop: '17px', fontSize: "18px" }}>
                                                            <span style={{ color: 'red', fontSize: "18px" }}>Please Login/register for add cart</span>
                                                            &nbsp;&nbsp;<span className="btn btn-sm btn-primary waves-effect waves-light"
                                                                onClick={() => { this.tog_scroll(1) }}
                                                                data-toggle="modal">Login/Register</span>
                                                        </div> : null} */}
                                                    {/* <p className="text-muted mb-4">To achieve this, it would be necessary to have uniform grammar pronunciation and more common words If several languages coalesce</p>
                                                    <Row className="mb-3">
                                                        <Col md="6">
                                                            <div>
                                                                <p className="text-muted"><i className="bx bx-unlink font-size-16 align-middle text-primary mr-1"></i> Wireless</p>
                                                                <p className="text-muted"><i className="bx bx-shape-triangle font-size-16 align-middle text-primary mr-1"></i> Wireless Range : 10m</p>
                                                                <p className="text-muted"><i className="bx bx-battery font-size-16 align-middle text-primary mr-1"></i> Battery life : 6hrs</p>
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <div>
                                                                <p className="text-muted"><i className="bx bx-user-voice font-size-16 align-middle text-primary mr-1"></i> Bass</p>
                                                                <p className="text-muted"><i className="bx bx-cog font-size-16 align-middle text-primary mr-1"></i> Warranty : 1 Year</p>
                                                            </div>
                                                        </Col>
                                                    </Row> */}
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mt-5">
                                            <h5 className="mb-3 font-weight-bolder" style={{ fontSize: '30px' }}>Specifications :</h5>

                                            <div className="table-responsive">
                                                {/* <Table className="table mb-0 table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row" style={{ width: "400px" }}>Category</th>
                                                            <td>{productCategory.category_name_en}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Brand</th>
                                                            <td>{productBrand.brand_name_en}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Color</th>
                                                            <td>Black</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Connectivity</th>
                                                            <td>Bluetooth</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Warranty Summary</th>
                                                            <td>1 Year</td>
                                                        </tr>
                                                    </tbody>
                                                </Table> */}
                                                <div dangerouslySetInnerHTML={{ __html: productInfo.product_specification }} />

                                            </div>
                                        </div>

                                        {/* <div className="mt-5">
                                            <h5 className="mb-4">Reviews :</h5>
                                            {
                                                this.state.comments.map((comment, k) =>
                                                    <Media className={comment.id === 1 ? "border-bottom" : "border-bottom mt-3"} key={"__media__" + k}>
                                                        {
                                                            comment.img !== "Null" ?
                                                                <img src={comment.img} className="avatar-xs mr-3 rounded-circle" alt="img" />
                                                                : <div className="avatar-xs mr-3">
                                                                    <span className="avatar-title bg-soft-primary text-primary rounded-circle font-size-16">
                                                                        N
                                                                    </span>
                                                                </div>
                                                        }
                                                        <Media body>
                                                            <h5 className="mt-0 mb-1 font-size-15">{comment.name}</h5>
                                                            <p className="text-muted">{comment.description}</p>
                                                            <ul className="list-inline float-sm-right">
                                                                <li className="list-inline-item">
                                                                    <Link to="#"><i className="far fa-thumbs-up mr-1"></i> Like</Link>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <Link to="#"><i className="far fa-comment-dots mr-1"></i> Comment</Link>
                                                                </li>
                                                            </ul>
                                                            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary mr-1"></i>{comment.date}</div>
                                                            {
                                                                comment.childComment ?
                                                                    comment.childComment.map((child, key) =>
                                                                        <Media className="mt-4" key={"_media_" + key}>
                                                                            <img src={child.img} className="avatar-xs mr-3 rounded-circle" alt="img" />
                                                                            <Media body>
                                                                                <h5 className="mt-0 mb-1 font-size-15">{child.name}</h5>
                                                                                <p className="text-muted">{child.description}</p>
                                                                                <ul className="list-inline float-sm-right">
                                                                                    <li className="list-inline-item">
                                                                                        <Link to="#"><i className="far fa-thumbs-up mr-1"></i> Like</Link>
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <Link to="#"><i className="far fa-comment-dots mr-1"></i> Comment</Link>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary mr-1"></i> {child.date}</div>
                                                                            </Media>
                                                                        </Media>
                                                                    )

                                                                    : null
                                                            }
                                                        </Media>
                                                    </Media>
                                                )
                                            }
                                        </div> */}

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col lg={12}>
                                <div>
                                    <h5 className="mb-3 font-weight-bolder text-primary" style={{ fontSize: '25px' }}>Related Product</h5>
                                    <hr />
                                    <Row>
                                        {
                                            similarProducts.slice(0, 6).map((product, key) =>
                                                <Col xl="4" sm="6" key={"__product__" + key}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="align-items-center">
                                                                <Col md="4">
                                                                    {product.product_bar_code_img == null || product.product_bar_code_img == undefined ? [] :
                                                                        <span onClick={() => this.reloadPage()}><Link to={"/product-details/" + product.product_slug} ><img src={imageLink + 'upload/Products/' + product.product_id + '/featureImage/' + product.feature_image[0].feature_image} alt="" height="200" className="img-fluid mx-auto d-block" /></Link></span>
                                                                    }
                                                                </Col>
                                                                <Col md="8">
                                                                    <div className="text-center text-md-left pt-3 pt-md-0">
                                                                        <h5 className="mb-3 text-truncate text-primary" onClick={() => this.reloadPage()}><Link to={"/product-details/" + product.product_slug}>{product.product_name_en}</Link></h5>
                                                                        <div className="text-muted mb-3">
                                                                            <StarRatings
                                                                                rating={product.rating}
                                                                                starRatedColor="#F1B44C"
                                                                                starEmptyColor="#2D363F"
                                                                                numberOfStars={5}
                                                                                name='rating'
                                                                                starDimension="14px"
                                                                                starSpacing="3px"
                                                                            />
                                                                        </div>
                                                                        {!product.product_market_price == null || !product.product_market_price == 0 ? product.product_market_price != product.product_sale_price ?
                                                                            <h5 className="my-0"><span className="text-muted mr-2"><del className="text-danger font-weight-bold">৳{product.product_market_price}</del></span> <b>৳{product.product_sale_price}</b></h5>
                                                                            : <h5 className="my-0"><b>৳{product.product_sale_price}</b></h5> : ''
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        }

                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
                <Modal
                    isOpen={this.state.modal_scroll}
                    toggle={this.tog_scroll}
                    scrollable={true}
                    centered
                >
                    <div className="modal-header bg-primary">
                        {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null} */}

                        <h5 className="modal-title mt-0 text-light">Welcome to AutoT! Please login.</h5>
                        <button
                            type="button"
                            onClick={() =>
                                this.setState({ modal_scroll: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Login />
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Cart;
    return { msg, error, success };
}
// const mapStatetoProps2 = state =>{
//     const {msg,error,success} = state.Buy;
//     return {msg,error,success};
// }

export default withRouter(connect(mapStatetoProps, { createCart, loginUser, createBuy })(EcommerceProductDetail, NotificationDropdown));
