import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Table,Badge,CardFooter} from "reactstrap";
import { Link } from "react-router-dom";
import {orderList,orderShow} from '../../store/order/actions';
import {CustomPagination} from "../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import shortid from 'shortid';

function TestRow(props) {
    const order = props.order
    // const testLink = `/order/details/${order.order_id}`
  
   const getBadge = (status) => {
    return status == 1 ? 'success' : 'secondary'
  }
  return (
    <tr key={order.order_id .toString()}>
    <td scope="row">{props.index}</td>
    <td>{order.order_code}</td>
    <td>{order.order_date}</td>
    <td>{order.quantity}</td>
    <td>{order.discount}</td>
    <td>{order.total_amount}</td>
    <td>{order.final_amount}</td>
    <td>{order.paid_amount}</td>
    <td>{order.name}</td>
    <td>{order.email}</td>
    <td>{order.phone}</td>
    <td>{order.address}</td>
    <td><Link to={ `/order-details/${order.order_id}`}><i className="fa fa-eye fa-2x"></i></Link></td>

</tr>
  )
}
class OrderIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
                status: true,
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                modal_scroll: false,
                image_modal_scroll: false,
                uploadImage: {},
                order: {
                    
                },
            };
    }

    componentDidMount()
    {
            this.fetchorderList();
            this.fetchorderDetails();  
    }

    fetchorderList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        orderList(options).then((response) => {
            this.setState({
                orderList :response.data, 
            });
            this.state.orderList = response.data;

        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchorderDetails = (order_id) => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        orderShow(options,order_id).then((response) => {
            this.setState({
                orderDetails :response.data, 
            });
            this.state.orderDetails = response.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
      tog_scroll = (order) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.order = order;
        this.fetchBrandCategoryList(order.f_category_info_id);
        this.removeBodyCss();
      }

      tog_image_scroll = (order) => {
        this.setState(prevState => ({
          image_modal_scroll: !prevState.image_modal_scroll,
        }));
        this.state.order = order;
        this.removeBodyCss();
      }


      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createProduct(values);
            this.fetchorderList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editProduct(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchorderList();
       }


       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchorderList(); 
        });
       }
       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index,

            },() => {
                this.fetchorderList();
            });
        }

    render() {
        let orderListMap = [];
        let paginator;
        // if (this.state.hasOwnProperty('orderList')) {
        //     orderListMap = this.state.orderList.data;
        //     console.log('orderListMap',orderListMap)
            if (this.state.hasOwnProperty('orderList')) {
    
                orderListMap =this.state.orderList.data;
                console.log('orderListMap',orderListMap)

            paginator = {
                'currentPage':this.state.orderList.current_page,
                'pagesCount':this.state.orderList.last_page,
                'total':this.state.orderList.total,
                'to':this.state.orderList.to,
                'from':this.state.orderList.from,
            }
        }
        const imageLink =process.env.REACT_APP_IMAGE_URL;
        // const imageLink ='http://localhost:8083/';
        // const {product_id,product_code,product_name_bn,product_name_en,product_bar_code,f_category_info_id,f_brand_id,f_unit_id,product_stock_balance,product_buy_price,product_sale_price,product_market_price,discount,product_description,product_weight,product_sku_code,product_specification,minimum_oder_quantity,product_meta_tag,f_discount_type,status} = this.state.order;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Order" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Product Order List</CardTitle>
                                        <div className="table-responsive-xs">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table table-responsive">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Order Code</th>
                                                        <th>Order Date</th>
                                                        <th>Order Quantity</th>
                                                        <th>Discount</th>
                                                        <th>Total Amount</th>
                                                        <th>Final Amount</th>
                                                        <th>Paid Amount</th>
                                                        <th>Order By</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Address</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {orderListMap.map((order, index) =>
                                                <TestRow key={index} order={order} index={index + 1}/>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Order;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps)(OrderIndex));
