import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { ADD_TO_CART,UPDATE_CART, CART_SUCCESS, EDIT_PRODUCT, SHOW_PRODUCT } from './actionTypes';

import { addCart, cartSuccess, cartError } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData, editJwtData } from '../../helpers/self_accounts_settings_helper';

function* createCart({ payload: { product } }) {
    try {
        let authchk = localStorage.getItem("jwtToken");

        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(postJwtData, 'cart/add', product);
            // console.log("cart response",response);
            
            if (response.success == false) {
                // console.log('cart err',response.message)
                yield put(cartError(response.message));
            }else if(authchk != undefined && response.success == true){
                yield put(cartSuccess(response.data));
                // console.log('cart data find', response.data);
                localStorage.setItem('cartData', JSON.stringify(response.data.cart));
                window.location.reload();
            }else{
                yield put(cartError('Unauthorized User!'));
            }            
          }
    } catch (error) {
        yield put(cartError(error));
    }
}


function* updateCart({ payload: { product } }) {
    try {
        // console.log("updateCart",product)
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'cart/update/'+product.cart_product_details_id+'/'+product.quantity+'?cart_id='+product.cart_id);
             if (response.success == false) {
                // alert(response.message['message']);
                yield put(cartError(response.message.message));
            }else if(response.success == true){
                yield put(cartSuccess(response.data));
                localStorage.setItem('cartData', JSON.stringify(response.data.cart));
                window.location.reload();
            }
          }


    } catch (error) {
        alert('err')
        yield put(cartError(error));
    }
}


// function* showProduct({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtData, 'users/id/'+user_id);
//              yield put(productShow(response));
//           }


//     } catch (error) {
//         yield put(productError(error));
//     }
// }

export function* watchCart() {
    yield takeEvery(UPDATE_CART, updateCart)
    yield takeEvery(ADD_TO_CART, createCart)
    // yield takeEvery(SHOW_PRODUCT, showProduct)
}

function* CartSaga() {
    yield all([
        fork(watchCart),
    ]);
}

export default CartSaga;