import React, { Component } from 'react'
import { Container } from 'reactstrap';
import { orderConfirm, orderShow } from '../../store/order/actions';



export default class EcommerceOrderTrack extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: true,
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            modal_scroll: false,
            image_modal_scroll: false,
            Color: `warning`,
            modal: false,
            order: {

            },

            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }

        }

    }

    componentDidMount() {
        this.fetchconfirmOrder();
        this.fetchOrderDetails();
    }

    fetchconfirmOrder = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
        });
        const url = window.location.href;
        const order_id = url.split("/").pop();
        orderConfirm(order_id).then((response) => {
            this.setState({
                confirmOrders: response.data,
            });
            this.state.confirmOrders = response.data;
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
            });
        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });
            })
    }
    
    fetchOrderDetails = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
        });
        const url = window.location.href;
        const order_id = url.split("/").pop();
        orderShow(order_id).then((response) => {
            this.setState({
                orderDetails: response.data.orderDetails,
            });
            this.state.orderDetails = response.data.orderDetails;
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
            });
        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });
            })
    }


    render() {
        let confirmOrderStatus = [];
        let current_status = [];
        if (this.state.hasOwnProperty('confirmOrders')) {
            confirmOrderStatus = this.state.confirmOrders;

            // current_status= confirmOrderStatus.pop();

            confirmOrderStatus.slice(-1).map(status =>
                current_status = status.order_current_status
            )
        }
        let orderDate = [];
        if (this.state.hasOwnProperty('orderDetails')) {
            orderDate = this.state.orderDetails;
        }
        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="page-content">
                    <Container fluid>
                        <div className="main_container">
                            <div class="container padding-bottom-3x mb-1">
                                <div class="card mb-3">
                                    <div class="p-4 text-center text-white text-lg bg-dark rounded-top"><span class="text-uppercase">Tracking Order No - </span><span class="text-medium">#{orderDate.order_code}</span></div>
                                    <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-primary text-white">
                                        <div class="w-100 text-center py-1 px-2"><span class="text-medium">Shipped Type: </span> Cash on Delivery</div>
                                        <div class="w-100 text-center py-1 px-2"><span class="text-medium">Status: </span>{current_status.action_name}</div>
                                        <div class="w-100 text-center py-1 px-2"><span class="text-medium">Expected Date: </span> {new Date(new Date(orderDate.order_date).setDate(new Date(orderDate.order_date).getDate() + 5)).toDateString()}</div>
                                    </div>
                                    <div class="card-body">
                                        <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                                            {confirmOrderStatus.map(status =>
                                                <div class="step completed" >
                                                    <div class="step-icon-wrap">
                                                        <div class="step-icon" style={{ backgroundColor: status.order_current_status.colour }}><i className="bx bxs-message-square-check"></i></div>
                                                    </div>
                                                    <h4 class="step-title" >{status.order_current_status.action_name}</h4>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
                                    <div></div>
                                    <div class="text-left text-sm-right"><a class="btn btn-outline-primary btn-rounded btn-sm" href="#">View Order Details</a></div>
                                </div> */}
                            </div>
                        </div>

                    </Container>
                </div >
            </React.Fragment >
        )
    }
}
