import { BANNER_ERROR,BANNER_SUCCESS,EDIT_BANNER } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const Banner = (state = initialState, action) => {
    // console.log("statess",action)
    switch (action.type) {
        case EDIT_BANNER:
            state = { ...state }
            break;
        case BANNER_SUCCESS:
            state = { ...state, success: action.payload }
            console.log("state",state)
            break;
        case BANNER_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Banner;