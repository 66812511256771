import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PRODUCT, PRODUCT_SUCCESS, EDIT_PRODUCT, SHOW_PRODUCT } from './actionTypes';

import { productSuccess, productError, productShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData, editJwtData } from '../../helpers/self_accounts_settings_helper';

function* createProduct({ payload: { product } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(postJwtData, 'product/create', product);
            if (response.success == false) {
                // alert(response.message['message']);
                yield put(productError(response.message.message));
            } else if (response.success == true) {
                yield put(productSuccess(response.data.message));
            }
        }
    } catch (error) {
        yield put(productError(error));
    }
}


function* editProduct({ payload: { product } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(editJwtData, 'product/' + product.product_id, product);
            yield put(productSuccess(response));
        }


    } catch (error) {
        yield put(productError(error));
    }
}


function* showProduct({ payload: { user_id } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(getJwtData, 'users/id/' + user_id);
            yield put(productShow(response));
        }


    } catch (error) {
        yield put(productError(error));
    }
}

export function* watchProduct() {
    yield takeEvery(EDIT_PRODUCT, editProduct)
    yield takeEvery(CREATE_PRODUCT, createProduct)
    yield takeEvery(SHOW_PRODUCT, showProduct)
}

function* ProductSaga() {
    yield all([
        fork(watchProduct),
    ]);
}

export default ProductSaga;