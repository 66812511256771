import { AvForm } from 'availity-reactstrap-validation'
import AvField from 'availity-reactstrap-validation/lib/AvField'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Alert, Modal } from 'reactstrap'
import { loginUser } from '../../store/auth/login/actions';

class Login extends Component {
    constructor(props) {
        super(props);
        this.toggleTab = this.toggleTab.bind(this);
        // this.imageShow = this.imageShow.bind(this);
    }

    tog_scroll = (banner) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.state.banner = banner;

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    handleValidSubmit(event, values) {
        this.props.loginUser(values);
        // this.fetchbannerList(); 
        this.form && this.form.reset();

        // window.location.reload();
    }
    render() {
        return (
            <div>
                {/* {this.props.msg} */}    
                {this.props.error? toast.error(this.props.error, { position: toast.POSITION.TOP_CENTER }) : ""}
                {this.props.msg? toast.success(this.props.msg, { position: toast.POSITION.TOP_CENTER }) : ""}
                {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                {/* {this.props.error && this.props.error ? <p color="danger">{this.props.error}</p> : ''}
                {this.props.success == false && this.props.success == false ? <p color="danger">{this.props.error}</p> : ''} */}
                <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
               
                    <div className="form-group">
                        <AvField name="mobile" label="Mobile Number" className="form-control" type="text" placeholder="Enter mobile number" />
                    </div>
                    <div className="form-group ">
                        <AvField name="password" label="Password" className="form-control " type="password" placeholder="Enter Password" />
                    </div>
                    <div className="d-flex justify-content-between">
                        <h6>New member? <Link to="/register" className="text-info">Register</Link> here</h6>
                        <Link to="/forgot-password" className="text-info">Forget Password?</Link>
                    </div>


                    <div className="text-center mt-4">
                        {/* <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button> */}
                        <button type="submit" className="btn btn-success btn-lg save-event">Login</button>
                    </div>
                </AvForm>

            </div>
        )
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Login;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { loginUser })(Login));
