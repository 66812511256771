import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardImg, CardTitle, Form, Label, Input, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import classnames from 'classnames';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
//Import Star Ratings
import StarRatings from 'react-star-ratings';

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import Slider from "react-slick";

//Import Product Images
import product1 from "../../assets/images/product/img-1.png";
import product2 from "../../assets/images/product/img-2.png";
import product3 from "../../assets/images/product/img-3.png";
import product4 from "../../assets/images/product/img-4.png";
import product5 from "../../assets/images/product/img-5.png";
import product6 from "../../assets/images/product/img-6.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { createBanner, sliderList, editBanner, sliderUpload } from '../../store/banner/actions';
import { products } from '../../store/ecommerceProducts/actions';
import { Searchbox } from './Searchbox';
import { productDetails, cartDetails } from '../../store/ecommerceProducts/actions';
import { help } from '../../store/ecommerceHelp/actions';
import helpImg from '../../assets/images/settings.jpg';
import { isBoolean } from 'lodash';
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "blue" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "blue" }}
            onClick={onClick}
        />
    );
}
class EcommerceHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true,
            page: 1,
            per_page: 18,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            image_modal_scroll: false,
            uploadImage: {},
            product: {

            },

                displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
     

        };
        this.toggleTab = this.toggleTab.bind(this);
        // this.handleChange = this.handleChange.bind(this);

    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    componentDidMount() {
        this.fetchHelpList();

    }

    fetchHelpList = () => {
        this.setState({
            displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        });
        help().then((response) => {
            this.setState({
                helpList: response.data,
            });
            this.state.helpList = response.data;
            // console.log('asdfghjas', this.state.helpList);
            this.setState({
                displayHide: { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            });
        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
                });
            })
    }





    render() {
        let helpData = [];
        let getHelpData = [];
        let title = [];
        if (this.state.hasOwnProperty('helpList')) {
            helpData = this.state.helpList;

            const url = window.location.href;
            const product_slug = "/" + url.split("/").pop();
            if (helpData.faq_url === product_slug) {
                title = `FAQ`
                getHelpData = helpData.faq
            }
            else if (helpData.privacy_policy_url === product_slug) {
                title = `Privacy Policy`
                getHelpData = helpData.privacy_policy
            }
            else if (helpData.about_us_url === product_slug) {
                title = `ABOUT US`
                getHelpData = helpData.about_us
            }
            else if (helpData.contact_us_url === product_slug) {
                title = `CONTACT US`
                getHelpData = helpData.contact_us
            }
            // if(helpData.)
            else if (helpData.how_to_buy_url === product_slug) {
                title = `How To BUY`
                getHelpData = helpData.how_to_buy
            }
            else if (helpData.terms_conditions_url === product_slug) {
                title = `Terms and Conditions`
                getHelpData = helpData.terms_conditions
            }
            else if (helpData.return_refund_url === product_slug) {
                title = `Returns and Refunds`
                getHelpData = helpData.returns_refunds
            }

        }





        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="page-content">
                    <Container fluid>
                        {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Product" /> */}

                        <Row>
                            <Col lg="12" >
                                <CardImg src={helpImg} className="img-fluid mx-auto d-block" style={{height:'300px'}}></CardImg>
                            </Col>
                            <Col lg="12">
                                <h2 className="text-center border-bottom border-primary font-weight-bold my-4">{title}</h2>
                                <div  dangerouslySetInnerHTML={{ __html: getHelpData }}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EcommerceHelp;