import { getApi } from '../../services/httpService';
import { BUY_NOW, BUY_SUCCESS, BUY_ERROR } from './actionTypes';


export const createBuy = (product) => {
    // console.log('prodasdasduct', product);

    return {
        type: BUY_NOW,
        payload: { product }

    }
}


export const buySuccess = (response) => {
    return {
        type: BUY_SUCCESS,
        payload: response
    }
}

export const buyError = (error) => {
    return {
        type: BUY_ERROR,
        payload: error
    }
}


export function buyNow(data){
    // console.log('buyData',data);
    return getApi().post('order/buy/now',data)
}