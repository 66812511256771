import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECKOUT_SUCCESS, EDIT_PRODUCT, CHECKOUT_PRODUCTS } from './actionTypes';

import { addCart, checkoutError, checkoutSuccess } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData, editJwtData } from '../../helpers/self_accounts_settings_helper';

function* createCheckout({ payload: { data } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(postJwtData, 'checkout', data);
            // console.log("Checkout", response);

            if (response.success == false) {
                // console.log('Checkout', response.message)
                yield put(checkoutError(response.message));
            } else if (response.success == true) {
                yield put(checkoutSuccess(response.message));
                // console.log('checkout data find', response.data);
                localStorage.removeItem('cartData', JSON.stringify(response.data.cart));
            
            }
        }
    } catch (error) {
        yield put(checkoutError(error));
    }
}


// function* updateCart({ payload: { product } }) {
//     try {
//         console.log("updateCart",product)
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtData, 'cart/update/'+product.cart_product_details_id+'/'+product.quantity+'?cart_id='+product.cart_id);
//              if (response.success == false) {
//                 // alert(response.message['message']);
//                 yield put(cartError(response.message.message));
//                 console.log("add cart",response.message.message)
//             }else if(response.success == true){
//                 yield put(cartSuccess(response.data));

//                 // localStorage.setItem('cartData', JSON.stringify(response.data.cart));
//                 // window.location.reload();
//             }
//           }


//     } catch (error) {
//         alert('err')
//         yield put(cartError(error));
//     }
// }


// function* showProduct({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtData, 'users/id/'+user_id);
//              yield put(productShow(response));
//           }


//     } catch (error) {
//         yield put(productError(error));
//     }
// }

export function* watchCheckout() {
    // yield takeEvery(UPDATE_CART, updateCart)
    yield takeEvery(CHECKOUT_PRODUCTS, createCheckout)
    // yield takeEvery(SHOW_PRODUCT, showProduct)
}

function* CheckoutSaga() {
    yield all([
        fork(watchCheckout),
    ]);
}

export default CheckoutSaga;