import React, { Component } from "react";
import { CardImg, Col, Collapse, Row } from "reactstrap";
import { Link, NavLink, withRouter } from "react-router-dom";
import classname from "classnames";



//i18n
import { withNamespaces } from 'react-i18next';
import { createBrand, brandList, editBrand, productBrandImageUpload } from '../../store/brand/actions';
import { categoryList } from '../../store/category/actions';
import { help } from "../../store/ecommerceHelp/actions";


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 1000,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            brand_logo: {},
            brand: {

            },
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
        };
    }

    componentDidMount() {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    };

    componentDidMount() {
        this.fetchbrandList();
        this.fetchCategoryList();
        this.fetchHelpList();
    }

    fetchbrandList = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        brandList(options).then((response) => {
            // console.log("response",response)
            this.setState({
                brandList: response.data,
            });
            this.state.brandList = response.data;
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
            });
        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }
    fetchCategoryList = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        categoryList(options).then((response) => {
            // console.log('res', response)
            this.setState({
                categoryList: response.data.childTree,
            });
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
            });
        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }

    fetchHelpList = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        help().then((response) => {
            this.setState({
                helpList: response.data,
            });
            this.state.helpList = response.data;
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
            });
        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }




    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let categoryList = [];
        let brandListMap = [];
        let helpOptions = [];
        if (this.state.hasOwnProperty('categoryList')) {
            categoryList = this.state.categoryList;
        }

        // dynamic navbar using recursive function
        const ChildMenu = ({ children }) => {
            return (
                <div className={classname("dropdown-menu", { show: this.state.ecommerceState })}>
                    {children != null && children.map((category, index) =>
                        <div className="dropdown">
                            <a href={"/category/" + category.category_slug}
                                className="dropdown-item dropdown-toggle arrow-none"
                                >
                                <span key="t-email-templates">{category.category_name_en}</span>{" "}
                                {category.children &&
                                    <span className="arrow-down"></span>
                                }
                            </a>
                            {category.children && <ChildMenu children={category.children} />}
                        </div>
                    )}
                </div>
            )
        }


        if (this.state.hasOwnProperty('brandList')) {
            brandListMap = this.state.brandList.data;
        }
        if (this.state.hasOwnProperty('helpList')) {
            helpOptions = this.state.helpList;
        }
        return (
            <React.Fragment>
                <div className="topnav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                            <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                                <ul className="navbar-nav active">
                                    <li className="nav-item dropdown">
                                        <Link to="/home" className="nav-link" style={{ color: '#ffffff' }}><i className="bx bx-home-circle mr-2"></i>{this.props.t('Home')}</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle arrow-none" onClick={e => { e.preventDefault(); this.setState({ uiState: !this.state.uiState }); }} to="/" style={{ color: '#ffffff' }}>
                                            <i className="bx bx-home-circle mr-2"></i>{this.props.t('Category')} {this.props.menuOpen}<div className="arrow-down"></div>
                                        </Link>
                                        <div className={classname("dropdown-menu", { show: this.state.uiState })} >
                                            {categoryList.map((category, index) =>
                                                <div className="dropdown">
                                                    <a
                                                        className="dropdown-item dropdown-toggle arrow-none"
                                                        href={"/category/" + category.category_slug}>
                                                       
                                                        <span key="t-email-templates">{category.category_name_en}</span>{" "}
                                                        {category.children &&
                                                            <div className="arrow-down"></div>
                                                        }
                                                    </a>
                                                    {category.children && <ChildMenu children={category.children} />}
                                                </div>
                                            )}


                                        </div>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <Link style={{ color: '#ffffff' }} onClick={e => { e.preventDefault(); this.setState({ appState: !this.state.appState }); }} to="/#" className="nav-link dropdown-toggle arrow-none">
                                            <i className="bx bx-home-circle mr-2"></i>{this.props.t('Brand')} {this.props.menuOpen}<div className="arrow-down"></div>
                                        </Link>
                                        <div className={classname("dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl", { show: this.state.appState })} >
                                            <Row>
                                                {brandListMap.map((brand, index) =>
                                                    <Col lg={3} sm={3} xs={3}>
                                                        <a href={"/brand/" + brand.brand_slug} className="dropdown-item">
                                                            <div className="d-flex my-1">
                                                                <CardImg src={imageLink + 'brand/' + brand.brand_logo} alt="" className="img-fluid d-block brand-img w-25 h-25" />
                                                                <p className="mx-2 my-auto">{brand.brand_name_en}</p>
                                                            </div>
                                                        </a>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link to="/voucher" className="nav-link" style={{ color: '#ffffff' }}><i className="bx bx-home-circle mr-2"></i>{this.props.t('Voucher')}</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link style={{ color: '#ffffff' }} onClick={e => { e.preventDefault(); this.setState({ isDashboard: !this.state.isDashboard }); }} to="/" className="nav-link dropdown-toggle arrow-none">
                                            <i className="bx bx-home-circle mr-2"></i>{this.props.t('Help')} {this.props.menuOpen}
                                            <div className="arrow-down"></div>
                                        </Link>
                                        <div className={classname("dropdown-menu", { show: this.state.isDashboard })}>
                                            <Link to={"/help" + helpOptions.faq_url} className="dropdown-item">{this.props.t('Faq')}</Link>
                                            <Link to={"/help" + helpOptions.privacy_policy_url} className="dropdown-item">{this.props.t('Privacy Policy')}</Link>
                                            <Link to={"/help" + helpOptions.about_us_url} className="dropdown-item">{this.props.t('About Us')}</Link>
                                            <Link to={"/help" + helpOptions.contact_us_url} className="dropdown-item">{this.props.t('Contact Us')}</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link to={"/help" + helpOptions.how_to_buy_url} className="nav-link" style={{ color: '#ffffff' }}><i className="bx bx-home-circle mr-2"></i>{this.props.t('How To Buy')}</Link>
                                    </li>
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(Navbar));
