import React, { Component } from 'react';
import { Container, Row, Col, Card, Alert, CardBody, Media, Button, CardImgOverlay, CardImg } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

import avatar from '../../assets/images/users/avatar-1.jpg';
// actions
import { cityList, districtList, thanaList } from '../../store/petrolPump/actions';
import { editProfile, showProfile, profileShow } from '../../store/auth/profile/actions';
import { userProfile } from '../../store/auth/profile/actions';
// import SweetAlert from 'react-bootstrap-sweetalert';
// import { POSITION } from 'react-toastify/dist/utils';
import { toast } from 'react-toastify';

class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "", user_name: "", user_id: "", district: 1,
            thana: 1,
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
        }

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit



    componentDidMount() {
        this.fetchDivisionList();
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
                this.setState({
                    user: obj.data,
                    user_name: obj.user_name,
                    email: obj.email,
                    user_id: obj.user_id,
                });
                this.state.user = obj.data
                // console.log('user', this.state.user);
            }
        }
        this.userProfileInfo();
        // this.fetchProfileInfo(this.state.user.user.id);
    }

    userProfileInfo = () => {
        userProfile().then(response => {
            this.setState({
                profile: response.data,
            })
            this.selectDistrict(this.state.profile.userDetails==null||this.state.profile.userDetails.f_division_id == null || this.state.profile.userDetails.f_division_id == "" ? "" : this.state.profile.userDetails.f_division_id);
            this.selectThana(this.state.profile.userDetails==null||this.state.profile.userDetails.f_zilla_id == null || this.state.profile.userDetails.f_zilla_id == "" ? "" : this.state.profile.userDetails.f_zilla_id);
            // console.log('profile', this.state.profile)
        })
    }

    handleValidSubmit(event, values) {
        let data = {
            vehicle_owner_name_en: values.fullName,
            vehicle_owner_email: values.email,
            user_id: values.user_id,
            mobile: values.mobile,
            gender: values.gender,
            birth_date: values.birthDate,
            address: values.address,
            f_division_id: values.f_division_id,
            f_zilla_id: values.f_district_id,
            f_thana_id: values.f_thana_id,
            f_action_status: 6,
            f_user_type: 2,
            success_msg: false,
        }
        this.props.editProfile(data);
        
        setTimeout(
            // this.userProfileInfo(),
            window.location.reload(),
            toast.success('Profile Update Successfully', { position: toast.POSITION.TOP_RIGHT }),
            5000
        );
       

        // alert('updated successfully');

    }






    fetchDivisionList = () => {
        cityList()
            .then((response) => {
                this.setState({
                    divisionList: response.data,
                });
                this.state.divisionList = response.data;
            })
            .catch(err => {
                console.log(err)
            })
    }

    selectDistrict = (city_id) => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        districtList(city_id)
            .then((response) => {
                this.setState({
                    // district: 0,
                    // thana: 1,
                    districtList: response.data,
                })
                this.state.districtList = response.data;
                // console.log('district', this.state.districtList);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }

    selectThana = (district_id) => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        thanaList(district_id)
            .then((response) => {
                this.setState({
                    // thana: 0,
                    thanaList: response.data,
                })
                this.state.thanaList = response.data;
                // console.log(' this.state.thanaList',  this.state.thanaList);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }

    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.selectDistrict(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.selectThana(e.target.value);
        }
        // this.setState({[e.target.name]: e.target.value},() => {
        //     this.fetchsupplierList();
        // });
    }


    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        // let userInformation = [];
        // let userInfo = [];
        // let userDivision = [];
        // let userDistrict = [];
        // let userThana = [];

        // if (this.state.hasOwnProperty('user')) {
        //     userInformation = this.state.user.user;
        //     userInfo = this.state.user.userInfo;
        //     userDivision = this.state.user.userInfo.division;
        //     userDistrict = this.state.user.userInfo.district;
        //     userThana = this.state.user.userInfo.thana;

        // }

        let profileInfo = [];
        let profileInfoDetails = [];
        if (this.state.hasOwnProperty('profile')) {
            profileInfo = this.state.profile;
            if(this.state.profile.userDetails == null){
                profileInfoDetails = ""
            }else{
                profileInfoDetails = this.state.profile.userDetails;
            }
        }

        // this.selectDistrict(profileInfoDetails.f_division_id);
        // this.selectThana(profileInfoDetails.f_division_id);
        // console.log('userInfo', userDistrict.f_division_id);

        let divisionLists = [];
        if (this.state.hasOwnProperty('divisionList')) {
            divisionLists = this.state.divisionList;
            // console.log('divisionLists', divisionLists);
        }

        let selectDistricts = [];
        if (this.state.hasOwnProperty('districtList')) {
            selectDistricts = this.state.districtList;
        }

        let thanaLists = [];
        if (this.state.hasOwnProperty('thanaList')) {
            thanaLists = this.state.thanaList;
        }

        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumb title="Autot" breadcrumbItem="Profile" />

                        <Row>
                            <Col lg="4" md="4">


                                <Card>
                                    <CardBody>
                                        <Media>
                                            <div className="mr-3">
                                                <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                            </div>
                                            <Media body className="align-self-center">
                                                <div className="text-muted">
                                                    <h5>{profileInfo.name}</h5>
                                                </div>
                                            </Media>
                                        </Media>
                                        <br></br>
                                        <div className="form-group ">
                                            <a><span className="font-weight-bold">User Name: </span> {profileInfo.name}</a>
                                        </div>
                                        <div className="form-group ">
                                            <a><span className="font-weight-bold">Mobile: </span>{profileInfo.mobile}</a>
                                        </div>
                                        <div className="form-group ">
                                            <a><span className="font-weight-bold">Email: </span>{profileInfo.email}</a>
                                        </div>
                                        <div className="form-group ">
                                            <a><span className="font-weight-bold">Address: </span>{profileInfoDetails.address}</a>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="8" md="8">
                                {/* <h4 className="card-title mb-4">Update Profile</h4> */}
                                {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                                <Card>
                                    <CardBody>
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }}>
                                            {/* {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    User Updated Successfully!
                                                </Alert>
                                            ) : null} */}

                                            {/* {this.state.success_msg ? (
                                                <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    // showCancel
                                                    confirmBtnBsStyle="success"
                                                    // cancelBtnBsStyle="danger"
                                                    onConfirm={() => this.setState({ success_msg: false })}
                                                >
                                                    Update Successfully!
                                                </SweetAlert>
                                            ) : null} */}
                                            <div className="form-group ">
                                                {/* <AvField name="user_name" label="User Name" value={userInformation.name} className="form-control " readOnly placeholder="Enter UserName" type="text" /> */}
                                                <AvField name="user_id" value={profileInfo.id} type="hidden" />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="fullName" label="Full Name" value={profileInfo.name} className="form-control" placeholder="Enter full name" type="text" />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="email" label="Email" value={profileInfo.email} className="form-control" placeholder="Enter your email" type="email" />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="mobile" readOnly label="Mobile" value={profileInfo.mobile} className="form-control" placeholder="Enter mobile" type="phone" />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="address" label="Address" value={profileInfoDetails.address} className="form-control" placeholder="Enter your address" type="text" />
                                            </div>

                                            <div className="form-group">

                                                <AvField type="select" label="Gender" name="gender" value={profileInfoDetails.gender} className="form-control" id="gender" title="gender" >
                                                    <option defaultValue>Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="others">Others</option>
                                                </AvField >
                                            </div>

                                            <div className="form-group">
                                                <AvField name="birthDate" label="Birth Date" value={profileInfoDetails.birth_date} className="form-control" placeholder="Enter your Birth Date" type="date" />
                                            </div>

                                            <div className="form-group">

                                                <AvField type="select" label="Division" className="form-control select2" value={profileInfoDetails.f_division_id} name="f_division_id" id="f_division_id" onChange={(e) => this.handleInputChange(e)} title="Division">
                                                    <option value="">Select Division</option>
                                                    {divisionLists.map(division =>
                                                        <option value={division.division_id}>{division.division_name}</option>
                                                    )}
                                                </AvField>

                                            </div>
                                            {/* {userInfo.f_division_id == userDistrict.f_division_id? userDistrict.district_name:"" } */}
                                            {/* { userInfo.f_zilla_id == userDistrict.district_id?"true":"false"} */}
                                            <div className="form-group">
                                                <AvField type="select" label="District" className="form-control select2" name="f_district_id" value={profileInfoDetails.f_zilla_id} id="f_district_id" onChange={(e) => this.handleInputChange(e)} title="District">
                                                    <option value="">
                                                        Select District</option>
                                                    {selectDistricts.map(district => (
                                                        <option value={district.district_id}>{district.district_name}</option>
                                                    ))}
                                                </AvField>

                                            </div>

                                            <div className="form-group">

                                                <AvField type="select" name="f_thana_id" label="Thana" id="f_thana_id" value={profileInfoDetails.f_thana_id} className="form-control select2" title="thana">
                                                    <option value="">
                                                        Select Thana</option>
                                                    {thanaLists.map(thana => (
                                                        <option value={thana.thana_id}>{thana.thana_name}</option>
                                                    ))}
                                                </AvField>

                                            </div>
                                            <div className="text-center mt-4">
                                                <Button type="submit" color="danger" onClick={() => this.setState({ success_msg: true })}
                                                    id="sa-success">Update</Button>
                                            </div>
                                        </AvForm>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { editProfile, showProfile })(UserProfile));

