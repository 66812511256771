import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

import { Container, Card, CardBody, Table, Input, CardTitle, InputGroup, InputGroupAddon, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

//Import Product Images
import img1 from "../../../assets/images/product/img-1.png";
import img2 from "../../../assets/images/product/img-2.png";
import img3 from "../../../assets/images/product/img-3.png";
import img4 from "../../../assets/images/product/img-4.png";
import img5 from "../../../assets/images/product/img-5.png";
import img6 from "../../../assets/images/product/img-6.png";
import { productDetails, cartDetails } from '../../../store/ecommerceProducts/actions';
//i18n
import { withNamespaces } from 'react-i18next';

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      productList: [
        { id: 1, img: img1, name: "Half sleeve T-shirt", color: "Maroon", price: "450", data_attr: 2, total: 900 },
        { id: 2, img: img2, name: "Light blue T-shirt", color: "Light blue", price: "225", data_attr: 6, total: 225 },
        { id: 3, img: img3, name: "Black Color T-shirt", color: "Black", price: "152", data_attr: 2, total: 304 },
        { id: 4, img: img4, name: "Hoodie (Blue)", color: "Blue", price: "145", data_attr: 2, total: 290 },
        { id: 5, img: img5, name: "Half sleeve T-Shirt", color: "Light orange", price: "138", data_attr: 8, total: 138 },
        { id: 6, img: img6, name: "Green color T-shirt", color: "Green", price: "152", data_attr: 2, total: 304 }
      ]
    };
    this.countUP.bind(this);
    this.countDown.bind(this);
    this.removeCartItem.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  removeCartItem = (id) => {


    let productList = this.state.productList;

    var filtered = productList.filter(function (item) {
      return item.id !== id;
    });

    this.setState({ productList: filtered });
  }

  countUP = (id, prev_data_attr) => {
    this.setState({
      productList: this.state.productList.map(p => (p.id === id ? { ...p, data_attr: prev_data_attr + 1 } : p))
    });
  }

  countDown = (id, prev_data_attr) => {
    this.setState({
      productList: this.state.productList.map(p => (p.id === id ? { ...p, data_attr: prev_data_attr - 1 } : p))
    });
  }


  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }
  cartList(cartID) {
    if(cartID){
      this.fetchCartDetails(cartID)
    }
  }

  fetchCartDetails = (cartID) => {

    cartDetails(cartID)
      .then((response) => {
        console.log('cart Details', response);
        this.setState({
          cartDetails: response.data.cart.cart_product_details,
          cart: response.data.cart
        });
        this.state.cartDetails = response.data.cart.cart_product_details;
        this.state.cart = response.data.cart;
        localStorage.setItem('cartData', JSON.stringify(response.data.cart));
      })
      .catch(err => {
        console.log(err)
      })
  }
  render() {
    let cartCount = JSON.parse(localStorage.getItem('cartData'));
    // console.log('cartCount', cartCount);
    let cartID = cartCount == undefined || cartCount == null && cartCount.cart_id == null ? 0 : cartCount.cart_id;
    if (cartCount != undefined) {
      cartCount = cartCount.total_quantity;
    } else {
      cartCount = 0;
      cartID = 0;
    }
    let cartDetailsList = [];

    let paginator;
    if (this.state.hasOwnProperty('cartDetails')) {
      cartDetailsList = this.state.cartDetails;
    }

    let cart = [];
    if (this.state.hasOwnProperty('cart')) {
      cart = this.state.cart;
    }

    const imageLink = process.env.REACT_APP_IMAGE_URL;

    return (
      <React.Fragment>

        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button" id="page-header-notifications-dropdown" onClick={() => this.cartList(cartID)} >
            <i className="fas fa-cart-plus"></i>
            <span className="badge badge-danger badge-pill">
              {cartCount}
            </span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-xl p-0" right>


            <SimpleBar className="cartPopUp" >
              <Row>
                <Col xl="12">
                  {
                   this.state.cart  && cartDetailsList ? "" :
                      <div className="d-flex justify-content-center m-5">
                        <div class="spinner-border text-info" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                  }
                  {
                    this.state.cart  && cartDetailsList.length > 0 ?
                      <Card>
                        <CardBody className="thead-light ">

                          <div className="table-responsive">


                            <Table className="table table-centered mb-0 table-nowrap">
                              <thead className="thead-light ">
                                <tr>
                                  <th style={{ padding: '2px' }}>S/N</th>
                                  <th style={{ padding: '2px' }}>Img</th>
                                  <th style={{ padding: '2px' }}>Desc</th>
                                  <th style={{ padding: '2px' }}>Price</th>
                                  <th style={{ padding: '2px' }}>Quantity</th>
                                  <th style={{ padding: '2px' }}>Total</th>
                                  {/* <th style={{padding: '2px'}}>Action</th> */}
                                </tr>
                              </thead>
                              <tbody >
                                {
                                  cartDetailsList.map((productList, index) =>
                                    <tr key={index} >
                                      <td className="font-size-12" style={{ padding: '0' }}>
                                        {index + 1}
                                      </td>
                                      <td style={{ padding: '0' }}>
                                        <img src={imageLink + 'upload/Products/' + productList.product.product_id + '/featureImage/' + productList.product.feature_image[0].feature_image} alt="product-img" title="product-img" className="avatar-sm" />
                                      </td>
                                      <td style={{ padding: '0' }}>
                                        <h5 className="font-size-12 text-truncate"><Link to={"/product-details/" + productList.product.product_slug} className="text-dark">{productList.product.product_name_en}</Link></h5>
                                        <p className="font-size-12 mb-0">Brand : <span className="font-weight-medium">{productList.product.brand.brand_name_en}</span></p>
                                      </td>
                                      <td className="font-size-12" style={{ padding: '0' }}>
                                        ৳ {productList.unit_price}
                                      </td>
                                      <td className="font-size-12 text-center" style={{ padding: '0' }}>
                                        {productList.quantity}
                                      </td>
                                      <td className="font-size-12" style={{ padding: '0' }}>
                                        ৳ {productList.total_amount}
                                      </td>
                                      {/* <td className="font-size-12 text-center" style={{padding: '0'}}>
                                    <Link to="#" onClick={() => this.removeCartItem(productList.cart_product_details_id)} className="action-icon text-danger"> <i className="mdi mdi-trash-can font-size-18"></i></Link>
                                  </td> */}
                                    </tr>
                                  )
                                }


                              </tbody>

                            </Table>

                          </div>
                          <div className="mt-5 fixed-bottom  bg-light">
                            <div className="d-flex justify-content-end py-1 bg-info">
                              <div className="font-weight-bold mr-3 text-light">Total Amount:</div>
                              <div className="mx-4 text-light">৳{cart.total_amount}</div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <Col sm="6" md="6" className="my-2">
                                <div className="text-sm-right mt-sm-0 d-flex justify-content-center">
                                  <Link to="/checkout" className="btn btn-secondary mx-2 ">
                                    <i className="mdi mdi-arrow-left mr-1"></i>Checkout</Link>
                                </div>
                              </Col>
                              <Col sm="6" md="6" className="my-2">
                                <div className="text-sm-right mt-sm-0 d-flex justify-content-center">
                                  <Link to="/cart" className="btn btn-success">
                                    <i className="mdi mdi-cart-arrow-right mr-1"></i>View Cart</Link>
                                </div>
                              </Col>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      :
                    <div>
                      <h3 className="text-secondary text-center my-5">Your Shopping Cart is empty</h3>
                    </div>
                  }
                </Col>
              </Row>

            </SimpleBar>
        

          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(NotificationDropdown);
