import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, Form, Label, Input, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, CardImg } from 'reactstrap';
import classnames from 'classnames';

//Import Star Ratings
import StarRatings from 'react-star-ratings';

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { categoryWiseProduct } from '../../store/ecommerceCategory/actions';
import { CustomPagination } from '../Settings/Common/CustomPagination';
import { globalProductSearch } from '../../store/ecommerceProducts/actions';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';

class EcommerceCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true,
            page: 1,
            per_page: 12,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            image_modal_scroll: false,
            categoryWiseProduct: {

            },
            obj: {

            },
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 },
            FilterClothes: [
                { id: 1, name: "T-shirts", link: "#" },
                { id: 2, name: "Shirts", link: "#" },
                { id: 3, name: "Jeans", link: "#" },
                { id: 4, name: "Jackets", link: "#" },
            ],
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount() {
        this.fetchCategoryWiseProduct();

    }



    fetchCategoryWiseProduct = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
        });
        // let url2 =this.props.location.pathname;
        // console.log(url2);
        const url = window.location.href;
        // console.log(url);
        const category_slug = url.split("/").pop();
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }


        categoryWiseProduct(category_slug, options)
            .then((response) => {
                // console.log('product Details', response);
                this.setState({
                    categoryProductDetails: response.data,
                });
                this.state.categoryProductDetails = response.data;
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });
            })
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index,
        }, () => {
            this.fetchCategoryWiseProduct();
        });
    }

    submitInput = (e) => {
        this.state.obj[e.target.name] = e.target.value;
        // console.log('asduisahd',this.state.obj[e.target.name])
        let min = parseInt(this.state.obj.min);
        let max = parseInt(this.state.obj.max);

        const url = window.location.href;
        const category_slug = url.split("/").pop();
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
        });
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }

        globalProductSearch(category_slug + "?minimum_price=" + min + "&maximum_price=" + max, options, category_slug).then(response => {
            this.setState({
                filterProducts: response.data
            })
            this.state.filterProducts = response.data;
            // console.log('priceRanger', this.state.filterProducts);
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
            });
        })
    }

    onChangeSlide(data, e) {
        // console.log(this.refs.NoUiSlider.slider.get())
        // console.log('e', e);

        let min = parseInt(data[0] || data[this.state.obj[e.target.name]]);
        let max = parseInt(data[1] || data[this.state.obj[e.target.name]]);
        // let dataFind=this.state.categoryProductDetails.data ;
        // let filterProduct=[];
        //  filterProduct  = dataFind.filter(product=>(min <= product.product_sale_price) || (product.product_sale_price <= max));
        //  console.log('filterProduct', filterProduct)
        // this.setState({
        //     priceRanger:filterProduct,
        // })
        // this.state.priceRanger = filterProduct;
        //    console.log('price', this.state.priceRanger.map(product=>product.product_name_en));
        const url = window.location.href;

        const category_slug = url.split("/").pop();

        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
        });
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }

        globalProductSearch("1?minimum_price=" + min + "&maximum_price=" + max, options, category_slug).then(response => {
            this.setState({
                filterProducts: response.data
            })
            this.state.filterProducts = response.data;
            // console.log('priceRanger', this.state.filterProducts);
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
            });
        })

    }


    render() {

        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let categoryProduct = [];
        // let categoryName=[];
        let paginator;
        let categoryBrand = []

        let brands = [];
        let brandList = [];

        let products = [];
        let filterProducts = [];
        let LargestNum = [];
        let MinNum = [];
        // let priceRangerFilter = [];
        if (this.state.hasOwnProperty('categoryProductDetails')) {
            categoryProduct = this.state.categoryProductDetails.data;
            // categoryName = this.state.categoryProductDetails.data.category;
            // console.log("all product", categoryProduct);
            categoryBrand = categoryProduct.map(product =>
                brands = product.brand
            );

            brandList = categoryBrand.reduce((acc, current) => {
                const x = acc.find(item => item.brand_id === current.brand_id);
                // console.log(x);
                if (!x) {
                    return acc.concat([current]);

                } else {
                    return acc;

                }
            }, []);

            if (categoryProduct != 0) {
                products = categoryProduct.map(product =>
                    filterProducts = product.product_sale_price
                );
                LargestNum = products.reduce((prev, curr) => {
                    return Math.max(prev, curr)
                });

                MinNum = products.reduce((prev, curr) => {
                    return Math.min(prev, curr)
                });

            } else {
                products = 0;
            }
            paginator = {
                // 'currentPage': this.state.categoryProductDetails.current_page,
                // 'currentPage': this.state.categoryProductDetails.current_page,
                'currentPage': this.state.categoryProductDetails.current_page,
                'pagesCount': this.state.categoryProductDetails.last_page,
                'total': this.state.categoryProductDetails.total,
                'to': this.state.categoryProductDetails.to,
                'from': this.state.categoryProductDetails.from,
            }
        }
        // console.log('products', products);

        let productFilter = [];
        if (this.state.hasOwnProperty('filterProducts')) {
            productFilter = this.state.filterProducts;
            // paginator = {
            //     'currentPage': this.state.filterProducts.current_page,
            //     'pagesCount': this.state.filterProducts.last_page,
            //     'total': this.state.filterProducts.total,
            //     'to': this.state.filterProducts.to,
            //     'from': this.state.filterProducts.from,
            // }
            // console.log('productFilter', productFilter)
        }
        // console.log('products', categoryProduct)





        const { product_id, product_code, product_name_bn, product_name_en, product_bar_code,
            f_category_info_id, f_brand_id, f_unit_id, product_stock_balance, product_buy_price,
            product_sale_price, product_market_price, discount, product_description,
            product_weight, product_sku_code, product_specification, minimum_oder_quantity,
            product_meta_tag, f_discount_type, status } = this.state.categoryWiseProduct;


        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Ecommerce" breadcrumbItem="Category" />
                        <Row>
                            <Col lg="3">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            Filter
                                        </CardTitle>
                                        <div>
                                            {categoryProduct.slice(0, 1).map((product) =>
                                                <h5 className="font-size-14 mb-3">{product.category.category_name_en}</h5>
                                            )}
                                            {/* Render Cloth Categories */}
                                            <ul className="list-unstyled product-list">
                                                {
                                                    brandList.map((product, key) =>
                                                        <li key={"_li_" + key} ><Link to={"/brand/" + product.brand_slug}><i className="mdi mdi-chevron-right mr-1"></i>{product.brand_name_en}</Link></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className="mt-4 pt-3">
                                            <h5 className="font-size-14 mb-4">Price</h5>
                                            <br />

                                            <Nouislider range={{ min: 1, max: 200000 }} tooltips={true} start={[3500, 200000]} onSlide={this.onChangeSlide.bind(this)}
                                                ref="NoUiSlider" connect />

                                        </div>
                                        <div className="form-group">
                                            <AvForm className="col-md-12 my-3">

                                                <div className="d-flex my-2">
                                                    <label className="mx-2">Min</label>
                                                    <AvInput type="number" onChange={(e) => this.submitInput(e)} className="mx-2" name="min" />৳
                                                </div>


                                                <div className="d-flex my-2">
                                                    <label className="mx-2">Max</label>
                                                    <AvInput type="number" onChange={(e) => this.submitInput(e)} className="mx-2" name="max" />৳
                                                </div>

                                            </AvForm>
                                        </div>

                                        <div className="mt-4 pt-3">
                                            <h5 className="font-size-14 mb-3">Discount</h5>
                                            <div className="custom-control custom-checkbox mt-2">
                                                <Input type="checkbox" value="0" className="custom-control-input" id="productdiscountCheck1" />
                                                <Label className="custom-control-label" htmlFor="productdiscountCheck1">Less than 10%</Label>
                                            </div>
                                            <div className="custom-control custom-checkbox mt-2">
                                                <Input type="checkbox" value="1" className="custom-control-input" id="productdiscountCheck2" />
                                                <Label className="custom-control-label" htmlFor="productdiscountCheck2">10% or more</Label>
                                            </div>
                                            <div className="custom-control custom-checkbox mt-2">
                                                <Input type="checkbox" value="2" className="custom-control-input" id="productdiscountCheck3" defaultChecked />
                                                <Label className="custom-control-label" htmlFor="productdiscountCheck3">20% or more</Label>
                                            </div>
                                            <div className="custom-control custom-checkbox mt-2">
                                                <Input type="checkbox" value="3" className="custom-control-input" id="productdiscountCheck4" />
                                                <Label className="custom-control-label" htmlFor="productdiscountCheck4">30% or more</Label>
                                            </div>
                                            <div className="custom-control custom-checkbox mt-2">
                                                <Input type="checkbox" value="4" className="custom-control-input" id="productdiscountCheck5" />
                                                <Label className="custom-control-label" htmlFor="productdiscountCheck5">40% or more</Label>
                                            </div>
                                            <div className="custom-control custom-checkbox mt-2">
                                                <Input type="checkbox" value="5" className="custom-control-input" id="productdiscountCheck6" />
                                                <Label className="custom-control-label" htmlFor="productdiscountCheck6">50% or more</Label>
                                            </div>
                                        </div>

                                        <div className="mt-4 pt-3">
                                            <h5 className="font-size-14 mb-3">Customer Rating</h5>
                                            <div>
                                                <div className="custom-control custom-checkbox mt-2">
                                                    <Input type="checkbox" className="custom-control-input" id="productratingCheck1" />
                                                    <Label className="custom-control-label" htmlFor="productratingCheck1">4 <i className="bx bx-star text-warning"></i>  & Above</Label>
                                                </div>
                                                <div className="custom-control custom-checkbox mt-2">
                                                    <Input type="checkbox" className="custom-control-input" id="productratingCheck2" />
                                                    <Label className="custom-control-label" htmlFor="productratingCheck2">3 <i className="bx bx-star text-warning"></i>  & Above</Label>
                                                </div>
                                                <div className="custom-control custom-checkbox mt-2">
                                                    <Input type="checkbox" className="custom-control-input" id="productratingCheck3" />
                                                    <Label className="custom-control-label" htmlFor="productratingCheck3">2 <i className="bx bx-star text-warning"></i>  & Above</Label>
                                                </div>
                                                <div className="custom-control custom-checkbox mt-2">
                                                    <Input type="checkbox" className="custom-control-input" id="productratingCheck4" />
                                                    <Label className="custom-control-label" htmlFor="productratingCheck4">1 <i className="bx bx-star text-warning"></i></Label>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg="9">

                                {/* <Row className="mb-3">
                                    <Col xl="4" sm="6">
                                        <div className="mt-2">
                                            {categoryProduct.slice(0, 1).map((product) =>
                                                <h5>{product.category.category_name_en}</h5>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg="8" sm="6">
                                        <Form className="mt-4 mt-sm-0 float-sm-right form-inline">
                                            <div className="search-box mr-2">
                                                <div className="position-relative">
                                                    <Input type="text" className="form-control border-0" placeholder="Search..." />
                                                    <i className="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                            <Nav className="product-view-nav" pills>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '1' })}
                                                        onClick={() => { this.toggleTab('1'); }}
                                                    >
                                                        <i className="bx bx-grid-alt"></i>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '2' })}
                                                        onClick={() => { this.toggleTab('2'); }}
                                                    >
                                                        <i className="bx bx-list-ul"></i>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Form>
                                    </Col>
                                </Row> */}

                                <Row>
                                    {
                                        productFilter != 0 ? productFilter.map((product, key) =>
                                            <Col xl="3" sm="6" key={"_col_" + key}>
                                                <Link to={"/product-details/" + product.product_slug}>
                                                    <Card>
                                                        <CardBody>
                                                            {product.product_stock_balance > 0 ?
                                                                " " : <div class="note no-stock">Out of stock</div>
                                                            }
                                                            <div className="product-img position-relative">
                                                                {
                                                                    !product.discount == 0 || !product.discount == null ?
                                                                        <div className="avatar-sm product-ribbon">
                                                                            <span className="avatar-title rounded-circle  bg-primary">
                                                                                {product.discount + "%"}
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }


                                                                {product.feature_image[0].feature_image == null || product.feature_image[0].feature_image == undefined ? [] :
                                                                    <Link to={"/product-details/" + product.product_slug}>
                                                                        <img src={imageLink + 'upload/Products/' + product.product_id + '/featureImage/' + product.feature_image[0].feature_image} alt="" height="200" className="img-fluid mx-auto d-block" />
                                                                    </Link>
                                                                }
                                                            </div>
                                                            <div className="mt-4 text-center">
                                                                <h5 className="mb-3 text-truncate text-primary"><Link to={"/product-details/" + product.product_slug}>{product.product_name_en}</Link></h5>
                                                                <div className="text-muted mb-3">
                                                                    <StarRatings
                                                                        rating={product.rating}
                                                                        starRatedColor="#F1B44C"
                                                                        starEmptyColor="#2D363F"
                                                                        numberOfStars={5}
                                                                        name='rating'
                                                                        starDimension="14px"
                                                                        starSpacing="3px"
                                                                    />
                                                                </div>
                                                                {!product.product_market_price == null || !product.product_market_price == 0 ? product.product_market_price != product.product_sale_price ?
                                                                    <h5 className="my-0"><span className="text-muted mr-2">
                                                                        <del className="text-danger font-weight-bold">৳{product.product_market_price}</del></span> <b>৳{product.product_sale_price}</b>
                                                                    </h5> : <h5 className="my-0"><span className="text-muted mr-2">
                                                                    </span><b>৳{product.product_sale_price}</b>
                                                                    </h5> : ''
                                                                }
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </Col>)
                                            : categoryProduct != 0 ?
                                                categoryProduct.map((product, key) =>
                                                    <Col xl="3" sm="6" key={"_col_" + key}>
                                                        <Link to={"/product-details/" + product.product_slug}>
                                                            <Card>
                                                                <CardBody>
                                                                    {product.product_stock_balance > 0 ?
                                                                        " " : <div class="note no-stock">Out of stock</div>
                                                                    }
                                                                    <div className="product-img position-relative">
                                                                        {
                                                                            !product.discount == 0 || !product.discount == null ?
                                                                                <div className="avatar-sm product-ribbon">
                                                                                    <span className="avatar-title rounded-circle  bg-primary">
                                                                                        {product.discount + "%"}
                                                                                    </span>
                                                                                </div>
                                                                                : null
                                                                        }


                                                                        {product.feature_image[0].feature_image == null || product.feature_image[0].feature_image == undefined ? [] :
                                                                            <Link to={"/product-details/" + product.product_slug}>
                                                                                <img src={imageLink + 'upload/Products/' + product.product_id + '/featureImage/' + product.feature_image[0].feature_image} alt="" height="200" className="img-fluid mx-auto d-block" />
                                                                            </Link>
                                                                        }
                                                                    </div>
                                                                    <div className="mt-4 text-center">
                                                                        <h5 className="mb-3 text-truncate text-primary"><Link to={"/product-details/" + product.product_slug}>{product.product_name_en}</Link></h5>
                                                                        <div className="text-muted mb-3">
                                                                            <StarRatings
                                                                                rating={product.rating}
                                                                                starRatedColor="#F1B44C"
                                                                                starEmptyColor="#2D363F"
                                                                                numberOfStars={5}
                                                                                name='rating'
                                                                                starDimension="14px"
                                                                                starSpacing="3px"
                                                                            />
                                                                        </div>
                                                                        {!product.product_market_price == null || !product.product_market_price == 0 ? product.product_market_price != product.product_sale_price ?
                                                                            <h5 className="my-0"><span className="text-muted mr-2">
                                                                                <del className="text-danger font-weight-bold">৳{product.product_market_price}</del></span> <b>৳{product.product_sale_price}</b>
                                                                            </h5> : <h5 className="my-0"><span className="text-muted mr-2">
                                                                            </span><b>৳{product.product_sale_price}</b>
                                                                            </h5> : ''
                                                                        }
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </Col>
                                                )
                                                :
                                                <div>
                                                    <h1 className="text-danger no-product-category">NO Products Found!</h1>
                                                </div>

                                    }
                                </Row>

                                <Row>
                                    <Col lg="12">
                                        <Pagination className="pagination pagination-rounded justify-content-center">
                                            {categoryProduct != 0 ? paginator &&
                                                <PaginationItem>
                                                    <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                                </PaginationItem> : ""

                                            }
                                        </Pagination>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EcommerceCategory;