import { VOUCHER_BUY_SUCCESS,VOUCHER_BUY_ERROR} from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const VoucherBuy = (state = initialState, action) => {
    switch (action.type) {
        case VOUCHER_BUY_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case VOUCHER_BUY_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default VoucherBuy;