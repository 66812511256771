import { ORDER_ERROR,ORDER_SUCCESS,EDIT_ORDER,SHOW_ORDER,CONFIRM_ORDER,CREATE_ORDER,CREATE_ORDER_STATUS } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createOrder = (order) => {
    return {
        type: CREATE_ORDER,
        payload: { order }
    }
}

export const showOrder = (order_id) => {
    return {
        type: SHOW_ORDER,
        payload: { order_id }
    }
}

export const editOrder = (order) => {
    return {
        type: EDIT_ORDER,
        payload: { order }
    }
}

export const createOrderStatus = (orderStatus) => {
    return {
        type: CREATE_ORDER_STATUS,
        payload: { orderStatus }
    }
}

export const confirmOrder = (order_id) => {
    return {
        type: CONFIRM_ORDER,
        payload: { order_id }
    }
}


export const orderSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: ORDER_SUCCESS,
        payload: msg
    }
}


export function orderShow(order_id){
    return getApi().get('order/details/'+order_id)
}

export function orderConfirm(order_id){
    return getApi().get('order/confirm/'+order_id)
}

export function statusList(params){
    return getApi().get('order/status/list',{
        params: params
    })
}

export function orderList(params){
    return getApi().get('order/product/list',{
        params: params
    })
}

export function orderTreeList(params){
    return getApi().get('order/list/tree',{
        params: params
    })
}

export function orderCancel(id,data){
    console.log('order_id', id)
    return getApi().post('order/cancel/'+id,data)
}


export const orderError = (error) => {
    return {
        type: ORDER_ERROR,
        payload: error
    }
}

