// import { quantity } from 'chartist';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table } from 'reactstrap';
import { voucherShow } from '../../store/actions';
import { orderList, orderShow } from '../../store/order/actions';
import order from '../../store/order/reducer';

class EcommerceOrderSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true,
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            modal_scroll: false,
            image_modal_scroll: false,
            Color: `warning`,
            modal: false,
            order: {

            },
            quantity:1,
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }

        }

    }



    componentDidMount() {
        // this.fetchOrderList();
        this.fetchOrderShow();
        let order = null;
        if (typeof this.props.location.state !== 'undefined' && this.props.location.state !== null && this.props.location.state.hasOwnProperty('orderData')) {
            order = this.props.location.state.orderData;
        }
        // JSON.parse(localStorage.removeItem('cartData'));
    }

    // fetchOrderList = () => {
    //     this.setState({
    //         displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
    //     });
    //     let options = {
    //         page: this.state.page,
    //         per_page: this.state.per_page,
    //         search: this.state.search
    //     }

    //     orderList(options).then((response) => {
    //         this.setState({
    //             orderList: response.data,
    //         });
    //         this.state.orderList = response.data;
    //         this.setState({
    //             displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
    //         });

    //     })
    //         .catch((err) => {
    //             console.log(err);
    //             this.setState({
    //                 displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
    //             });
    //         })
    // }

    fetchOrderShow = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
        });

        // const url = window.location.href;
        // const product_slug = url.split("/").pop();
        let url;
        let orderURL = window.location.href.split('/').pop();
        if(orderURL!=orderURL.split('?')){
        url = orderURL.split('?')
             
        }else{
            url=""
        }
        let type;
        let id;
        let order_Info;
        if (url !="") {
            order_Info = url[1].split('=');
            type = order_Info[0];
            id = order_Info[1];
            // console.log('Type', order_Info[0]);
            // console.log('Id', order_Info[1]);
        } else {
            order_Info = ""
        }


     

        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        if (type == 'order') {
            orderShow(id).then((response) => {
                this.setState({
                    orderShow: response.data,
                });
                this.state.orderShow = response.data;
                // console.log('orderShow', this.state.orderShow)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });

            })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                    });
                })
        }
        else if (type == 'voucher') {
            voucherShow(id).then((response) => {
                this.setState({
                    voucherDetails: response.data.VoucherGenerateList,
                });
                this.state.voucherDetails = response.data.VoucherGenerateList;
                // console.log('orderShow', this.state.orderShow)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });

            })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                    });
                })
        }
        else {
        
            let order = null;
            if (typeof this.props.location.state !== 'undefined' && this.props.location.state !== null && this.props.location.state.hasOwnProperty('orderData')) {
                order = this.props.location.state.orderData;
            }
            orderShow(order.order_id).then((response) => {
                this.setState({
                    orderShow: response.data,
                });
                this.state.orderShow = response.data;
                // console.log('orderShow', this.state.orderShow)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });

            })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                    });
                })
        }

    }



    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let orderLists = [];
        let orderData = [];
        let total_weight = 0;
        // let total_delivery_charge;
        // if (this.state.hasOwnProperty('orderList')) {
        //     orderLists = this.state.orderList.data.data;
        //     orderLists.slice(0, 1).map(data =>
        //         orderData = data
        //     )
        // }

        let url;
        let orderURL = window.location.href.split('/').pop();
        if(orderURL!=orderURL.split('?')){
        url = orderURL.split('?')
             
        }else{
            url=""
        }
        let type;
        let id;
        let order_Info;
        if (url !="") {
            order_Info = url[1].split('=');
            type = order_Info[0];
            id = order_Info[1];
            // console.log('Type', order_Info[0]);
            // console.log('Id', order_Info[1]);
        } else {
            order_Info = ""
        }

        let paymentGatewayName;
        let orderShowDetails = [];
        if (this.state.hasOwnProperty('orderShow')) {
            orderShowDetails = this.state.orderShow.orderDetails;
            // console.log('orderShowDetails', orderShowDetails);
            paymentGatewayName = orderShowDetails.payment_gateway.payment_gateway_name;
        }

        let voucherPaymentGatewayName;
        let voucherDetail=[];
        if (this.state.hasOwnProperty('voucherDetails')) {
            voucherDetail = this.state.voucherDetails;
            // console.log('voucherlist2222', voucherDetail);
            // voucherPaymentGatewayName = voucherDetail.payment_gateway.payment_gateway_name
        }


        // const { name, order_details, address, amount, total_amount, email, final_amount, order_code, order_id, order_date, order_type, phone, remarks, f_user_id, f_order_id, f_status_id, } = this.state.order;

        return (
            <div className="page-content my-5">
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <Container fluid>
                    <div className="box">
                        <div className="success alert">
                            <div className="alert-body">
                                THANK YOU FOR YOUR PURCHASE !
                            </div>
                        </div>
                    </div>
                {
                    type == "order" &&
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <div className="border border-secondary p-4 bg-light">
                                <h6>You will receive an order confirmation email with details of your order.</h6>
                                <h4>Your Order is #{orderShowDetails.order_code}</h4>
                                <Link to="/home"><button className="btn btn-outline-info my-3">Continue Shopping</button></Link>
                            </div>

                            <div className="border border-secondary p-3 my-2  bg-light">
                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap">
                                        <thead>
                                            <tr>
                                                <th scope="col">Product</th>
                                                <th scope="col">Product Name</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderShowDetails.order_details != undefined && orderShowDetails.order_details != null &&
                                                orderShowDetails.order_details.map((orders) =>

                                                    <tr>
                                                        <th scope="row">
                                                            <div>
                                                                <img src={imageLink + 'upload/Products/' + orders.product.product_id + '/featureImage/' + orders.product.feature_image[0].feature_image} alt="" className="avatar-sm" />
                                                            </div>
                                                        </th>
                                                        <td>
                                                            <div>
                                                                <h5 className="text-truncate font-size-14">{orders.product.product_name_en}</h5>
                                                                <p className="text-muted mb-0">৳ {orders.unit_price} x {orders.quantity}</p>
                                                                <p>{orders.product.is_delivery_charge_free == 1 ? <i className="mdi mdi-truck-fast mr-1 text-success"> <span className='ml-1'>Delivery Free</span></i> : ''}  </p>
                                                            </div>
                                                        </td>
                                                        <td>৳ {orders.total_amount}</td>
                                                    </tr>


                                                )
                                            }




                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Sub Total:</h6>
                                                </td>
                                                <td>
                                                    ৳ {orderShowDetails.total_amount}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Delivery Charge:</h6>
                                                </td>
                                                <td>
                                                    ৳{orderShowDetails.total_delivery_charge}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Grand Total:</h6>
                                                </td>
                                                <td>
                                                    ৳ {orderShowDetails.final_amount}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="row border border-secondary p-4 mb-2  bg-light">
                                <div className="col-md-6">
                                    <h5 className="font-weight-bold">Delivery Address</h5>
                                    <p>{orderShowDetails.address}</p>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="font-weight-bold">Delivery Method</h5>
                                    <p>Courier</p>
                                </div>
                            </div>
                            <div className="row border border-secondary p-4  bg-light">
                                <div className="col-md-6">
                                    <h5 className="font-weight-bold">Billing information</h5>
                                    <p>Name: {orderShowDetails.name}</p>
                                    <p>Mobile: {orderShowDetails.phone}</p>
                                    <p>Order Date: {orderShowDetails.order_date}</p>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="font-weight-bold">Payment Method</h5>
                                    <p>{paymentGatewayName}</p>
                                    <h5 className="font-weight-bold">Order Status: <span className="text-success">{orderShowDetails.status}</span></h5>
                                </div>
                                <div className="col-md-12">
                                    <div className="text-center">
                                        <h5>Expected Date of Delivery</h5>
                                        <p className="text-danger font-weight-bold">{new Date(new Date(orderShowDetails.order_date).setDate(new Date(orderShowDetails.order_date).getDate() + 3)).toDateString()} TO {new Date(new Date(orderShowDetails.order_date).setDate(new Date(orderShowDetails.order_date).getDate() + 5)).toDateString()}</p>
                                    </div>
                                    <h6>Delivery Timeline</h6>
                                    <div className="my-3">
                                        <ul class="list-unstyled">
                                            <li className="mb-2"><div className="d-flex align-items-center"><i className="bx bx-envelope fa-1x mx-1 text-info"></i><span>Inside Dhaka: 3-5 working days (max)</span></div></li>
                                            <li className="mb-2"><div className="d-flex align-items-center"><i className="bx bx-envelope fa-1x mx-1 text-info"></i><span>Outside Dhaka: 7-10 working days (max)</span></div></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }
                {
                    type == "voucher"&&
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <div className="border border-secondary p-4 bg-light">
                                <h6>You will receive an order confirmation email with details of your order.</h6>
                                <h4>Your Order is #{voucherDetail.code}</h4>
                                <Link to="/home"><button className="btn btn-outline-info my-3">Continue Shopping</button></Link>
                            </div>

                            <div className="border border-secondary p-3 my-2  bg-light">
                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap">
                                        <thead>
                                            <tr>
                                                <th scope="col">Voucher</th>
                                                <th scope="col">Voucher Name</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {orderShowDetails.order_details != undefined && orderShowDetails.order_details != null &&
                                                orderShowDetails.order_details.map((orders) => */}

                                                    <tr>
                                                        <th scope="row">
                                                            <div>
                                                                <img src={imageLink + '/voucher/' + voucherDetail.f_voucher_id +'/'+voucherDetail.voucher_image} alt="" className="avatar-sm" />
                                                            </div>
                                                        </th>
                                                        <td>
                                                            <div>
                                                                <h5 className="text-truncate font-size-14">{voucherDetail.voucher_name}</h5>
                                                                <p className="text-muted mb-0">৳ {voucherDetail.voucher_buy_price} x {this.state.quantity}</p>
                                                            </div>
                                                        </td>
                                                        <td>৳ {voucherDetail.amount}</td>
                                                    </tr>




                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Sub Total:</h6>
                                                </td>
                                                <td>
                                                    ৳ {voucherDetail.amount}
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td colspan="2">
                                                    <h6 className="m-0 text-right">Grand Total:</h6>
                                                </td>
                                                <td>
                                                    ৳ {voucherDetail.final_amount}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            {/* <div className="row border border-secondary p-4 mb-2  bg-light">
                                <div className="col-md-6">
                                    <h5 className="font-weight-bold">Delivery Address</h5>
                                    <p>{voucherDetail.user_address}</p>
                                </div>
                            </div> */}
                            <div className="row border border-secondary p-4  bg-light">
                                <div className="col-md-6">
                                    <h5 className="font-weight-bold">Billing information</h5>
                                    <p>Name: {voucherDetail.user_name}</p>
                                    <p>Mobile: {voucherDetail.user_phone}</p>
                                    <p>Address: {voucherDetail.user_address}</p>
                                    <p>Order Date: {moment(new Date(voucherDetail.insert_date_time)).format("YYYY-MM-DD")}</p>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="font-weight-bold">Payment Method</h5>
                                    <p>{voucherDetail.f_payment_gateway_id == 3 ?'SSLCommerz':''}</p>
                                    <h5 className="font-weight-bold">Order Status: <span className="text-success">{voucherDetail.status}</span></h5>
                                </div>
                                {/* <div className="col-md-12">
                                    <div className="text-center">
                                        <h5>Expected Date of Delivery</h5>
                                        <p className="text-danger font-weight-bold">{new Date(new Date(orderShowDetails.order_date).setDate(new Date(orderShowDetails.order_date).getDate() + 3)).toDateString()} TO {new Date(new Date(orderShowDetails.order_date).setDate(new Date(orderShowDetails.order_date).getDate() + 5)).toDateString()}</p>
                                    </div>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                }
                {
                    type != "voucher" && type != "order"&&
                    <Row>
                    <Col md="6" lg="6" sm="12">
                        <div className="border border-secondary p-4 bg-light">
                            <h6>You will receive an order confirmation email with details of your order.</h6>
                            <h4>Your Order is #{orderShowDetails.order_code}</h4>
                            <Link to="/home"><button className="btn btn-outline-info my-3">Continue Shopping</button></Link>
                        </div>

                        <div className="border border-secondary p-3 my-2  bg-light">
                            <div className="table-responsive">
                                <Table className="table table-centered table-nowrap">
                                    <thead>
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Product Name</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderShowDetails.order_details != undefined && orderShowDetails.order_details != null &&
                                            orderShowDetails.order_details.map((orders) =>

                                                <tr>
                                                    <th scope="row">
                                                        <div>
                                                            <img src={imageLink + 'upload/Products/' + orders.product.product_id + '/featureImage/' + orders.product.feature_image[0].feature_image} alt="" className="avatar-sm" />
                                                        </div>
                                                    </th>
                                                    <td>
                                                        <div>
                                                            <h5 className="text-truncate font-size-14">{orders.product.product_name_en}</h5>
                                                            <p className="text-muted mb-0">৳ {orders.unit_price} x {orders.quantity}</p>
                                                            <p>{orders.product.is_delivery_charge_free == 1 ? <i className="mdi mdi-truck-fast mr-1 text-success"> <span className='ml-1'>Delivery Free</span></i> : ''}  </p>
                                                        </div>
                                                    </td>
                                                    <td>৳ {orders.total_amount}</td>
                                                </tr>


                                            )
                                        }




                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Sub Total:</h6>
                                            </td>
                                            <td>
                                                ৳ {orderShowDetails.total_amount}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Delivery Charge:</h6>
                                            </td>
                                            <td>
                                                ৳{orderShowDetails.total_delivery_charge}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Grand Total:</h6>
                                            </td>
                                            <td>
                                                ৳ {orderShowDetails.final_amount}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="12">
                        <div className="row border border-secondary p-4 mb-2  bg-light">
                            <div className="col-md-6">
                                <h5 className="font-weight-bold">Delivery Address</h5>
                                <p>{orderShowDetails.address}</p>
                            </div>
                            <div className="col-md-6">
                                <h5 className="font-weight-bold">Delivery Method</h5>
                                <p>Courier</p>
                            </div>
                        </div>
                        <div className="row border border-secondary p-4  bg-light">
                            <div className="col-md-6">
                                <h5 className="font-weight-bold">Billing information</h5>
                                <p>Name: {orderShowDetails.name}</p>
                                <p>Mobile: {orderShowDetails.phone}</p>
                                <p>Order Date : {orderShowDetails.order_date}</p>
                            </div>
                            <div className="col-md-6">
                                <h5 className="font-weight-bold">Payment Method</h5>
                                <p>{paymentGatewayName}</p>
                                <h5 className="font-weight-bold">Order Status: <span className="text-success">{orderShowDetails.status}</span></h5>
                            </div>
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h5>Expected Date of Delivery</h5>
                                    <p className="text-danger font-weight-bold">{new Date(new Date(orderShowDetails.order_date).setDate(new Date(orderShowDetails.order_date).getDate() + 3)).toDateString()} TO {new Date(new Date(orderShowDetails.order_date).setDate(new Date(orderShowDetails.order_date).getDate() + 5)).toDateString()}</p>
                                </div>
                                <h6>Delivery Timeline</h6>
                                                                <div className="my-3">
                                                                    <ul class="list-unstyled">
                                                                        <li className="mb-2"><div className="d-flex align-items-center"><i className="bx bx-envelope fa-1x mx-1 text-info"></i><span>Inside Dhaka: 3-5 working days (max)</span></div></li>
                                                                        <li className="mb-2"><div className="d-flex align-items-center"><i className="bx bx-envelope fa-1x mx-1 text-info"></i><span>Outside Dhaka: 7-10 working days (max)</span></div></li>
                                                                    </ul>
                                                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                }
                    
                </Container>
            </div>
        );
    }
}


export default EcommerceOrderSuccess;