import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import googlePlay from '../../assets/images/googlePlay.png';
import { help } from "../../store/ecommerceHelp/actions";
import nagad from '../../assets/images/paymentMethod/nagad.svg';
import bkash from '../../assets/images/paymentMethod/bkash.svg';
import mastercard from '../../assets/images/paymentMethod/mastercard.svg';
import visa from '../../assets/images/paymentMethod/visa.svg';
import ssl from '../../assets/images/paymentMethod/ssl.png';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.fetchHelpList();
    }

    fetchHelpList = () => {

        help().then((response) => {
            this.setState({
                helpList: response.data,
            });
            this.state.helpList = response.data;
            // console.log('helpList', this.state.helpList );
        })
            .catch((err) => {
                console.log(err);
            })
    }

    render() {
        let helpOptions = [];

        if (this.state.hasOwnProperty('helpList')) {
            helpOptions = this.state.helpList;
        }
        return (
            <React.Fragment>
                <footer className="footer">
                    <Container fluid={true}>
                        <Row>
                            <Col md={3}>
                                <h4>Download</h4>
                                <div className="my-3">
                                    <Link to={"//play.google.com/store/apps/details?id=com.hovata.autot"} target="_blank" >
                                        <img src={googlePlay} className="img-fluid d-block w-50" alt="google Link" />
                                    </Link>
                                </div>
                            </Col>
                            <Col md={3}>
                                <h4>Menu</h4>
                                <div className="my-3">
                                    <ul class="list-unstyled">
                                        <li><Link to={"/help" + helpOptions.faq_url}>{this.props.t('Faq')}</Link></li>
                                        <li><Link to={"/help" + helpOptions.privacy_policy_url} >{this.props.t('Privacy Policy')}</Link></li>
                                        <li><Link to={"/help" + helpOptions.about_us_url} >{this.props.t('About Us')}</Link></li>
                                        <li><Link to={"/help" + helpOptions.contact_us_url}>{this.props.t('Contact Us')}</Link></li>
                                        <li> <Link to={"/help" + helpOptions.terms_conditions_url} className="text-primary">
                                            Terms & Conditions
                                        </Link> </li>
                                        <li> <Link to={"/help" + helpOptions.return_refund_url} className="text-primary">
                                            Return and Refund Policies
                                        </Link> </li>
                                    </ul>
                                </div>

                            </Col>
                            <Col md={3}>
                                <h4>Contact Us</h4>
                                <div className="my-3">
                                    <ul class="list-unstyled">
                                        <li className="mb-2"><div className="d-flex align-items-center"><i className="fas fa-phone-alt fa-1x mx-1 text-info"></i><span>+8801701250250</span></div></li>
                                        <li className="mb-2"><div className="d-flex align-items-center"><i className="bx bx-envelope fa-1x mx-1 text-info"></i><span>support@autot.com.bd</span></div></li>
                                        <li><div className="d-flex"><i className="fas fa-map-marker-alt fa-1x mx-1 text-info mt-1"></i><span>49/1-A Latif Complex Purana Paltan Lane, 5th Floor Dhaka, 1000</span></div></li>
                                    </ul>
                                </div>
                               
                            </Col>
                            <Col md={3}>
                                <h4>Get in Touch</h4>
                                <div className="d-flex">
                                    <a href="https://www.facebook.com/AutoTSystem" target="_blank" alt="facebook"> <i className="fab fa-facebook-square text-primary mx-2 h2"></i></a>
                                    <a href="https://www.youtube.com/channel/UC7wD7Cqon94jMISIsuoYIEg" target="_blank" alt="youtube"> <i className="fab fa-youtube text-danger mx-2 h2"></i></a>
                                    <a href="https://www.instagram.com/autotsystem/" target="_blank" alt="instagram"> <i className="fab fa-instagram text-dark mx-2 h2"></i></a>
                                </div>
                                <h4>Delivery Timeline</h4>
                                <div className="my-3">
                                    <ul class="list-unstyled">
                                        <li className="mb-2"><div className="d-flex align-items-center"><i className="bx bx-envelope fa-1x mx-1 text-info"></i><span>Inside Dhaka: 3-5 working days (max)</span></div></li>
                                        <li className="mb-2"><div className="d-flex align-items-center"><i className="bx bx-envelope fa-1x mx-1 text-info"></i><span>Outside Dhaka: 7-10 working days (max)</span></div></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-end ">
                            <Col md={12}>
                                <img src={ssl} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-between">
                            <div>
                                <p className="footer-p mb-3 mx-2"> {new Date().getFullYear()} © hovata.</p>
                            </div>
                            <div>
                                <p className=" footer-p mb-3 mx-2">Design & Develop by Hovata technologies</p>
                            </div>
                        </div>
                    </Container>
                </footer>
            </React.Fragment>
        );
    }

};

export default withRouter(withNamespaces()(Footer));
