import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

//Account Redux states
import { REGISTER_USER, GET_OTP } from './actionTypes';
import { registerUserSuccessful, registerUserFailed } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postJwtData } from '../../../helpers/self_accounts_settings_helper';



// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.


function* GetOTP({ payload: { data } }) {
    console.log('data',data)

    try {
        const response = yield call('user/vehicleOwnerGetOTP', data);
        console.log('response',response);
        if (response.success == false) {
            // alert(response.message['message']);
            yield put(registerUserFailed(response.message.message));
            console.log("response.message.message", response.message.message);
        } else if (response.success == true) {
            yield put(registerUserSuccessful(response.data));
            console.log('data find', response.data);
            // localStorage.setItem('cartData', JSON.stringify(response.data.cart));
            // window.location.reload();
        }

    } catch (error) {
        yield put(registerUserFailed(error));
    }
}

// function* registerUser({ payload: { user } }) {
//     try {
//         if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//             const response = yield call(postJwtRegister, '/users', user);
//             yield put(registerUserSuccessful(response));
//         }

//     } catch (error) {
//         yield put(registerUserFailed(error));
//     }
// }

export function* watchUserRegister() {
    // yield takeEvery(REGISTER_USER, registerUser);
    yield takeEvery(GET_OTP, GetOTP);
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;