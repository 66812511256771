import { BANNER_ERROR,BANNER_SUCCESS,EDIT_BANNER,SHOW_BANNER,CREATE_BANNER } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createBanner = (banner) => {
    console.log('ji',banner)
    return {
        type: CREATE_BANNER,
        payload: { banner }
    }
}

export const showBanner = (id) => {
    return {
        type: SHOW_BANNER,
        payload: { id }
    }
}

export const editBanner = (banner) => {
    return {
        type: EDIT_BANNER,
        payload: { banner }
    }
}



export const bannerSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: BANNER_SUCCESS,
        payload: msg
    }
}


export function bannerShow(id){
    return getApi().get('users/id/'+id)
}


export function bannerList(params){
    return getApi().get('banner/list',{
        params: params
    })
}
export function sliderList(params){
    return getApi().get('slider/master',{
        params: params
    })
}
export const bannerError = (error) => {
    return {
        type: BANNER_ERROR,
        payload: error
    }
}

export function sliderUpload(params,image_id){
    console.log("image_id",image_id)
    if(image_id){
        return getApi().put('banner/slider/edit/'+image_id,params)
    }else{
        return getApi().post('banner/slider/upload',params);
    }
}
