import { getApi } from '../../services/httpService';
import {  ADD_TO_CART,UPDATE_CART, CART_SUCCESS,  CART_ERROR} from './actionTypes';


export const createCart = (product) => {
    console.log('addCart', product)
    return {
        type: ADD_TO_CART,
        payload: { product }
    }
}

export function updateCart(product){
    // console.log("hello",product);
    // return getApi().post('cart/update',params);
    return {
        type: UPDATE_CART,
        payload: { product }
    }
}

export const cartSuccess = (response) => {
    return {
        type: CART_SUCCESS,
        payload: response
    }
}

export const cartError = (error) => {
    return {
        type: CART_ERROR,
        payload: error
    }
}


