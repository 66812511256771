import { getApi } from "../../services/httpService";

export function courierList(){
    return getApi().get('Courier/lists')
}

export function courierPricing(data){
    return getApi().get('Courier/pricing/lists', {
        params: data
    })
}
