import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Table, Input, CardTitle, InputGroup, InputGroupAddon, Button, CardImg } from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Product Images
import img1 from "../../assets/images/product/img-1.png";
import img2 from "../../assets/images/product/img-2.png";
import img3 from "../../assets/images/product/img-3.png";
import img4 from "../../assets/images/product/img-4.png";
import img5 from "../../assets/images/product/img-5.png";
import img6 from "../../assets/images/product/img-6.png";
import { productDetails, cartDetails } from '../../store/ecommerceProducts/actions';
import { updateCart } from '../../store/cart/actions';
import NotificationDropdown from "../../components/CommonForBoth/TopbarDropdown/NotificationDropdown";

class EcommerceCart extends Component {
    constructor(props) {
        super(props);
        this.state = {

            displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }

        };
        this.countUP.bind(this);
        this.countDown.bind(this);
        this.removeCartItem.bind(this);
    }

    removeCartItem = (cart_product_details_id, cart_id) => {

        let quantity = 0;
        let productS = {
            cart_product_details_id: cart_product_details_id,
            cart_id: cart_id,
            quantity: quantity
        }
        this.props.updateCart(productS)
    }

    countUP = (cart_product_details_id, cart_id, quantity) => {
        let productS = {
            cart_product_details_id: cart_product_details_id,
            cart_id: cart_id,
            quantity: ++quantity
        }
        this.props.updateCart(productS)
    }

    countDown = (cart_product_details_id, cart_id, quantity) => {

        let productS = {
            cart_product_details_id: cart_product_details_id,
            cart_id: cart_id,
            quantity: --quantity
        }
        this.props.updateCart(productS)
    }
    componentDidMount() {
        let cartCount = JSON.parse(localStorage.getItem('cartData'));
        let cartID = cartCount == null || cartCount == undefined && cartCount.cart_id == undefined ? 0 : cartCount.cart_id;
        this.fetchCartDetails(cartID)
    }
    fetchCartDetails = (cartID) => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
        });
        cartDetails(cartID)
            .then((response) => {
                console.log('cart Details', response);
                this.setState({
                    cartDetails: response.data.cart,
                });
                this.state.cartDetails = response.data.cart;
                localStorage.setItem('cartData', JSON.stringify(response.data.cart));
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });
            })
    }
    render() {
        let cartDetailsList = [];
        let cartInfo = [];
        if (this.state.hasOwnProperty('cartDetails')) {
            cartDetailsList = this.state.cartDetails.cart_product_details;
            cartInfo = this.state.cartDetails;
        }
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <NotificationDropdown style={{ display: 'none' }} cartVal={1} />

                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Ecommerce" breadcrumbItem="Cart" />

                        <Row>
                            <Col xl="8" lg="8" md="12" sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table table-centered mb-0 table-nowrap">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>Img</th>
                                                        <th>Product Desc</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th colSpan="2">Total</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        cartDetailsList.map((productList, index) =>
                                                            <tr key={index}>
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td>
                                                                    <img src={imageLink + 'upload/Products/' + productList.product.product_id + '/featureImage/' + productList.product.feature_image[0].feature_image} alt="product-img" title="product-img" className="avatar-md" />
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 text-truncate"><Link to={"/product-details/" + productList.product.product_slug} className="text-dark">{productList.product.product_name_en}</Link></h5>
                                                                    <p className="mb-0">Brand : <span className="font-weight-medium">{productList.product.brand.brand_name_en}</span></p>
                                                                </td>
                                                                <td>
                                                                    ৳ {productList.unit_price}
                                                                </td>
                                                                <td>
                                                                    <div style={{ width: "120px" }}>
                                                                        <InputGroup>
                                                                            <InputGroupAddon addonType="append">
                                                                                <Button color={productList.quantity == 1 ? "secondary" : "primary"} disabled={productList.quantity == 1} onClick={() => { this.countDown(productList.cart_product_details_id, productList.f_cart_id, productList.quantity) }}>-</Button>
                                                                            </InputGroupAddon>
                                                                            <Input type="text" value={productList.quantity} name="demo_vertical" readOnly />
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <Button color={productList.quantity == 10 ? "secondary" : "primary"} disabled={productList.quantity == 10} onClick={() => { this.countUP(productList.cart_product_details_id, productList.f_cart_id, productList.quantity) }}>+</Button>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    ৳ {productList.total_amount}
                                                                </td>   
                                                                <td>
                                                                    <Link to="#" onClick={() => this.removeCartItem(productList.cart_product_details_id, productList.f_cart_id)} className="action-icon text-danger "> <i className="mdi mdi-trash-can font-size-18 "></i></Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }


                                                </tbody>
                                            </Table>
                                        </div>
                                        <Row className="mt-4">
                                            <Col sm="6">
                                                <div className="my-2 mt-sm-0">
                                                    <Link to="/home" className="btn btn-secondary">
                                                        <i className="mdi mdi-arrow-left mr-1"></i> Continue Shopping </Link>
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="text-sm-right my-2 mt-sm-0">
                                                    <Link to="/checkout" className="btn btn-success">
                                                        <i className="mdi mdi-cart-arrow-right mr-1"></i> Checkout </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4" lg="4" md="12" sm="12">

                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-3 text-primary">Order Summary</CardTitle>

                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Grand Total :</td>
                                                        <td>৳ {cartInfo.total_amount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Discount : </td>
                                                        <td>৳ {cartInfo.total_discount}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>Shipping Charge :</td>
                                                        <td>৳ 100</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Estimated Tax : </td>
                                                        <td>৳ 0</td>
                                                    </tr> */}
                                                    <tr>
                                                        <th>Total :</th>
                                                        <th>৳ {cartInfo.total_amount  - cartInfo.total_discount}</th>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                {/* <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Card Details</CardTitle>

                                        <div className="card bg-primary text-white visa-card mb-0">
                                            <CardBody>
                                                <div>
                                                    <i className="bx bxl-visa visa-pattern"></i>

                                                    <div className="float-right">
                                                        <i className="bx bxl-visa visa-logo display-3"></i>
                                                    </div>

                                                    <div>
                                                        <i className="bx bx-chip h1 text-warning"></i>
                                                    </div>
                                                </div>

                                                <Row className="mt-5">
                                                    <Col xs="4">
                                                        <p>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                        </p>
                                                    </Col>
                                                    <Col xs="4">
                                                        <p>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                        </p>
                                                    </Col>
                                                    <Col xs="4">
                                                        <p>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                            <i className="fas fa-star-of-life m-1"></i>
                                                        </p>
                                                    </Col>
                                                </Row>

                                                <div className="mt-5">
                                                    <h5 className="text-white float-right mb-0">12/22</h5>
                                                    <h5 className="text-white mb-0">Fredrick Taylor</h5>
                                                </div>
                                            </CardBody>
                                        </div>
                                    </CardBody>
                                </Card> */}
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
    const { msg, error, success } = state.Cart;
    return { msg, error, success };
}
export default withRouter(connect(mapStatetoProps, { updateCart })(EcommerceCart, NotificationDropdown));