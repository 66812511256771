import React, { Component } from "react";
import { CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
// import {CustomPagination} from "../../Settings/Common/CustomPagination";
// import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
import {productList} from '../../store/product/actions';
import {homefeaturesList} from '../../store/homefeatures/actions';
import { Multiselect } from 'multiselect-react-dropdown';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import { } from '../../../store/brand/actions';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class HomeFeaturesMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                featuresMap:{

                },
            };
    }

    componentDidMount()
    {
            this.fetchproductList();
            this.fetchhomefeaturesList();
    }

    updateHomeFeatureSetupObject = (name, value) => {
        let homeFeatureCopy= Object.assign({}, this.state.featuresMap);
        homeFeatureCopy[name] = value;

        this.setState({
            featuresMap: homeFeatureCopy
        });
    }

    fetchproductList = () => {
        productList().then((response) => {
            this.setState({
                productList :response.data, 
            });
            this.state.productList = response.data;
        },(respose) => {
      
        })
        .catch((err) => {
            console.log(err);
        })
    }

    onProductSelect = (selectedList, selectedItem)=> {
        let selected = selectedList;
        this.state.featuresMap.productsList = selectedList;
        this.setState({
            productsList: selected,
          });
        this.updateHomeFeatureSetupObject('productsList', selected);
    }        
    
    onProductRemove = (selectedList, removedItem)=> {
        let selected = selectedList;
        this.setState({
            productsList: selected,
          });
        this.updateHomeFeatureSetupObject('productsList', selected);
    }

    fetchhomefeaturesList = () => {
        homefeaturesList().then((response) => {
            this.setState({
                homefeaturesList :response.data,
            });
            this.state.homefeaturesList = response.data;
        },(respose) => {

        })
        .catch((err) => {
            console.log(err);
        })
    }
    onFeatureSelect = (selectedList, selectedItem)=> {
        let selected = selectedList;
        this.state.featuresMap.featureList = selectedList;
        this.setState({
            featureList: selected,
          });
        this.updateHomeFeatureSetupObject('featureList', selected);
    }
    
    onFeatureRemove = (selectedList, removedItem)=> {
        let selected = selectedList;
        this.setState({
            featureList: selected,
          });
        this.updateHomeFeatureSetupObject('featureList', selected);
    }

    //modal
    // tog_scroll = (brand) => {
    //     this.setState(prevState => ({
    //       modal_scroll: !prevState.modal_scroll,
    //     }));
    //     this.state.brand = brand;
    //     this.removeBodyCss();
    //   }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createBrand(values);
            this.fetchbrandList();
            this.form && this.form.reset();
            // window.location.reload(); 
        }
       
    //    handleEditSubmit(event, values) {  
    //         this.props.editBrand(values);
    //         this.setState(prevState => ({
    //             modal_scroll: !prevState.modal_scroll,
    //           }));
    //         this.fetchbrandList();
    //         this.form && this.form.reset();
    //         alert("Brand Updated Successfully"); 
    //         // window.location.reload();
    //    }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchproductList();
            this.fetchhomefeaturesList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchproductList();
                this.fetchhomefeaturesList();
            });
        }

    render() {
        let productListMap = [];
        let homefeaturesListMap = [];
        let paginator;
        if (this.state.hasOwnProperty('productList')) { productListMap = this.state.productList; }
        console.log('kkkkk',productListMap)
        if (this.state.hasOwnProperty('homefeaturesList')) { homefeaturesListMap = this.state.homefeaturesList; }
        console.log('hhhh',homefeaturesListMap)
        // if (this.state.hasOwnProperty('brandList')) {
        //     brandListMap = this.state.brandList.data;
            
        //     // paginator = {
        //     //     'currentPage':this.state.brandList.current_page,
        //     //     'pagesCount':this.state.brandList.last_page,
        //     //     'total':this.state.brandList.total,
        //     //     'to':this.state.brandList.to,
        //     //     'from':this.state.brandList.from,
        //     // }
        // }

        const {product_name_en,productsList,featureList} = this.state.featuresMap;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
          console.log("this.props.success",this.props.success)
          if(this.props.success != ''){
            setTimeout(function() {
                window.location.reload();
            }, 1000);
             
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Home and Features Map" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    {/* <CardBody>
                                        <CardTitle>Home And Features Map List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Brand Logo</th>
                                                        <th>Brand Name</th>
                                                        <th>Brand Name (Bangla)</th>
                                                        <th>Code</th>
                                                        <th>Category</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {brandListMap.map((brand, index) =>
                                                    <tr key={brand.brand_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{brand.brand_name_en}</td>
                                                        <td>{brand.brand_name_bn}</td>
                                                        <td>{brand.brand_code}</td>
                                                        <td>{brand.category == null?brand.f_category_info_id:brand.category.category_name_en}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(brand) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody> */}
                                    {/* {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      } */}
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{product_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   {/* <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }} ref={c => (this.form = c)}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Home and Features Map Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="form-group">
                                               <AvField name="brand_code" readonly label="Brand Code" value={brand_code} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group ">
                                               <AvField name="brand_name_en" label={reqLabel('Brand Name')} value={brand_name_en} className="form-control "  type="text" />
                                               <AvField name="brand_id" id="brand_id" value={brand_id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="brand_name_bn" label="Brand Name (Bangla)" value={brand_name_bn} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group">
                                                <AvField name="f_category_info_id" label="Category" value={f_category_info_id} type="select"className="custom-select" >
                                                    <option defaultValue>Select Category</option>
                                                        {categoryList.map((category,index)=>
                                                    <option value={category.category_id}>{category.category_name_en}</option>
                                                        )}
                                                </AvField>           
                                           </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div> */}
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Home and Features Map</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                <Col md="1">
                                                    <label htmlFor="fuel_name_eng">Product List</label>
                                                </Col>
                                                        <Multiselect
                                                            options={productListMap} 
                                                            selectedValues={this.state.featuresMap.productsList} 
                                                            onSelect={this.onProductSelect} 
                                                            onRemove={this.onProductRemove} 
                                                            displayValue="product_name_en"
                                                        />
                                                <Col md="1">
                                                    <label htmlFor="home_features_name_en">Features List</label>
                                                </Col>
                                                        <Multiselect
                                                            options={homefeaturesListMap} 
                                                            selectedValues={this.state.featuresMap.featureList} 
                                                            onSelect={this.onFeatureSelect} 
                                                            onRemove={this.onFeatureRemove} 
                                                            displayValue="home_features_name_en"
                                                        />
                                                    <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.HomeFeatures;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { productList,homefeaturesList })(HomeFeaturesMap));
