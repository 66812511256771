
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, DropdownItemCard, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal } from 'reactstrap';
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { withRouter, Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
//i18n
import { withNamespaces } from 'react-i18next';
import { loginUser } from '../../../store/auth/login/actions';

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';
import Login from '../../../pages/Ecommerce/Login';
import { toast } from 'react-toastify';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            name: "Admin"
        };
        this.toggle = this.toggle.bind(this);
    }

    //modal
    tog_scroll = (banner) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.state.banner = banner;

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }
    handleValidSubmit(event, values) {
        this.props.loginUser(values);
        // this.fetchbannerList(); 
        this.form && this.form.reset();
        toast.success('Login Successfully', { position: toast.POSITION.TOP_CENTER });
        // window.location.reload();
    }
    componentDidMount() {

        if (localStorage.getItem("authUser")) {
            if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                this.setState({
                    name: obj.data.user.name
                });
            }
        }
    }

    render() {

        if (localStorage.getItem("authUser")) {
            return (
                <React.Fragment>
                    <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                        <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                            <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" />
                            <span className="d-none d-xl-inline-block ml-2 mr-1">{this.state.name}</span>
                            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem tag="a" href="/profile">
                                <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                                {this.props.t('Profile')}
                            </DropdownItem>
                            <DropdownItem tag="a" href="/orders">
                                <i className="bx bx-wallet font-size-16 align-middle mr-1"></i>
                                {this.props.t('My Orders')}
                            </DropdownItem>
                            {/* <DropdownItem tag="a" href="#">
                                <span className="badge badge-success float-right mt-1">5</span><i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                                {this.props.t('Settings')}
                            </DropdownItem>
                            <DropdownItem tag="a" href="auth-lock-screen">
                                <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{this.props.t('Lock screen')}
                            </DropdownItem> */}
                            <div className="dropdown-divider"></div>
                            <Link to="/logout" className="dropdown-item">
                                <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                                <span>{this.props.t('Logout')}</span>
                            </Link>
                        </DropdownMenu>
                    </Dropdown>
                </React.Fragment>
            );
        } else {
            // console.log("this.props.success", this.props.success)
            return (
                <React.Fragment>
                    <div className="d-flex">


                        {/* <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button> */}

                        {/* <form className="app-search d-none d-lg-block"> */}
                        <div className="position-relative" style={{ marginTop: '17px', fontSize: "18px" }}>
                            <span className="btn btn-sm btn-primary waves-effect waves-light"
                                onClick={() => { this.tog_scroll(1) }}
                                data-toggle="modal">Login/Register</span>
                        </div>
                        {/* </form> */}


                    </div>
                    <Modal
                        isOpen={this.state.modal_scroll}
                        toggle={this.tog_scroll}
                        scrollable={true}
                        centered
                    >
                        <div className="modal-header bg-primary">
                            <h5 className="modal-title mt-0 text-light">Welcome to AutoT! Please login.</h5>
                            <button
                                type="button"
                                onClick={() =>
                                    this.setState({ modal_scroll: false })
                                }
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Login/>
                        </div>
                    </Modal>
                </React.Fragment>
            );
        }

    }
}
const mapStatetoProps = state => {
    const { msg, error, success } = state.Login;
    return { msg, error, success };
}
export default withRouter(withNamespaces()(connect(mapStatetoProps, { loginUser })(ProfileMenu)));
