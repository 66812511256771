import React from 'react';
import { Input } from 'reactstrap';
export class Searchbox extends React.Component {
    render() {
        return (

            <div class="position-relative">
                <Input type="text" class="form-control" placeholder="Search your products...." onChange={this.props.handleInputChange} />
                {/* <div class ="input-group-append"> */}
                <i class ="bx bx-search-alt search-icon"></i>
                {/* </div> */}
            </div>

        );
    }

};


    {/* // <div className="position-relative">
            //     <Input type="text" className="form-control border-0" name="search" placeholder="Search..." onChange={this.props.handleInputChange} />
            //     <i className="bx bx-search-alt search-icon"></i>
            // </div> */}