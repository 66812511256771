import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_BANNER,BRAND_SUCCESS,EDIT_BANNER,SHOW_BANNER } from './actionTypes';

import { bannerSuccess, bannerError,bannerShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createBanner({ payload: { banner } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'banner/create',banner);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(bannerError(response.message.message));
            }else if(response.success == true){
                yield put(bannerSuccess(response.data.message));
            } 
          }
    } catch (error) {
        yield put(bannerError(error));
    }
}


function* editBanner({ payload: { banner } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'banner/'+banner.id, banner);
             yield put(bannerSuccess(response));
          }
          
          
    } catch (error) {
        yield put(bannerError(error));
    }
}


function* showBanner({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(bannerShow(response));
          }
          
          
    } catch (error) {
        yield put(bannerError(error));
    }
}

export function* watchBanner() {
    yield takeEvery(EDIT_BANNER, editBanner)
    yield takeEvery(CREATE_BANNER, createBanner)
    yield takeEvery(SHOW_BANNER, showBanner)
}

function* BannerSaga() {
    yield all([
        fork(watchBanner),
    ]);
}

export default BannerSaga;