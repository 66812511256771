import React, { Component } from 'react';
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table, Media, InputGroup, InputGroupAddon, Input } from "reactstrap";
import classnames from 'classnames';
//Import Star Ratings
import StarRatings from 'react-star-ratings';
import axios from 'axios';

//Import Product Images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class EcommerceTermsCondition extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comments: [
                { id: 1, img: avatar2, name: "Brian", description: "If several languages coalesce, the grammar of the resulting language.", date: "5 hrs ago" },
                {
                    id: 2, img: avatar4, name: "Denver", description: "To an English person, it will seem like simplified English, as a skeptical Cambridge", date: "07 Oct, 2019",
                    childComment: [
                        { id: 1, img: avatar5, name: "Henry", description: "Their separate existence is a myth. For science, music, sport, etc.", date: "08 Oct, 2019" },
                    ]
                },
                { id: 3, img: "Null", name: "Neal", description: "Everyone realizes why a new common language would be desirable.", date: "05 Oct, 2019" },
            ],
            productQty: 1,
            activeTab: '0',
            cartItem: [],
            disable: true
        }
        this.toggleTab = this.toggleTab.bind(this);
        this.imageShow = this.imageShow.bind(this);
        this.countUP.bind(this);
        this.countDown.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    imageShow(img, id) {
        var expandImg = document.getElementById("expandedImg" + id);
        expandImg.src = img;
    }



    componentDidMount() {

    }
    reloadPage() {

    }


    addToCart = (e, products) => {
        let quantity = this.state.productQty;
        let productsData = {
            f_action_status: 2,
            imei_no: 'web browser',
            productArray: [{
                product_id: products.product_id,
                product_map_id: 0,
                coupon_discount: 0,
                quantity: quantity
            }]
        }
        this.props.createCart(productsData)
    }

    countUP = () => {
        if (this.state.productQty < 10) {
            this.state.productQty += 1;
            this.setState({
                productQty: this.state.productQty,
                // disable: this.state.disable(false)
            });
        }
    }

    countDown = () => {
        if (this.state.productQty > 1) {
            this.state.productQty -= 1;
            this.setState({
                productQty: this.state.productQty
            });
        }
    }


    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h2 className="font-weight-bold mb-3">Terms and Conditions</h2>
                        <Row >
                            <Col>

                                <div className="mb-5">
                                    <h4 className="font-weight-bold"> 1.Introduction</h4>
                                </div>

                                <div>
                                    <p>
                                        Welcome to Autotbd.com also hereby known as “we", "us" or "Daraz". We are an online marketplace and these are the terms and conditions governing your access and use of Daraz along with its related sub-domains, sites, mobile app, services and tools (the "Site"). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site. This Site is owned and operated by <span className="font-weight-bold"> Autot Bangladesh Limited, a company incorporated under the Companies Act, 1994, (Registration Number: 117773/14).</span>
                                    </p>

                                    <p>
                                        The Site reserves the right to change, modify, add, or remove portions of these Terms and Conditions at any time without any prior notification. Changes will be effective when posted on the Site with no other notice provided. Please check these Terms and Conditions regularly for updates. Your continued use of the Site following the posting of changes to Terms and Conditions of use constitutes your acceptance of those changes.
                                    </p>
                                </div>

                                <div className="my-5">
                                    <h4 className="font-weight-bold">2. CONDITIONS OF USE </h4>
                                    <h4 className="font-weight-bold">A. YOUR ACCOUNT </h4>
                                </div>

                                <div>
                                    <p>
                                        To access certain services offered by the platform, we may require that you create an account with us or provide personal information to complete the creation of an account. We may at any time in our sole and absolute discretion, invalidate the username and/or password without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by, caused by, arising out of, in connection with or by reason of such request or invalidation.
                                    </p>

                                    <p>
                                        You are responsible for maintaining the confidentiality of your user identification, password, account details and related private information. You agree to accept this responsibility and ensure your account and its related details are maintained securely at all times and all necessary steps are taken to prevent misuse of your account. You should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized manner. You agree and acknowledge that any use of the Site and related services offered and/or any access to private information, data or communications using your account and password shall be deemed to be either performed by you or authorized by you as the case may be. You agree to be bound by any access of the Site and/or use of any services offered by the Site (whether such access or use are authorized by you or not). You agree that we shall be entitled (but not obliged) to act upon, rely on or hold you solely responsible and liable in respect thereof as if the same were carried out or transmitted by you. You further agree and acknowledge that you shall be bound by and agree to fully indemnify us against any and all losses arising from the use of or access to the Site through your account.
                                    </p>

                                    <p>
                                        Please ensure that the details you provide us with are correct and complete at all times. You are obligated to update details about your account in real time by accessing your account online. For pieces of information you are not able to update by accessing Your Account on the Site, you must inform us via our customer service communication channels to assist you with these changes. We reserve the right to refuse access to the Site, terminate accounts, remove or edit content at any time without prior notice to you. We may at any time in our sole and absolute discretion, request that you update your Personal Data or forthwith invalidate the account or related details without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by or caused by you or arising out of or in connection with or by reason of such request or invalidation. You hereby agree to change your password from time to time and to keep your account secure and also shall be responsible for the confidentiality of your account and liable for any disclosure or use (whether such use is authorised or not) of the username and/or password.
                                    </p>

                                </div>

                                <div className="my-5">
                                    <h4 className="font-weight-bold">B. PRIVACY </h4>
                                </div>
                                
                                <div>
                                Please review our Privacy Agreement, which also governs your visit to the Site. The personal information / data provided to us by you or your use of the Site will be treated as strictly confidential, in accordance with the Privacy Agreement and applicable laws and regulations. If you object to your information being transferred or used in the manner specified in the Privacy Agreement, please do not use the Site. 
                                </div>

                                <div className="my-5">
                                    <h4 className="font-weight-bold">C. PLATFORM FOR COMMUNICATION</h4>
                                </div>
                                
                                <div>
                                You agree, understand and acknowledge that the Site is an online platform that enables you to purchase products listed at the price indicated therein at any time from any location using a payment method of your choice. You further agree and acknowledge that we are only a facilitator and cannot be a party to or control in any manner any transactions on the Site or on a payment gateway as made available to you by an independent service provider. Accordingly, the contract of sale of products on the Site shall be a strictly bipartite contract between you and the sellers on our Site while the payment processing occurs between you, the service provider and in case of prepayments with electronic cards your issuer bank. Accordingly, the contract of payment on the Site shall be strictly a bipartite contract between you and the service provider as listed on our Site.
                                </div>

                                <div className="my-5">
                                    <h4 className="font-weight-bold">D. CONTINUED AVAILABILITY OF THE SITE</h4>
                                </div>
                                
                                <div>
                                You agree, understand and acknowledge that the Site is an online platform that enables you to purchase products listed at the price indicated therein at any time from any location using a payment method of your choice. You further agree and acknowledge that we are only a facilitator and cannot be a party to or control in any manner any transactions on the Site or on a payment gateway as made available to you by an independent service provider. Accordingly, the contract of sale of products on the Site shall be a strictly bipartite contract between you and the sellers on our Site while the payment processing occurs between you, the service provider and in case of prepayments with electronic cards your issuer bank. Accordingly, the contract of payment on the Site shall be strictly a bipartite contract between you and the service provider as listed on our Site.
                                </div>
                                <div className="my-5">
                                    <h4 className="font-weight-bold">E. LICENSE TO ACCESS THE SITE</h4>
                                </div>
                                
                                <div>
                                <p>We require that by accessing the Site, you confirm that you can form legally binding contracts and therefore you confirm that you are at least 18 years of age or are accessing the Site under the supervision of a parent or legal guardian. We grant you a non-transferable, revocable and non-exclusive license to use the Site, in accordance with the Terms and Conditions described herein, for the purposes of shopping for personal items and services as listed to be sold on the Site. Commercial use or use on behalf of any third party is prohibited, except as explicitly permitted by us in advance. If you are registering as a business entity, you represent that you have the authority to bind that entity to this User Agreement and that you and the business entity will comply with all applicable laws relating to online trading. No person or business entity may register as a member of the Site more than once. Any breach of these Terms and Conditions shall result in the immediate revocation of the license granted in this paragraph without notice to you.</p>

                                <p>
                                Content provided on this Site is solely for informational purposes. Product representations including price, available stock, features, add-ons and any other details as expressed on this Site are the responsibility of the vendors displaying them and is not guaranteed as completely accurate by us. Submissions or opinions expressed on this Site are those of the individual(s) posting such content and may not reflect our opinions.
                                </p>
                                <p>
                                We grant you a limited license to access and make personal use of this Site, but not to download (excluding page caches) or modify the Site or any portion of it in any manner. This license does not include any resale or commercial use of this Site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this Site or its contents; any downloading or copying of account information for the benefit of another seller; or any use of data mining, robots, or similar data gathering and extraction tools.
                                </p>
                                <p>
                                This Site or any portion of it (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent by us as may be applicable.
                                </p>
                                <p>
                                You agree and undertake not to perform restricted activities listed within this section; undertaking these activities will result in an immediate cancellation of your account, services, reviews, orders or any existing incomplete transaction with us and in severe cases may also result in legal action:
                                </p>

                                <ul>
                                    <li>
                                    Refusal to comply with the Terms and Conditions described herein or any other guidelines and policies related to the use of the Site as available on the Site at all times. 
                                    </li>
                                    <li>Impersonate any person or entity or to falsely state or otherwise misrepresent your affiliation with any person or entity.</li>
                                    <li>Use the Site for illegal purposes.</li>
                                    <li>Attempt to gain unauthorized access to or otherwise interfere or disrupt other computer systems or networks connected to the Platform or Services.</li>
                                    <li>Interfere with another’s utilization and enjoyment of the Site;</li>
                                    <li>Post, promote or transmit through the Site any prohibited materials as deemed illegal by The People's Republic of Bangladesh.</li>
                                    <li>
                                    Use or upload, in any way, any software or material that contains, or which you have reason to suspect that contains, viruses, damaging components, malicious code or harmful components which may impair or corrupt the Site’s data or damage or interfere with the operation of another Customer’s computer or mobile device or the Site and use the Site other than in conformance with the acceptable use policies of any connected computer networks, any applicable Internet standards and any other applicable laws.
                                    </li>
                                </ul>
                                </div>

                                <div className="my-5">
                                    <h4 className="font-weight-bold">F. YOUR CONDUCT</h4>
                                </div>
                                <div>
                                    <p>
                                    You must not use the website in any way that causes, or is likely to cause, the Site or access to it to be interrupted, damaged or impaired in any way. You must not engage in activities that could harm or potentially harm the Site, its employees, officers, representatives, stakeholders or any other party directly or indirectly associated with the Site or access to it to be interrupted, damaged or impaired in any way. You understand that you, and not us, are responsible for all electronic communications and content sent from your computer to us and you must use the Site for lawful purposes only. You are strictly prohibited from using the Site
                                    </p>
                                    <p>
                                    for fraudulent purposes, or in connection with a criminal offense or other unlawful activity
                                    to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libellous, obscene, pornographic, paedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of Bangladesh or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam”
                                    Use the Site for illegal purposes.
                                    to cause annoyance, inconvenience or needless anxiety
                                    for any other purposes that is other than what is intended by us
                                    </p>
                                </div>
                                <div className="my-5">
                                    <h4 className="font-weight-bold">G. YOUR SUBMISSION</h4>
                                </div>
                                <div>
                                    <p>
                                    Anything that you submit to the Site and/or provide to us, including but not limited to, questions, reviews, comments, and suggestions (collectively, "Submissions") will become our sole and exclusive property and shall not be returned to you. In addition to the rights applicable to any Submission, when you post comments or reviews to the Site, you also grant us the right to use the name that you submit, in connection with such review, comment, or other content. You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any Submissions. We may, but shall not be obligated to, remove or edit any Submissions without any notice or legal course applicable to us in this regard.
                                    </p>
                                </div>
                                <div className="my-5">
                                    <h4 className="font-weight-bold">H. CLAIMS AGAINST OBJECTIONABLE CONTENT</h4>
                                </div>
                                <div>
                                    <p>
                                    We list thousands of products for sale offered by numerous sellers on the Site and host multiple comments on listings, it is not possible for us to be aware of the contents of each product listed for sale, or each comment or review that is displayed. Accordingly, we operate on a "claim, review and takedown" basis. If you believe that any content on the Site is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libellous, obscene, pornographic, paedophilic or menacing; ethnically objectionable, disparaging; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of Bangladesh or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, (" objectionable content "), please notify us immediately by following by writing to us on legal@daraz.com.bd. We will make all practical endeavours to investigate and remove valid objectionable content complained about within a reasonable amount of time.
                                    </p>
                                    <p>
                                    Please ensure to provide your name, address, contact information and as many relevant details of the claim including name of objectionable content party, instances of objection, proof of objection amongst other. Please note that providing incomplete details will render your claim invalid and unusable for legal purposes. 
                                    </p>
                                </div>

                            </Col>
                        </Row>


                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EcommerceTermsCondition;
