import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, loginError } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postJwtLogin } from '../../../helpers/self_accounts_login_helper';
import { toast } from 'react-toastify';



function* loginUser({ payload: { user, history } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(postJwtLogin, 'user/login', { mobile: user.mobile, password: user.password });
            if (response.success == false) {
                // alert(response.message['message']);
                yield put(loginError(response));
                // console.log('message',response.message);
            } else if (response.success == true) {
                yield put(loginSuccess("Login Successfully !"));
            }
            //  localStorage.setItem("authUser", JSON.stringify(response));
            //  yield put(loginSuccess(response));
            //  console.log('response');
        }
        history.push('/home');

    } catch (error) {
        yield put(loginError('Mobile no. or Password is incorrect'));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("cartData");
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("menubar");
        localStorage.removeItem("permissions");

        history.push('/home');
    } catch (error) {
        yield put(loginError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;