import { PROFILE_ERROR,PROFILE_SUCCESS,EDIT_PROFILE,SHOW_PROFILE } from './actionTypes';
import {getApi} from "../../../services/httpService";

export const showProfile = (user_id) => {
    return {
        type: SHOW_PROFILE,
        payload: { user_id }
    }
}

export const editProfile = (user) => {
    return {
        type: EDIT_PROFILE,
        payload: { user }
    }
}

export const profileSuccess = (msg) => {
    return {
        type: PROFILE_SUCCESS,
        payload: msg
    }
}


export function profileShow(user_id){
    return getApi().get('user/'+user_id)
}

export const profileError = (error) => {
    return {
        type: PROFILE_ERROR,
        payload: error
    }
}


export function userProfile(){
    return getApi().get('user/profile')
}

