import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_BRAND,BRAND_SUCCESS,EDIT_BRAND,SHOW_BRAND } from './actionTypes';

import { brandSuccess, brandError,brandShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createBrand({ payload: { brand } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'brand/create',brand);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(brandError(response.message.message));
            }else if(response.success == true){
                yield put(brandSuccess(response.data.message));
            } 
          }
    } catch (error) {
        yield put(brandError(error));
    }
}


function* editBrand({ payload: { brand } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'brand/'+brand.brand_id, brand);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(brandError(response.message.message));
            }else if(response.success == true){
                yield put(brandSuccess(response.data.message));
            } 
          }
          
          
    } catch (error) {
        yield put(brandError(error));
    }
}


function* showBrand({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(brandShow(response));
          }
          
          
    } catch (error) {
        yield put(brandError(error));
    }
}

export function* watchBrand() {
    yield takeEvery(EDIT_BRAND, editBrand)
    yield takeEvery(CREATE_BRAND, createBrand)
    yield takeEvery(SHOW_BRAND, showBrand)
}

function* BrandSaga() {
    yield all([
        fork(watchBrand),
    ]);
}

export default BrandSaga;