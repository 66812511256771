import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Input, Button, Card, CardBody, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, ButtonGroup } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import logoAutoT from "../../assets/images/AutoT-Logo-Color.png";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';

import img4 from "../../assets/images/product/img-4.png";
import img7 from "../../assets/images/product/img-7.png";
import { orderCancel, orderList, orderShow } from '../../store/order/actions';
import { CustomPagination } from '../Settings/Common/CustomPagination';
import { toast } from 'react-toastify';
import { voucherShow, voucherUserList } from '../../store/actions';
import moment from 'moment';


class EcommerceOrders extends Component {
    constructor(props) {
        super(props);
        if (!localStorage.getItem('OrderList') || (localStorage.getItem('OrderList') != 'order' && localStorage.getItem('OrderList') != 'voucher')) {
            localStorage.setItem('OrderList', 'order');
        }
        let OrderList = localStorage.getItem('OrderList');
        let selected = localStorage.getItem('selectedMachine');

        if (selected == null) {
            localStorage.setItem('selectedMachine', 'm-1');
        }
        selected = localStorage.getItem('selectedMachine');

        var today = new Date(),

        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        this.state = {
            status: true,
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            modal_scroll: false,
            image_modal_scroll: false,
            Color: `warning`,
            modal: false,
            order: {

            },
            voucher: {

            },
            OrderList: OrderList,
            quantity: 1,
            currentDate: moment(new Date(date)).format("YYYY-MM-DD"),

            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }

        }
        this.togglemodal.bind(this);

    }


    componentDidMount() {
        this.fetchOrderList();
        // this.fetchOrderDetails();
        this.fetchVoucherList();
        // this.fetchVoucherDetails();
    }

    fetchOrderList = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search,
        }
        orderList(options).then((response) => {
            this.setState({
                orderList: response.data,
            });
            this.state.orderList = response.data;
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
            });

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }

    fetchVoucherList = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search,
        }
        voucherUserList(options).then((response) => {
            this.setState({
                voucherList: response.data,
            });
            this.state.voucherList = response.data;
            // console.log('this.state.voucherList', this.state.voucherList);
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
            });

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })

    }

    fetchOrderDetails = (order_id) => {

        orderShow(order_id).then((response) => {
            this.setState({
                orderDetails: response.data.orderDetails,
            });
            this.state.orderDetails = response.data.orderDetails;
            // console.log('orderDetails',this.state.orderDetails);
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchVoucherDetails = (voucher_id) => {

        voucherShow(voucher_id).then((response) => {
            this.setState({
                voucherDetails: response.data.VoucherGenerateList,
            });
            this.state.voucherDetails = response.data.VoucherGenerateList;
            // console.log('voucherDetails',this.state.voucherDetails);
        })
            .catch((err) => {
                console.log(err);
            })
    }


    togglemodal = (order) => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
        this.state.order = order;
        this.fetchOrderDetails(order.order_id);
        // console.log('order', order.order_id);
    }

    togglemodal2 = (voucher) => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
        this.state.voucher = voucher;
        this.fetchVoucherDetails(voucher.id);
        // console.log('voucher', voucher.id);
    }

    orderHandlePageClick= (e, index) => {
        e.preventDefault()
        this.setState({
            page: index,

        }, () => {
            this.fetchOrderList();
        });
    }

    voucherHandlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index,

        }, () => {
            this.fetchVoucherList();
        });
    }

    orderHandleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchOrderList();
        });
    }

    voucherHandleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchVoucherList();
        });
    }


    OrderCancel = (order_id, values) => {

        let r = window.confirm("Are you sure you wish to cancel this Order?");
        if (r == true) {
            // console.log(order_id);
            let data = {
                f_action_status: values
            }
            orderCancel(order_id, data).then(response => {
                // console.log('response', response)
                if (response.success == true) {
                    toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
                    setTimeout(
                        this.fetchOrderList(), 2000,
                    );
                } else {
                    toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
                }
            })
        }
    }

    selectPanel = (e, OrderList) => {
        e.preventDefault();
        this.setState({
            OrderList: OrderList
        })


        if (OrderList == 'order') {
            // this.state.stockOut.f_payment_type = 2;
            // this.state.stockOut.is_due = 0;
            localStorage.setItem('OrderList', OrderList);
            // this.reset(e);
            //.log("cash-value ",this.state.stockOut.is_due);

        }
        else {
            // this.state.stockOut.f_payment_type = 3;
            // this.state.stockOut.is_due = 1;
            localStorage.setItem('OrderList', OrderList);
            // this.reset(e);
            //.log("due-value ",this.state.stockOut.is_due);
        }
    }

    printDiv = () => {
        var content = document.getElementById('modal-content').innerHTML;
        var title = document.title;
        var printWindow = window.open("", "_blank", "width=800,height=600");
        printWindow.document.open();
        printWindow.document.write('<html><head><title>' + title + '</title><style>@media print { @page {size: A4 portrait;}  footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width:297mm  !important; height:210mm !important; font-size:25px; } .table{ margin-top:100px !important;  } thead{text-align:left !important; font-size:25px;} tbody{font-size:25px;} .table-bordered th, .table-bordered td {border: 1px solid #181b1e !important; }.table th, .table td {border-top: 1px solid #EFF2F7 !important; } h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {margin-bottom: 0 !important; font-weight: 800 !important;  line-height: 1 !important;} .table th,.table td { width:297mm;  padding: 0.25rem !important; } img{width:150px !important; height:150px !important;} tfoot{text-align: left;} .info{font-weight:bold; display:flex; justify-content:flex-start !important} .p_code{color:#24ADF3 !important} .autoTLogo{ width:180px!important; height:60px !important; margin-top:100px;!important} .line-break{color: #181b1e !important;} .p_name{text-align:left !important;} .p_total{text-align:right !important; font-size:45px !important} .product_details{margin:0px 60px} .orderImg{text-align:left!important;} .userInfo{margin-left:350px !important} .tfoot_info{font-size:35px!important;}.p_main_name{font-size:25px !important} </style></head><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        setTimeout(function () {
            printWindow.print();
            printWindow.close();
        }, 500);
    }

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let orderLists = [];
        let orderDetail;
        let orderPaginator;
        const getStyle = (OrderList) => {
            if (OrderList == this.state.OrderList) {
                return { background: '#239CD0', color: 'white' };
            } else {
                return {};
            }
        }

        if (this.state.hasOwnProperty('orderList')) {

            orderLists = this.state.orderList.data.data;
            // console.log('orderlist', orderLists);
            orderPaginator = {
                'currentPage': this.state.orderList.data.current_page,
                'pagesCount': this.state.orderList.data.last_page,
                'total': this.state.orderList.data.total,
                'to': this.state.orderList.data.to,
                'from': this.state.orderList.data.from,
            }
        }
        let voucherList = [];
        let voucherDate = [];
        let voucherPaginator;
        if (this.state.hasOwnProperty('voucherList')) {

            voucherList = this.state.voucherList.VoucherGenerateList.data;

            // console.log('voucherList', this.state.voucherList.VoucherGenerateList.total);
            voucherPaginator = {
                'currentPage': this.state.voucherList.VoucherGenerateList.current_page,
                'pagesCount': this.state.voucherList.VoucherGenerateList.last_page,
                'total': this.state.voucherList.VoucherGenerateList.total,
                'to': this.state.voucherList.VoucherGenerateList.to,
                'from': this.state.voucherList.VoucherGenerateList.from,
            }
        }

        let voucherDetail = [];
        if (this.state.hasOwnProperty('voucherDetails')) {
            voucherDetail = this.state.voucherDetails;
            // console.log('voucherlist2222', voucherDetail);
            // voucherDate=voucherDetail.insert_date_time;
            // console.log('voucherDate', voucherDate)
        }

        if (this.state.hasOwnProperty('orderDetails')) {
            orderDetail = this.state.orderDetails;
            // console.log('orderlist', orderDetail);
        }



        const { name, order_details, address, amount, total_amount, email, final_amount, order_code, order_id, order_date, order_type, phone, remarks, f_user_id, f_order_id, f_status_id, total_delivery_charge } = this.state.order;


        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" />
                        <Row>
                            <Col xs="12" sm="12" md={{ size: 12 }} className='d-flex justify-content-center mb-4' >
                                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                                    <Button className="btn-selection-secondary" onClick={(e) => this.selectPanel(e, 'order')} style={getStyle('order')}> ORDERS</Button>
                                    <Button className="btn-selection-secondary" onClick={(e) => this.selectPanel(e, 'voucher')} style={getStyle('voucher')}  > VOUCHERS </Button>
                                </ButtonGroup>
                            </Col>

                        </Row>
                        {this.state.OrderList == "order" &&
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            {/* <Row className="mb-2">
                                            <Col sm="4">
                                                <div className="search-box mr-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <Input type="text" className="form-control" placeholder="Search..." />
                                                        <i className="bx bx-search-alt search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="8">
                                                <div className="text-sm-right">
                                                    <Button type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Add New Order</Button>
                                                </div>
                                            </Col>
                                        </Row> */}

                                            <div className="table-responsive table-responsive-xs">
                                                <DataTabeSearch handleInputChange={this.orderHandleInputChange} />

                                                <Table className="table table-centered table-nowrap">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>
                                                                S/N
                                                            </th>
                                                            <th>Order Code</th>
                                                            <th>Billing Name</th>
                                                            <th>Order Date</th>
                                                            <th>Total</th>
                                                            <th>Order Status</th>
                                                            <th>Payment Method</th>
                                                            {/* <th>View Details</th> */}
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderLists &&
                                                            orderLists.map((order, key) =>
                                                                <tr key={"_order_" + key}>
                                                                    <td>
                                                                        {(this.state.per_page * (this.state.page - 1)) + key + 1}
                                                                    </td>
                                                                    <td><Link to="#" className="text-body font-weight-bold">#{order.order_code}</Link></td>
                                                                    <td>{order.name}</td>
                                                                    <td>
                                                                        {order.order_date}
                                                                    </td>
                                                                    <td>
                                                                        {/* ৳{order.total_amount+order.total_delivery_charge} */}
                                                                        ৳{order.final_amount}
                                                                    </td>
                                                                    <td>
                                                                        <Badge className="font-size-12" style={{ background: order.status == 'Cancel' || order.status == 'Returned' || order.status == "Payment Failed" || order.status == "Failed" || order.status == "Order cancel"||order.status == "Payment Canceled" ? 'red' : order.status == 'Pending' ? 'orange' : 'green' }} pill>{order.status}</Badge>
                                                                    </td>
                                                                    <td>
                                                                        <i className={"fab " + order.methodIcon + " mr-1"}></i> {order.order_type}
                                                                    </td>
                                                                    <td>
                                                                        <Button type="button" color="primary" className="btn-sm btn-rounded " onClick={() => this.togglemodal(order)}>
                                                                            View Details
                                                                        </Button>
                                                                        <Link to={"/order-track/" + order.order_id}>
                                                                            <Button type="button" color="success" className="btn-sm btn-rounded mx-2">
                                                                                Order Tracking
                                                                            </Button>
                                                                        </Link>
                                                                        {/* <Link to="/orders"> */}
                                                                        <Button type="button" color={order.status == 'Pending' || order.status == 'Cancelled' ? 'danger' : 'secondary'} className="btn-sm btn-rounded mx-2" onClick={() => this.OrderCancel(order.order_id, 9)} disabled={order.status == 'Pending' || order.status == 'Cancelled' ? '' : 'disabled'}>
                                                                            Cancel Order
                                                                        </Button>
                                                                        {/* </Link> */}
                                                                    </td>
                                                                    {/* <td>
                                                                    <Link to="#" className="mr-3 text-primary">
                                                                        <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                                                            Edit
                                                                        </UncontrolledTooltip >
                                                                    </Link>
                                                                    <Link to="#" className="text-danger">
                                                                        <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                            Delete
                                                                        </UncontrolledTooltip >
                                                                    </Link>
                                                                </td> */}
                                                                </tr>
                                                            )
                                                        }

                                                    </tbody>
                                                </Table>
                                            </div>
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                {orderPaginator &&
                                                    <PaginationItem>
                                                        <CustomPagination paginator={orderPaginator} handlePageClick={this.orderHandlePageClick} />
                                                    </PaginationItem>
                                                }
                                            </Pagination>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {this.state.OrderList == "voucher" &&
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive table-responsive-xs">
                                                <DataTabeSearch handleInputChange={this.voucherHandleInputChange} />

                                                <Table className="table table-centered table-nowrap">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>
                                                                S/N
                                                            </th>
                                                            <th>Order Code</th>
                                                            <th>Billing Name</th>
                                                            <th>Purchase Date</th>
                                                            <th>Total</th>
                                                            <th>Order Status</th>
                                                            <th>Payment Method</th>
                                                            {/* <th>View Details</th> */}
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {voucherList &&
                                                            voucherList.map((voucher, key) =>
                                                                <tr key={"_order_" + key}>
                                                                    <td>
                                                                        {(this.state.per_page * (this.state.page - 1)) + key + 1}
                                                                    </td>
                                                                    <td><Link to="#" className="text-body font-weight-bold">#{voucher.code}</Link></td>
                                                                    <td>{voucher.voucher_name}</td>
                                                                    <td>
                                                                        {moment(new Date(voucher.insert_date_time)).format("YYYY-MM-DD")}
                                                                    </td>
                                                                    <td>
                                                                        {/* ৳{order.total_amount+order.total_delivery_charge} */}
                                                                        ৳{voucher.final_amount}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            moment(new Date(voucher.voucher_end_date)).format("YYYY-MM-DD")<this.state.currentDate?<Badge className="font-size-12" color='danger' pill>Voucher Date Over</Badge> :
                                                                            voucher.is_closed == "1" ? <Badge className="font-size-12" color='secondary' pill>Redeem</Badge> :
                                                                                <Badge className="font-size-12" style={{ background: voucher.status == 'Cancel' || voucher.status == 'Returned' || voucher.status == "Payment Failed" || voucher.status == "Failed" || voucher.status == "Order cancel" || voucher.status == "Payment Canceled" ? 'red' : voucher.status == 'Pending' ? 'orange' : 'green' }} pill>{voucher.status}</Badge>

                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        <i className={"fab " + voucher.methodIcon + " mr-1"}></i> {voucher.order_type}
                                                                    </td>
                                                                    <td>
                                                                        <Button type="button" color="primary" className="btn-sm btn-rounded " onClick={() => this.togglemodal2(voucher)}>
                                                                            View Details
                                                                        </Button>

                                                                    </td>

                                                                </tr>
                                                            )
                                                        }

                                                    </tbody>
                                                </Table>
                                            </div>
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                {voucherPaginator &&
                                                    <PaginationItem>
                                                        <CustomPagination paginator={voucherPaginator} handlePageClick={this.voucherHandlePageClick} />
                                                    </PaginationItem>
                                                }
                                            </Pagination>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
                {this.state.OrderList == "order" &&
                    <Modal isOpen={this.state.modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={this.togglemodal}>
                        <div className="modal-content" >
                            <ModalHeader toggle={this.togglemodal}>
                                <h2>Order Details</h2>
                            </ModalHeader >
                            <ModalBody>
                                <div id="modal-content" className="orderInfo">
                                    <div>
                                        <img src={logoAutoT} className="w-25 autoTLogo" alt="logo" />
                                    </div>
                                    <hr className="line-break" />

                                    <div className="row info">
                                        <div className="col-md-6 useInfo">
                                            <p className="mb-2 font-weight-bold">Name: <span className="text-primary p_code">{name}</span></p>
                                            <p className="mb-2 font-weight-bold">Mobile: <span className="text-primary p_code">{phone}</span></p>
                                            <p className="mb-2 font-weight-bold">Address: <span className="text-primary p_code">{address}</span></p>

                                        </div>
                                        <div className="col-md-6 userInfo">
                                            <p className="mb-2 font-weight-bold">Order Code: <span className="text-primary p_code">#{order_code}</span></p>
                                            <p className="mb-2 font-weight-bold">Order Date: <span className="text-primary p_code">{order_date}</span></p>
                                            <p className="mb-2 font-weight-bold">Payment Type: <span className="text-primary p_code">{order_type}</span></p>
                                        </div>
                                    </div>


                                    <div className="table-responsive ">
                                        <Table className="table table-centered table-nowrap">
                                            <thead>
                                                <tr>
                                                    <th scope="col" >S/N</th>
                                                    <th scope="col" className="p_name">Product</th>
                                                    <th scope="col">Product Name</th>
                                                    <th scope="col">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order_details != undefined && order_details != null &&
                                                    order_details.map((orders, key) =>

                                                        <tr className="product_details">
                                                            <td className="text-center">
                                                                {key + 1}
                                                            </td>
                                                            <th scope="row" className="orderImg">
                                                                <div >
                                                                    <img src={imageLink + 'upload/Products/' + orders.product.product_id + '/featureImage/' + orders.product.feature_image[0].feature_image} alt="" className="avatar-sm " />
                                                                </div>
                                                            </th>
                                                            <td>
                                                                <div>
                                                                    <h5 className="text-truncate font-size-14 p_main_name">{orders.product.product_name_en}</h5>
                                                                    <p className="text-muted mb-0 tfoot_info">৳ {orders.unit_price} x {orders.quantity}</p>
                                                                </div>
                                                            </td>
                                                            <td className="tfoot_info">৳ {orders.total_amount}</td>
                                                        </tr>


                                                    )
                                                }

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="3" className="p_total">
                                                        <h6 className="m-0 text-right">Sub Total:</h6>
                                                    </td>
                                                    <td className="tfoot_info">
                                                        ৳ {total_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" className="p_total">
                                                        <h6 className="m-0 text-right">Delivery Charge:</h6>
                                                    </td>
                                                    <td className="tfoot_info">
                                                        ৳{total_delivery_charge}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" className="p_total">
                                                        <h6 className="m-0 text-right">Total:</h6>
                                                    </td>
                                                    <td className="tfoot_info">
                                                        ৳ {final_amount}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="btn btn-success "
                                    onClick={() => this.printDiv()}>
                                    Print
                                </Button>
                                <Button type="button" color="secondary" onClick={this.togglemodal}>Close</Button>
                            </ModalFooter>
                        </div>
                    </Modal>
                }

                {this.state.OrderList == "voucher" &&

                    <Modal isOpen={this.state.modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={this.togglemodal}>
                        <div className="modal-content" >
                            <ModalHeader toggle={this.togglemodal}>
                                <h2>Voucher Details</h2>
                            </ModalHeader >
                            <ModalBody>
                                <div id="modal-content" className="orderInfo">
                                    <div>
                                        <img src={logoAutoT} className="w-25 autoTLogo" alt="logo" />
                                    </div>
                                    <hr className="line-break" />

                                    <div className="row info">
                                        <div className="col-md-6 useInfo">
                                            <p className="mb-2 font-weight-bold">Name: <span className="text-primary p_code">{voucherDetail.user_name}</span></p>
                                            <p className="mb-2 font-weight-bold">Mobile: <span className="text-primary p_code">{voucherDetail.user_phone}</span></p>
                                            <p className="mb-2 font-weight-bold">Address: <span className="text-primary p_code">{voucherDetail.user_address}</span></p>

                                        </div>
                                        <div className="col-md-6 userInfo">
                                            <p className="mb-2 font-weight-bold">Voucher Code: <span className="text-primary p_code">#{voucherDetail.code}</span></p>
                                            <p className="mb-2 font-weight-bold">Purchase Date: <span className="text-primary p_code">{moment(new Date(voucherDetail.insert_date_time)).format("YYYY-MM-DD")}</span></p>
                                            <p className="mb-2 font-weight-bold">Payment Type: <span className="text-primary p_code">{voucherDetail.order_type}</span></p>
                                        </div>
                                    </div>


                                    <div className="table-responsive ">
                                        <Table className="table table-centered table-nowrap">
                                            <thead>
                                                <tr>
                                                    <th scope="col" >S/N</th>
                                                    <th scope="col" className="p_name">Voucher</th>
                                                    <th scope="col">Voucher Name</th>
                                                    <th scope="col">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {order_details != undefined && order_details != null &&
                                                order_details.map((orders, key) => */}

                                                <tr className="product_details">
                                                    <td className="text-center">
                                                        1
                                                    </td>
                                                    <th scope="row" className="orderImg">
                                                        <div >
                                                            <img src={imageLink + '/voucher/' + voucherDetail.f_voucher_id + '/' + voucherDetail.voucher_image} alt="" className="avatar-sm " />
                                                        </div>
                                                    </th>
                                                    <td>
                                                        <div>
                                                            <h5 className="text-truncate font-size-14 p_main_name">{voucherDetail.voucher_name}</h5>
                                                            <p className="text-muted mb-0 tfoot_info">৳ {voucherDetail.voucher_buy_price} x {this.state.quantity}</p>
                                                        </div>
                                                    </td>
                                                    <td className="tfoot_info">৳ {voucherDetail.amount}</td>
                                                </tr>


                                                {/* )
                                            } */}

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="3" className="p_total">
                                                        <h6 className="m-0 text-right">Sub Total:</h6>
                                                    </td>
                                                    <td className="tfoot_info">
                                                        ৳ {voucherDetail.amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" className="p_total">
                                                        <h6 className="m-0 text-right">Total:</h6>
                                                    </td>
                                                    <td className="tfoot_info">
                                                        ৳ {voucherDetail.final_amount}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="btn btn-success "
                                    onClick={() => this.printDiv()}>
                                    Print
                                </Button>
                                <Button type="button" color="secondary" onClick={this.togglemodal}>Close</Button>
                            </ModalFooter>
                        </div>
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

export default EcommerceOrders;