import { CHECKOUT_ERROR,CHECKOUT_SUCCESS} from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const Checkout = (state = initialState, action) => {
    switch (action.type) {
        case CHECKOUT_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case CHECKOUT_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Checkout;