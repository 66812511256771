import { getApi } from "../../services/httpService";
import { VOUCHER_BUY_NOW, VOUCHER_BUY_SUCCESS, VOUCHER_BUY_ERROR } from './actionTypes';

export const createBuy = (voucher) => {
    // console.log('prodasdasduct', product);

    return {
        type: VOUCHER_BUY_NOW,
        payload: { voucher }

    }
}

export const voucherBuySuccess = (response) => {
    return {
        type: VOUCHER_BUY_SUCCESS,
        payload: response
    }
}

export const voucherBuyError = (error) => {
    return {
        type: VOUCHER_BUY_ERROR,
        payload: error
    }
}

export function voucherList(params){
    return getApi().get('voucher/public/list',{
        params: params
    })
}

export function voucherDetails(voucher_slug,params){
    return getApi().get('voucher/detials/'+voucher_slug,{
        params: params
    })
}

export function voucherBuyNow(data){
    // console.log('buyData',data);
    return getApi().post('vouchar/buy/now',data)
}

export function voucherUserList(params){
    // console.log('buyData',data);
    return getApi().get('vouchar/generates/list/user',{
        params: params
    })
}

export function voucherShow(id){
    return getApi().get('vouchar/generates/show/'+id)
}





