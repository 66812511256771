import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS, LOGIN_ERROR } from './actionTypes';

export const loginUser = (user, history) => {

    return {
        type: LOGIN_USER,
        payload: { user, history }
    }
}

export const loginSuccess = (response) => {
    
    return {
        type: LOGIN_SUCCESS,
        payload: response
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

// export const apiError = (error) => {
//     return {
//         type: API_ERROR,
//         payload: error
//     }
// }

export const loginError = (response) => {
    // console.log('error', response);
    return {
        type: LOGIN_ERROR,
        payload: response
    }
}


