import { getApi } from '../../../services/httpService';
import { GET_OTP, REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED } from './actionTypes';

export const registerUser = (user) => {
    return {
        type: REGISTER_USER,
        payload: { user }
    }
}

export const registerUserSuccessful = (response) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: response
    }
}

export const registerUserFailed = (response) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: response
    }
}

export const GetOTP = (data) =>{
    console.log('data',data);
    return {
        type:GET_OTP,
        payload:{data}
    }
}

export function getOTP(data){
    return getApi().post('user/vehicleOwnerGetOTP',data)
}

export function OTPValidation(data){
    return getApi().post('user/vehicleOwnerGetOTPValidation',data)
}

export function registration(data){
    return getApi().post('user/vehicleOwnerRegister',data)
}