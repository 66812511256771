import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_HOME_FEATURES,EDIT_HOME_FEATURES,SHOW_HOME_FEATURES } from './actionTypes';

import { homeFeaturesSuccess, homeFeaturesError,homefeaturesShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createHomeFeatures({ payload: { features } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'features/create',features);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(homeFeaturesError(response.message.message));
            }else if(response.success == true){
                yield put(homeFeaturesSuccess(response.data.message));
            } 
          }
    } catch (error) {
        yield put(homeFeaturesError(error));
    }
}


function* editHomeFeatures({ payload: { features } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'features/'+features.home_features_id,features);
             console.log('ji','features/'+features.home_features_id,features)
             if(response.success == false){
                // alert(response.message['message']);
                yield put(homeFeaturesError(response.message.message));
            }else if(response.success == true){
                yield put(homeFeaturesSuccess(response.data.message));
            } 
          }
          
          
    } catch (error) {
        yield put(homeFeaturesError(error));
    }
}


function* showHomeFeatures({ payload: { home_features_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'features/'+home_features_id);
             yield put(homefeaturesShow(response));
          }
          
          
    } catch (error) {
        yield put(homeFeaturesError(error));
    }
}

export function* watchHomeFeatures() {
    yield takeEvery(EDIT_HOME_FEATURES, editHomeFeatures)
    yield takeEvery(CREATE_HOME_FEATURES, createHomeFeatures)
    yield takeEvery(SHOW_HOME_FEATURES, showHomeFeatures)
}

function* HomeFeaturesSaga() {
    yield all([
        fork(watchHomeFeatures),
    ]);
}

export default HomeFeaturesSaga;