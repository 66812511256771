import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardImg, CardTitle, Form, Label, Input, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import classnames from 'classnames';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
//Import Star Ratings
import StarRatings from 'react-star-ratings';
// import p_thumbnail from '../../assets/images/thumbnail/p_thumbnail.jpg';

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import Slider from "react-slick";

//Import Product Images
import product1 from "../../assets/images/product/img-1.png";
import product2 from "../../assets/images/product/img-2.png";
import product3 from "../../assets/images/product/img-3.png";
import product4 from "../../assets/images/product/img-4.png";
import product5 from "../../assets/images/product/img-5.png";
import product6 from "../../assets/images/product/img-6.png";
import { placeholder } from "../../assets/images/thumbnail/placeholder.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { createBanner, sliderList, editBanner, sliderUpload } from '../../store/banner/actions';
import { featuresProducts, products } from '../../store/ecommerceProducts/actions';
import { Searchbox } from './Searchbox';
import { productDetails, cartDetails } from '../../store/ecommerceProducts/actions';
import { brandList } from '../../store/actions';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "blue", borderRadius: '50%' }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "blue", borderRadius: '50%' }}
            onClick={onClick}
        />
    );
}

function SampleNextArrow2(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none", background: "blue" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow2(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none", background: "blue" }}
            onClick={onClick}
        />
    );
}
class EcommerceProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true,
            page: 1,
            per_page: 24,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            image_modal_scroll: false,
            uploadImage: {},
            product: {

            },
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }

        };
        this.toggleTab = this.toggleTab.bind(this);
        // this.handleChange = this.handleChange.bind(this);

    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    componentDidMount() {
        this.fetchbannerList();
        this.fetchProducts();
        this.fetchFeaturesProducts();
        this.fetchBrandDetails();
        // let cartCount = JSON.parse(localStorage.getItem('cartData'));
        // let cartID = cartCount == null || cartCount == undefined && cartCount.cart_id == undefined ? 0 : cartCount.cart_id;
        // this.fetchCartDetails(cartID)
    }

    fetchbannerList = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        sliderList().then((response) => {
            this.setState({
                sliderList: response.data,

            });
            this.state.sliderList = response.data;
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
            });
        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }
                });
            })
    }

    fetchProducts = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        products(options).then((response) => {
            // console.log('products here', response);
            this.setState({
                products: response.data,
            });
            this.state.products = response.data;
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
            });
        })
            .catch(err => {
                console.log(err)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index,

        }, () => {
            this.fetchProducts();
        });
    }

    fetchFeaturesProducts = () => {
        featuresProducts().then((response) => {
            this.setState({
                featuresProducts: response.data,
            });
            this.state.featuresProducts = response.data;
        })
            .catch(err => {
                console.log(err)
            })
    }

    fetchCartDetails = (cartID) => {

        cartDetails(cartID)
            .then((response) => {
                // console.log('cart Details', response);
                this.setState({
                    cartDetails: response.data.cart.cart_product_details,
                });
                this.state.cartDetails = response.data.cart.cart_product_details;
                localStorage.setItem('cartData', JSON.stringify(response.data.cart));
                // console.log('get cartDetails', this.state.cartDetails);
            })
            .catch(err => {
                console.log(err)
            })
    }

    fetchBrandDetails = () => {
        brandList().then((response) => {
            this.setState({
                brandList: response.data
            })
            this.state.brandList = response.data
            // console.log('brandlist', this.state.brandList)
        })
            .catch(err => {
                console.log(err);
            })
    }
    // handleInputChange = (e) => {
    //     this.setState({ [e.target.name]: e.target.value }, () => {
    //         this.fetchProducts();
    //     });
    //     console.log({ [e.target.name]: e.target.value });
    // }


    render() {
        let bannerListMap = [];
        // let paginator;
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 5000,
            autoplaySpeed: 5000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        const brandSetting = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 5000,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            nextArrow: <SampleNextArrow2 />,
            prevArrow: <SamplePrevArrow2 />
        };
        if (this.state.hasOwnProperty('sliderList')) {
            bannerListMap = this.state.sliderList;

        }
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let allProduct = [];

        let paginator;
        if (this.state.hasOwnProperty('products')) {
            allProduct = this.state.products.data;

            paginator = {
                'currentPage': this.state.products.current_page,
                'pagesCount': this.state.products.last_page,
                'total': this.state.products.total,
                'to': this.state.products.to,
                'from': this.state.products.from,
            }
        }

        let featuresProducts = [];
        if (this.state.hasOwnProperty('featuresProducts')) {
            featuresProducts = this.state.featuresProducts;
            // console.log('featuresProducts', featuresProducts);
        }
        let brandList = [];
        if (this.state.hasOwnProperty('brandList')) {
            brandList = this.state.brandList.data;
        }
        // console.log('brandList', brandList)


        // const filterProduct  = allProduct.filter(product=>(product.product_name_en.includes(this.state.search.toLowerCase())));
        // console.log(filterProduct);
        // const { product_id, product_code, product_name_bn, product_name_en, product_bar_code, f_category_info_id, f_brand_id, f_unit_id, product_stock_balance, product_buy_price, product_sale_price, product_market_price, discount, product_description, product_weight, product_sku_code, product_specification, minimum_oder_quantity, product_meta_tag, f_discount_type, status } = this.state.product;
        let featureImage = [];
        featureImage = (featureImage == undefined ? [] : featureImage);
        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="page-content">
                    <Container fluid>
                        {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Product" /> */}
                        <Row>
                            <Col lg="12 mb-4">
                                <Slider {...settings}>
                                    {bannerListMap.map((banner, index) =>
                                        <a href={banner.link} target="_blank" alt="Banner_URL">
                                            <div>
                                                <CardImg src={imageLink + 'banner/' + banner.banner_image}></CardImg>
                                            </div>
                                        </a>
                                    )}
                                </Slider>
                            </Col>
                            <hr />
                            <Col lg="12">

                                {/* <Row className='mb-3 mt-5'> */}

                                {
                                    featuresProducts.map((features) =>
                                        <div>
                                            <Col xl="12">
                                                <div className="features-border-bottom d-flex justify-content-between">
                                                    <h1 className="home-header"><span className="text-primary font-weight-bold">{features.home_features_name_en}</span></h1>
                                                    {
                                                        features.home_features_name_en == "Smartphone" ?
                                                            <Link to="/category/Mobile"> <button className="btn btn-outline-info btn-sm mb-3"> See More <i className="ml-1 fas fa-arrow-right"></i></button></Link> :
                                                            <Link to="/category/Fuel"> <button className="btn btn-outline-info btn-sm mb-3"> See More <i className="ml-1 fas fa-arrow-right"></i></button></Link>
                                                    }

                                                </div>
                                            </Col>

                                            <Row>
                                                {
                                                    features.connected_home_features.map((product, key) =>


                                                        
                                                        <Col style={{ display: +product.product.status == '0' ? 'none' : '' }} xl="2" lg="3" md="4" sm="4" key={"_col_" + key} className="my-3">
                                                            <Link to={"/product-details/" + product.product.product_slug}>
                                                                <Card>
                                                                    <CardBody>
                                                                        {product.product.product_stock_balance > 0 ?
                                                                            " " : <div class="note no-stock">Out of stock</div>
                                                                        }
                                                                        {product.product.is_delivery_charge_free == 1 ?
                                                                            <div className="free delivery">
                                                                                Free Delivery
                                                                            </div>
                                                                            : ""
                                                                        }
                                                                        <div className="product-img position-relative">
                                                                            {
                                                                                !product.product.discount == 0 || !product.product.discount == null || !product.product.discount == undefined ?
                                                                                    <div className="avatar-sm product-ribbon">
                                                                                        <span className="avatar-title rounded-circle  bg-primary">
                                                                                            {product.product.discount + "%"}
                                                                                        </span>
                                                                                    </div>
                                                                                    : ""
                                                                            }

                                                                            <div className="form-group">
                                                                                {product.product.feature_image[0].feature_image == null || product.product.feature_image[0].feature_image == undefined ? "" :
                                                                                    <Link to={"/product-details/" + product.product.product_slug}>
                                                                                        <img src={imageLink + 'upload/Products/' + product.product.product_id + '/featureImage/' + product.product.feature_image[0].feature_image} alt="" height="200" className="img-fluid mx-auto d-block" />
                                                                                    </Link>
                                                                                }
                                                                            </div>

                                                                            {/* <img src={imageLink+product.feature_image[0].feature_image} alt="" className="img-fluid mx-auto d-block" /> */}
                                                                        </div>
                                                                        <div className="mt-4 text-center">
                                                                            <h5 className="mb-3 text-truncate text-primary "><Link to={"/product-details/" + product.product.product_slug}>{product.product.product_name_en}</Link>
                                                                            </h5>
                                                                            <div className="text-muted mb-3">
                                                                                <StarRatings
                                                                                    rating={product.rating}
                                                                                    starRatedColor="#F1B44C"
                                                                                    starEmptyColor="#2D363F"
                                                                                    numberOfStars={5}
                                                                                    name='rating'
                                                                                    starDimension="14px"
                                                                                    starSpacing="3px"
                                                                                />
                                                                            </div>
                                                                            {!product.product.product_market_price == null || !product.product.product_market_price == 0 ? (product.product.product_market_price != product.product.product_sale_price) ?
                                                                                <h5 className="my-0"><span className="text-muted mr-2">
                                                                                    <del className="text-danger font-weight-bold">৳{product.product.product_market_price}</del></span> <b>৳{product.product.product_sale_price}</b>
                                                                                </h5> : <h5 className="my-0"><b>৳{product.product.product_sale_price}</b>
                                                                                </h5> : ''
                                                                            }
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Link>
                                                        </Col>

                                                    )

                                                }
                                            </Row>
                                        </div>


                                    )
                                }



                                {/* </Row> */}
                                {/* <Row> */}
                                <Row className="mb-3">
                                    <Col xl="12">
                                        <div className="features-border-bottom d-flex justify-content-between">
                                            <h1 className="home-header"><span className="text-primary font-weight-bold">Brand</span></h1>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <Slider {...brandSetting}>

                                            {brandList.map((brand, key) =>
                                                <Card className="my-2 mx-2">
                                                    <CardBody>
                                                        <div className="product-img position-relative ">
                                                            {/* {brand.brand_logo == null || brand.brand_logo == undefined ? [] : */}
                                                            {/* <div > */}
                                                            <Link to={"/brand/" + brand.brand_slug}>
                                                                <CardImg src={imageLink + 'brand/' + brand.brand_logo} alt="" className="img-fluid d-block brand-img mx-auto my-3" />
                                                            </Link>
                                                            {/* </div> */}
                                                            {/* } */}
                                                        </div>
                                                        <div>
                                                            <Link to={"/brand/" + brand.brand_slug}>
                                                                <p className="text-center font-weight-bold">{brand.brand_name_en}</p>
                                                            </Link>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            )}

                                        </Slider>
                                    </Col>
                                </Row>
                                {/* <Row className="mb-3">
                                    <Col xl="4" sm="6"> */}
                                {/* <div className="mt-2">
                                            <h5>Clothes</h5>
                                        </div> */}
                                {/* </Col> */}
                                {/* <Col lg="8" sm="6">
                                        <Form className="mt-4 mt-sm-0 float-sm-right form-inline">
                                            <div className="search-box mr-2">
                                                <div className="position-relative"> */}
                                {/* <DataTabeSearch handleInputChange={this.handleInputChange} /> */}
                                {/* <Searchbox handleInputChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                            <Nav className="product-view-nav" pills>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '1' })}
                                                        onClick={() => { this.toggleTab('1'); }}
                                                    >
                                                        <i className="bx bx-grid-alt"></i>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '2' })}
                                                        onClick={() => { this.toggleTab('2'); }}
                                                    >
                                                        <i className="bx bx-list-ul"></i>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Form>
                                    </Col> */}
                                {/* </Row> */}
                                {/* <Row> */}
                                <div>
                                    <div className="features-border-bottom">
                                        <h1 className="home-header"><span className="text-primary font-weight-bold">All Products</span></h1>
                                    </div>
                                </div>
                                <Row>

                                    {
                                        allProduct.map((product, key) =>
                                            <Col xl="2" lg="3" sm="4" key={"_col_" + key} className="my-3">
                                                <Link to={"/product-details/" + product.product_slug}>
                                                    <Card >
                                                        <CardBody>
                                                            {product.product_stock_balance > 0 ?
                                                                " " : <div class="note no-stock">Out of stock</div>
                                                            }
                                                            {
                                                                product.is_delivery_charge_free == 1 ?
                                                                    <div className="free delivery">
                                                                        <span className='free-delivery'>Free Delivery</span>
                                                                    </div>
                                                                    : ""
                                                            }
                                                            <div className="product-img position-relative">
                                                                {
                                                                    !product.discount == 0 || !product.discount == null ?
                                                                        <div className="avatar-sm product-ribbon">
                                                                            <span className="avatar-title rounded-circle  bg-primary">
                                                                                {product.discount + "%"}
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div className="form-group">
                                                                    {product.feature_image[0].feature_image == null || product.feature_image[0].feature_image == undefined ? [] :
                                                                        <Link to={"/product-details/" + product.product_slug}>
                                                                            <img src={imageLink + 'upload/Products/' + product.product_id + '/featureImage/' + product.feature_image[0].feature_image} alt="" height="200" className="img-fluid mx-auto d-block" />
                                                                        </Link>
                                                                    }
                                                                </div>


                                                                {/* <img src={imageLink+product.feature_image[0].feature_image} alt="" className="img-fluid mx-auto d-block" /> */}
                                                            </div>
                                                            <div className="mt-4 text-center">
                                                                <h5 className="mb-3 text-truncate text-primary"><Link to={"/product-details/" + product.product_slug}>{product.product_name_en}</Link>
                                                                </h5>
                                                                <div className="text-muted mb-3">
                                                                    <StarRatings
                                                                        rating={product.rating}
                                                                        starRatedColor="#F1B44C"
                                                                        starEmptyColor="#2D363F"
                                                                        numberOfStars={5}
                                                                        name='rating'
                                                                        starDimension="14px"
                                                                        starSpacing="3px"
                                                                    />
                                                                </div>
                                                                {!product.product_market_price == null || !product.product_market_price == 0 ? product.product_market_price != product.product_sale_price ?
                                                                    <h5 className="my-0"><span className="text-muted mr-2">
                                                                        <del className="text-danger font-weight-bold">৳{product.product_market_price}</del></span> <b>৳{product.product_sale_price}</b>
                                                                    </h5> : <h5 className="my-0"><span className="text-muted mr-2">
                                                                    </span><b className="text-dark">৳{product.product_sale_price}</b>
                                                                    </h5> : ''
                                                                }
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </Col>
                                        )
                                    }
                                </Row>

                                <Row>
                                    <Col lg="12">
                                        <Pagination className="pagination pagination-rounded justify-content-center">
                                            {paginator &&
                                                <PaginationItem>
                                                    <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                                </PaginationItem>
                                            }
                                        </Pagination>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EcommerceProducts;