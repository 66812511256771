import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import UserProfile from "../pages/Authentication/UserProfile";
import CategoryCreate from "../pages/Settings/Category/CategoryCreate";
import LoanTypeCreate from "../pages/Settings/LoanTypes/LoanTypeCreate";
import MemberCreate from "../pages/Settings/Member/MemberCreate";

import IncomeExpenseTypeCreate from "../pages/Settings/IncomeExpenseType/IncomeExpenseTypeCreate";
import TransactionCreate from "../pages/Settings/Transaction/TransactionCreate";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

  // Dashboard
import Dashboard from "../pages/Dashboard/index";

//PetrolPump
import PetrolPumpList from "../pages/PetrolPump/PetrolPump/Index";
import PetrolPumpCreate from "../pages/PetrolPump/PetrolPump/Create";

import FuelList from "../pages/PetrolPump/Fuel/FuelCreate";
import FuelPumpList from "../pages/PetrolPump/FuelPumpMap/FuelPumpMapCreate";

//Product
import ProductList from "../pages/Product/Product/ProductIndex";
import ProductCreate from "../pages/Product/Product/ProductCreate";
//Product Category
import ProductCategoryList from "../pages/Product/ProductCategory/CategoryCreate";
//Product Brand
import ProductBrandList from "../pages/Product/ProductBrand/BrandCreate";
//Product Supplier
import ProductSupplierList from "../pages/Product/ProductSupplier/SupplierCreate";
//Product Warehouse
import WarehouseList from "../pages/Product/Warehouse/WarehouseCreate";
import WarehouseSupplierMapList from "../pages/Product/WarehouseSupplierMap/WarehouseSupplierMapCreate";
//Product Stock
import ProductStock from "../pages/Product/ProductStock/ProductStock";
//Product Order
import OrderList from "../pages/Order/OrderList";

//Banner
import BannerList from "../pages/Banner/Banner/BannerCreate";
import OrderDetails from "../pages/Order/OrderDetails";
import HomeFeatures from "../pages/HomeFeatures/HomeFeatures";
import HomeFeaturesMap from "../pages/HomeFeatures/HomeFeaturesMap";

//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProductDetail";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";
import EcommerceCategory from "../pages/Ecommerce/EcommerceCategory";
import EcommerceBrand from "../pages/Ecommerce/EcommerceBrand";
import EcommerceHelp from "../pages/Ecommerce/EcommerceHelp";
import EcommerceTermsCondition from "../pages/Ecommerce/EcommerceTermsCondition";
import EcommerceRegister from "../pages/Ecommerce/EcommerceRegister";
import EcommerceOrderSuccess from "../pages/Ecommerce/EcommerceOrderSuccess";
import EcommerceResetPass from "../pages/Ecommerce/EcommerceResetPass";
import EcommerceOrderTrack from "../pages/Ecommerce/EcommerceOrderTrack";
import BuyNow from "../pages/Ecommerce/BuyNow";
import EcommerceOrderFail from "../pages/Ecommerce/EcommerceOrderFail";
import EcommerceOrderCancel from "../pages/Ecommerce/EcommerceOrderCancel";
import EcommerceVoucher from "../pages/Ecommerce/Voucher/EcommerceVoucher";
import EcommerceVoucherDetails from "../pages/Ecommerce/Voucher/EcommerceVoucherDetails";
import VoucherBuyNow from "../pages/Ecommerce/Voucher/VoucherBuyNow";



const authProtectedRoutes = [

	// { path: "/dashboard", component: Dashboard },
	{ path: "/profile", component: UserProfile },
	// { path: "/category", component: CategoryCreate },
	// { path: "/transaction", component: TransactionCreate },
	// { path: "/incomeExpenseType", component: IncomeExpenseTypeCreate },
	// { path: "/loanType", component: LoanTypeCreate },
	// { path: "/member", component: MemberCreate },
	// { path: "/petrolPump", component: PetrolPumpList },
	// { path: "/petrolPumpCreate", component: PetrolPumpCreate },
	// { path: "/fuel", component: FuelList },
	// { path: "/fuelPumpMap", component: FuelPumpList },
	// { path: "/products", component: ProductList },
	// { path: "/productCreate", component: ProductCreate },
	// { path: "/productCategory", component: ProductCategoryList },
	// { path: "/productBrand", component: ProductBrandList },
	// { path: "/productSupplier", component: ProductSupplierList },
	// { path: "/warehouse", component: WarehouseList },
	// { path: "/warehouseSupplierMap", component: WarehouseSupplierMapList },
	// { path: "/productStock", component: ProductStock },
	// { path: "/banner", component: BannerList },
	// { path: "/order", component: OrderList },
	// { path: "/order-details/:id", component: OrderDetails },
	// { path: "/featuresCreate", component: HomeFeatures },
	// { path: "/featuresMap", component: HomeFeaturesMap },


		//Ecommerce
		{ path: "/home", component: EcommerceProducts },
		{ path: "/category", component: EcommerceCategory },
		{ path: "/brand", component: EcommerceBrand },
		{ path: "/product-details", component: EcommerceProductDetail },
		{ path: "/orders", component: EcommerceOrders },
		{ path: "/customers", component: EcommerceCustomers },
		{ path: "/cart", component: EcommerceCart },
		{ path: "/checkout", component: EcommerceCheckout },
		{ path: "/shops", component: EcommerceShops },
		{ path: "/add-product", component: EcommerceAddProduct },
		{ path: "/help", component: EcommerceHelp },
		{ path: "/terms-conditions", component: EcommerceTermsCondition },
		{ path: "/register", component: EcommerceRegister },
		{ path: "/forgot-password", component: EcommerceResetPass },
		{ path: "/order-success", component: EcommerceOrderSuccess },
		{ path: "/order-fail", component: EcommerceOrderFail },
		{ path: "/order-cancel", component: EcommerceOrderCancel },
		{ path: "/order-track", component: EcommerceOrderTrack},
		{ path: "/buynow", component: BuyNow},
		{ path: "/voucher", component: EcommerceVoucher},
		{ path: "/voucher-details", component: EcommerceVoucherDetails },
		{ path: "/voucher-buy", component:  VoucherBuyNow},

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/home" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	// { path: "/forgot-password", component: ForgetPwd },
	// { path: "/register", component: Register },

];


export { authProtectedRoutes, publicRoutes };
