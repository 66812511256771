import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table,FormGroup,Button } from "reactstrap";

//Import Image
import logoAutoT from "../../assets/images/AutoT-Logo-Color.png";
import {orderShow,statusList,createOrderStatus,orderConfirm} from '../../store/order/actions';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {AvField,AvForm} from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import order from "../../store/order/reducer";


const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class OrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
                orderDetails: {  
                },
            };
    }

    componentDidMount()
    {
        if (this.props.match.params.id) {
            this.fetchorderDetails(this.props.match.params.id);
        }
        if (this.props.match.params.id) {
            this.fetchconfirmOrder(this.props.match.params.id);
        }
        this.fetchstatusList();
    }



    fetchorderDetails = (order_id) => {

        orderShow(order_id).then((response) => {
            this.setState({
                orderDetails :response.data.orderDetails, 
            });
            this.state.orderDetails = response.data.orderDetails;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchconfirmOrder = (order_id) => {

        orderConfirm(order_id).then((response) => {
            this.setState({
                confirmOrders :response.data, 
            });
            this.state.confirmOrders = response.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchstatusList = () => {
        let options = {
            page: this.state.page,
            per_page: 100,
            search: this.state.search
        }
        statusList(options).then((response) => {
            this.setState({
                statusList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }
    //modal
      removeBodyCss() {
        document.body.classList.add("no_padding");
      }


       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchorderDetails(); 
            this.fetchconfirmOrder(); 
        });
       }
       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index,

            },() => {
                this.fetchorderDetails();
                this.fetchconfirmOrder(); 
            });
        }

        handleValidSubmit(event, values) {
            this.props.createOrderStatus(values);
            this.form && this.form.reset();
        }

    render() {

        const{name,address,amount,email,final_amount,order_code,order_id,order_date,order_type,phone,remarks,f_user_id,f_order_id,f_status_id}=this.state.orderDetails;
        
        const imageLink =process.env.REACT_APP_IMAGE_URL;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }

           let orderDetailsMap =[];
           if (this.state.hasOwnProperty('orderDetails')) {
            orderDetailsMap = this.state.orderDetails;
        }
        let statusListMap =[];
        if (this.state.hasOwnProperty('statusList')) 
        { statusListMap = this.state.statusList; 
        }

        let confirmOrderStatus = [];
        if (this.state.hasOwnProperty('confirmOrders')) 
        { confirmOrderStatus = this.state.confirmOrders; 
        }

        return (
            <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Invoices" breadcrumbItem="Detail" />

                    <Row>
                        <Col lg="8">
                            <Card>
                                <CardBody>
                                    <div className="invoice-title">
                                        <h4 className="float-right font-size-16">Order Code : {order_code}</h4>
                                        <div className="mb-4">
                                            <img src={logoAutoT} alt="logo" height="50" />
                                        </div>
                                    </div>
                                    <hr />
                                    <Row>
                                        <Col xs="6">
                                            <address>
                                                <strong>Billed To:</strong><br />
                                               <br />
                                                Phone : {phone}
                                            </address>
                                        </Col>
                                        <Col xs="6" className="text-right">
                                            <address>
                                                <strong>Shipped To:</strong><br />
                                                {address}<br />
                                            </address>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" className="mt-3">
                                            <address>
                                                <strong>Payment Method:</strong><br />
                                                {order_type}
                                            </address>
                                            <address>
                                                <strong>Order Current Status:</strong><br />
                                                {orderDetailsMap.status}
                                            </address>
                                        </Col>
                                      
                                        <Col xs="6" className="mt-3 text-right">
                                            <address>
                                                <strong>Order Date:</strong><br />
                                               {order_date}<br /><br />
                                            </address>
                                        </Col>
                                    </Row>
                                    <div className="py-2 mt-3">
                                        <h3 className="font-size-15 font-weight-bold">Order summary</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <Table className="table-nowrap">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Product Name</th>
                                                <th>Quantity</th>
                                                <th  className="text-right">Unit Price</th>
                                                <th  className="text-right">Total</th>
                                            </tr>
                                        </thead>
                                                <tbody>
                                                {orderDetailsMap.order_details != undefined && orderDetailsMap.order_details.map((order, index) =>
                                                    <tr>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{order.product.product_name_en}</td>
                                                        <td>{order.quantity}</td>
                                                        <td className="border-0 text-right">{order.unit_price}</td>
                                                        <td className="border-0 text-right">{order.total_amount}</td>
                                                    </tr>
                                                )} 
                                                 <tr>
                                                        <td colSpan="4" className="text-right">Sub Total</td>
                                                        <td className="text-right">{orderDetailsMap.final_amount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className="border-0 text-right">
                                                            <strong>Shipping</strong></td>
                                                        <td className="border-0 text-right">100</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className="border-0 text-right">
                                                            <strong>Total</strong></td>
                                                        <td className="border-0 text-right"><h4 className="m-0">{orderDetailsMap.final_amount+100}</h4></td>
                                                    </tr>
                                                </tbody>
                                        </Table>
                                    </div>
                                    <div className="d-print-none">
                                        <div className="float-right">
                                            <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                          
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4}>
                        <Card>
                        <Col lg="12">
                        <AvForm  style={{marginTop:'20px'}}className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}  ref={c => (this.form = c)}>
                                <AvField type="select"className="custom-select" label='Status List Type' name="f_status_id" id="f_status_id">
                                            <option defaultValue>Status List Type</option>
                                        {statusListMap.map((order_status,index)=>
                                            <option value={order_status.id}>{order_status.action_name}</option>
                                        )}
                                </AvField>
                        <div className="form-group">
                            <AvField name="remarks" label='remarks' value={remarks} className="form-control"  type="textarea" />
                        </div>

                        <div className="form-group ">
                            <AvField name="f_user_id"  value={f_user_id}  type="hidden"  />
                        </div>

                        
                        <div className="form-group ">
                            <AvField name="f_order_id"  value={order_id}  type="hidden"  />
                        </div>

                        
                        <div className="form-group ">
                            <AvField name="f_status_id"  value={f_status_id}  type="hidden"  />
                        </div>

                        <div className="float-right">
                            <Button type="submit" color="success">Submit</Button>
                        </div>
                        </AvForm>
                        </Col>
                        <Col>
                        <div xs="6" className="mt-3 text-center">
                            <h3 className="font-size-15 font-weight-bold">Order Confirmation Status</h3>
                        </div>
                        <Table className="table-nowrap" style={{marginTop:'20px'}}>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>User Id</th>
                                    <th>Order Id</th>
                                    <th>Status Id</th>
                                    <th>remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {confirmOrderStatus != undefined && confirmOrderStatus!=null && confirmOrderStatus.map((confirm, index) =>
                                    <tr>
                                        <td scope="row">{index+1}</td>
                                        <td>{confirm.f_user_id}</td>
                                        <td>{confirm.f_order_id}</td>
                                        <td>{confirm.f_status_id}</td>
                                        <td>{confirm.remarks}</td>
                                    </tr>
                                )} 
                            </tbody>
                        </Table>
                        </Col>
                        </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Order;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps,{createOrderStatus,orderShow,orderConfirm})(OrderDetails));
