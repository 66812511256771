import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { EDIT_PROFILE, SHOW_PROFILE } from './actionTypes';
import { profileSuccess, profileError, profileShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtProfile, postJwtProfile } from '../../../helpers/self_accounts_login_helper';
import { editJwtData } from '../../../helpers/self_accounts_settings_helper';


function* editProfile({ payload: { user } }) {
        // console.log('userData', user);
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(editJwtData,'user/vehicleOwnerUpdate/' + user.user_id,user);
            if (response.success == false) {
                yield put(profileError(response.message));
            }
            else if (response.success == true){
                // localStorage.setItem('authUser', JSON.stringify(response.data.cart));
                // console.log(' profile res', response)
                yield put(profileSuccess(response.message));
            }
        }
    } catch (error) {
        yield put(profileError(error));
    }
}


function* showProfile({ payload: { user_id } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(getJwtProfile, 'users/id/' + user_id);
            yield put(profileShow(response));
        }


    } catch (error) {
        yield put(profileError(error));
    }
}

export function* watchProfile() {
    yield takeEvery(EDIT_PROFILE, editProfile)
    yield takeEvery(SHOW_PROFILE, showProfile)
}

function* ProfileSaga() {
    yield all([
        fork(watchProfile),
    ]);
}

export default ProfileSaga;