import { getApi } from "../../services/httpService";
import {  CHECKOUT_PRODUCTS,CHECKOUT_ERROR, CHECKOUT_SUCCESS} from './actionTypes';


export const createCheckout = (data) => {
    return {
        type: CHECKOUT_PRODUCTS,
        payload: { data }
    }
}

export const checkoutSuccess = (response) => {
    return {
        type: CHECKOUT_SUCCESS,
        payload: response
    }
}

export const checkoutError = (error) => {
    return {
        type: CHECKOUT_ERROR,
        payload: error
    }
}


export function checkOut(data){
    return getApi().post('checkout',data,{
        
    })
}

export function paymentGateway(params){
    return getApi().get('list/paymentGateway',{
        params:params
    })
}
