import React, { Component } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, Form } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/AutoT-Logo-Color.png";
import logoDark from "../../assets/images/logo-dark.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";
// Redux Store
import { searchProduct, toggleRightSidebar } from "../../store/actions";

//i18n
import { withNamespaces } from 'react-i18next';
import { globalProductSearch, products } from "../../store/ecommerceProducts/actions";
import { DataTabeSearch } from "../../pages/Settings/Common/DataTabeSearch";
import { Searchbox } from "../../pages/Ecommerce/Searchbox";

const openSearch = () => {
  document.getElementById('search-overlay').style.display = "block";
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isSearch: false, search: "", screen: 'none', screen2: 'none' };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }


  handleInputChange = (e) => {
    let data = this.setState({ [e.target.name]: e.target.value });
    if (e.target.value != "" || e.target.value != null) {
      let temp = e.target.value ;
      temp = temp.replace(/\s+/g, '');
      this.fetchSearchList(temp);
    }

    // console.log({ [e.target.name]: e.target.value });
  }

  fetchSearchList = (data) => {
    if (data == "") {
      let screen = document.getElementById("suggestProduct").style.display = 'none';
      let screen2 = document.getElementById("list").style.display = 'none';
      this.setState({
        screen: screen,
        screen2: screen2
      });
    } else {
      let screen = document.getElementById("suggestProduct").style.display = 'block';
      let screen2 = document.getElementById("list").style.display = 'block';
      globalProductSearch(data).then(response => {
        this.setState({
          productList: response.data,
          screen: screen,
          screen2: screen2
        })
        this.state.productList = response.data
      })
    }


    // console.log('productList', this.state.productList)
  }

  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;
    let allProduct = [];
    if (this.state.hasOwnProperty('productList')) {
      allProduct = this.state.productList;
    }
    // console.log('all Products', allProduct);
    // const filterProduct = allProduct.filter(product => (product.product_name_en.includes(this.state.search.toLowerCase())));

    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" className="header-logo" />
                  </span>

                  <span className="logo-lg">
                    <img src={logoLightSvg} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightSvg} alt="" height="19" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-4  mx-4 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button>

              {/* <form className="app-search d-none d-lg-block" style={{marginLeft: '60px'}}>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> */}

              <Form className="app-search d-none d-lg-block d-md-block" >
                <div className="search-box mr-2">
                  <div>
                    {/* <DataTabeSearch handleInputChange={this.handleInputChange} /> */}
                    <Searchbox handleInputChange={this.handleInputChange} />
                    {/* <a onClick={this.openSearch}>hello</a> */}
                  </div>
                </div>
              </Form>
              <div id="suggestProduct">
                <ul id="list">
                  {allProduct != "" ?
                    allProduct.slice(0, 8).map(products =>
                      <li>
                        <a href={"/product-details/" + products.product_slug}>
                          <div className="d-flex align-items-center">
                            <div>
                              <img src={imageLink + 'upload/Products/' + products.product_id + '/featureImage/' + products.feature_image[0].feature_image} alt="" className="img-fluid search_img" />
                            </div>
                            <div className="ml-3">
                              <p>{products.product_name_en} </p>
                              <p className="text-danger">৳{products.product_sale_price} </p>
                            </div>

                          </div>
                        </a>
                      </li>
                    ) :
                    <li className="font-weight-bold text-center text-secondary py-2 mt-3">No product found!</li>
                  }
                </ul>
              </div>
            </div>


            <div className="d-flex">
              <div className="dropdown d-inline-block d-md-none ml-2">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  onClick={() => { this.setState({ isSearch: !this.state.isSearch }); }}>
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={this.state.isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={this.props.t('Search') + "..."}
                          aria-label="Recipient's username"
                          onChange={this.handleInputChange}
                        />
                        {/* <Searchbox handleInputChange={this.handleInputChange} /> */}
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* <LanguageDropdown /> */}



              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>

              <NotificationDropdown />

              <ProfileMenu />

              {/* <div className="dropdown d-inline-block">
                <button
                  onClick={this.toggleRightbar}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect" >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
      </React.Fragment >
    );
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
