import React, { Component } from "react";
import { CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createBrand,brandList,editBrand,productBrandImageUpload} from '../../../store/brand/actions';
import { categoryList} from '../../../store/category/actions';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class BrandCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                brand_logo:{},
                brand: {
                    
                },
                displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchbrandList();
            this.fetchCategoryList();    
    }

    fetchbrandList = () => {
        this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        brandList(options).then((response) => {
            this.setState({
                brandList :response.data, 
            });
            this.state.brandList = response.data;
            this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        })
        .catch((err) => {
            console.log(err);
        })
    }


    fetchCategoryList = () => {
        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        categoryList(options).then((response) => {
            this.setState({
                categoryList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (brand) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.brand = brand;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createBrand(values);
            this.fetchbrandList();
            this.form && this.form.reset();
            // window.location.reload(); 
        }

        handleImageUpload = (e) => {
            var id = e.target.id;
            var brand_id = this.state.brand.brand_id;
            let files = e.target.files;
    
                let reader = new FileReader(); 
                reader.readAsDataURL(files[0]); 
                reader.onload = (e)  => {
                    const formData = {file: e.target.result, name:files[0].name, type:files[0].type ,brand_id: brand_id};
                    console.log("brandbrand",formData.brand_id)
    
                    productBrandImageUpload(formData,formData.brand_id).then((response) => {
                        this.state.brand_logo = response.data.productBrandImage.brand_logo;
                        this.fetchbrandList();     
                        this.setState({
                            brand_logo : response.data.productBrandImage.brand_logo, 
                        });
                        // alert('Logo Uploaded Successfully');
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                }
           }
           handleEditImageSubmit(event, values) {  
            this.props.editBrand(values);
            this.setState(prevState => ({
             image_modal_scroll: !prevState.image_modal_scroll,
           }));
            // this.fetchbrandList();     
    }
       
       handleEditSubmit(event, values) {  
            this.props.editBrand(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchbrandList();
            this.form && this.form.reset();
            alert("Brand Updated Successfully"); 
            // window.location.reload();
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchbrandList();
            this.fetchCategoryList();
        });
       }

       handlePageClick = (e, index) => {
        this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}     
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchbrandList();
                this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}     
            });
        }

    render() {
        let categoryList = [];
        let brandListMap = [];
        let paginator;
        if (this.state.hasOwnProperty('categoryList')) { categoryList = this.state.categoryList; }
        if (this.state.hasOwnProperty('brandList')) {
            brandListMap = this.state.brandList.data;
            
            paginator = {
                'currentPage':this.state.brandList.current_page,
                'pagesCount':this.state.brandList.last_page,
                'total':this.state.brandList.total,
                'to':this.state.brandList.to,
                'from':this.state.brandList.from,
            }
        }
        const imageLink =process.env.REACT_APP_IMAGE_URL;
        const {brand_id,brand_code,brand_name_bn,brand_name_en,f_category_info_id,brand_logo} = this.state.brand;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
          console.log("this.props.success",this.props.success)
          if(this.props.success != ''){
            setTimeout(function() {
                window.location.reload();
            }, 1000);
             
          }
        return (
            <React.Fragment>
                    <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="brand" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Brand List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Brand Logo</th>
                                                        <th>Brand Name</th>
                                                        <th>Brand Name (Bangla)</th>
                                                        <th>Code</th>
                                                        <th>Category</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {brandListMap.map((brand, index) =>
                                                    <tr key={brand.brand_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>
                                                    <img src={imageLink+'brand/'+brand.brand_logo} alt="" height="50" />
                                                        
                                                        </td>
                                                        <td>{brand.brand_name_en}</td>
                                                        <td>{brand.brand_name_bn}</td>
                                                        <td>{brand.brand_code}</td>
                                                        <td>{brand.category == null?brand.f_category_info_id:brand.category.category_name_en}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(brand) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{brand_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }} ref={c => (this.form = c)}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Brand Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="form-group">
                                               <AvField name="brand_code" readonly label="Brand Code" value={brand_code} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group ">
                                               <AvField name="brand_name_en" label={reqLabel('Brand Name')} value={brand_name_en} className="form-control "  type="text" />
                                               <AvField name="brand_id" id="brand_id" value={brand_id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="brand_name_bn" label="Brand Name (Bangla)" value={brand_name_bn} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group">
                                                <AvField name="f_category_info_id" label="Category" value={f_category_info_id} type="select"className="custom-select" >
                                                    <option defaultValue>Select Category</option>
                                                        {categoryList.map((category,index)=>
                                                    <option value={category.category_id}>{category.category_name_en}</option>
                                                        )}
                                                </AvField>           
                                           </div>
                                           <div>
                                           <img src={imageLink+'brand/'+brand_logo} alt="" height="50" />
                                                <AvField  type="hidden" value={this.state.brand_logo} name="brand_logo" id="brand_logo"/>
                                                <AvField label="Brand Logo" type="file" className="form-control" name="brand_logo" id="brand_logo" onChange={(e) =>this.handleImageUpload(e)}/>
                                            </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Brand</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label={reqLabel('Brand Name')}  id="brand_name_en" name="brand_name_en" required />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Brand Name (Bangla)"  type="text" name="brand_name_bn" id="brand_name_bn" />
                                                 </div>
                                                 <div className="col-md-12">
                                                    <AvField type="select"className="custom-select" label={reqLabel('Category')} name={"f_category_info_id"} id={"f_category_info_id"} >
                                                        <option defaultValue>Select Category</option>
                                                            {categoryList.map((category,index)=>
                                                        <option value={category.category_id}>{category.category_name_en}</option>
                                                                            )}
                                                    </AvField>
                                                 </div> 
                                                 <div>
                                                 <img src={imageLink+'brand/'+brand_logo} alt="" height="50" />
                                                 <AvField  type="hidden" value={this.state.brand_logo} name="brand_logo" id="brand_logo"/>
                                                 <AvField label="Brand Image" type="file" className="form-control" name="brand_logo_0" onChange={(e) =>this.handleImageUpload(e)} id="brand_logo"/>
                                                 </div>
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Brand;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createBrand,editBrand })(BrandCreate));
