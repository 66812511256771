import { SUPPLIER_ERROR,SUPPLIER_SUCCESS,EDIT_SUPPLIER,SHOW_SUPPLIER,CREATE_SUPPLIER } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createSupplier = (supplier,response) => {
    return {
        type: CREATE_SUPPLIER,
        payload: { supplier },
        response
    }
}

export const showSupplier = (supplier_id) => {
    return {
        type: SHOW_SUPPLIER,
        payload: { supplier_id }
    }
}

export const editSupplier = (supplier) => {
    return {
        type: EDIT_SUPPLIER,
        payload: { supplier }
    }
}



export const supplierSuccess = (msg) => {
    return {
        type: SUPPLIER_SUCCESS,
        payload: msg
    }
}


export function supplierShow(supplier_id){
    return getApi().get('users/id/'+supplier_id)
}


export function supplierList(params){
    return getApi().get('supplier/list',{
        params: params
    })
}

export const supplierError = (error) => {
    return {
        type: SUPPLIER_ERROR,
        payload: error
    }
}
