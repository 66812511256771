import { getApi } from "../../services/httpService";



export function products(params){
    return getApi().get('product/list',{
        params: params
    })
}

export function productDetails(product_slug,params){
    return getApi().get('product/'+product_slug,{
        params: params
    })
}

export function cartDetails(cartID){
    return getApi().get('cart/details/'+cartID,{
        
    })
}

export function featuresProducts(params){
    return getApi().get('home/features/list',{
        params:params
    })
}

export function globalProductSearch(keyword){
    return getApi().get('product-search/'+keyword)
}