import { HOME_FEATURES_ERROR,HOME_FEATURES_SUCCESS,EDIT_HOME_FEATURES } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const HomeFeatures = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_HOME_FEATURES:
            state = { ...state }
            break;
        case HOME_FEATURES_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case HOME_FEATURES_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default HomeFeatures;