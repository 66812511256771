import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { BUY_NOW, BUY_SUCCESS, BUY_ERROR} from './actionTypes';

import { buySuccess, buyError } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData, editJwtData } from '../../helpers/self_accounts_settings_helper';

function* createBuy({ payload: { product } }) {
   
    try {
        
        let authchk = localStorage.getItem("jwtToken");

        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            // console.log('product', product)
            const response = yield call(postJwtData, 'order/buy/now', product);
            // console.log('response',response)
            // console.log("cart response",response);
            
            if (response.success == false) {
                // console.log('cart err',response.message)
                yield put(buyError(response.message));
            }else if(authchk != undefined && response.success == true){
                yield put(buySuccess(response.data));
                // console.log('cart data find', response.data);
                // localStorage.setItem('cartData', JSON.stringify(response.data.cart));
                // window.location.reload();
            }else{
                yield put(buyError('Unauthorized User!'));
            }            
          }
    } catch (error) {
        yield put(buyError(error));
    }
}




export function* watchBuy() {
    yield takeEvery(BUY_NOW, createBuy)
}

function* BuySaga() {
    yield all([
        fork(watchBuy),
    ]);
}

export default BuySaga;