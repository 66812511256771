import React, { Component } from "react";
import { CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../Settings/Common/DataTabeSearch'
import { Link } from "react-router-dom";
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {homefeaturesList,editHomeFeatures,createHomeFeatures} from '../../store/homefeatures/actions';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class HomeFeaturesCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                status:true,
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                features: {
                    
                },
                displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount()
    {
            this.fetchhomefeaturesList();
    }

    fetchhomefeaturesList = () => {
        this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        homefeaturesList(options).then((response) => {
            this.setState({
                homefeaturesList :response.data,
            });
            this.state.homefeaturesList = response.data;
            this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (features) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.features = features;
        console.log('hhdsh',this.state.features)
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createHomeFeatures(values);
            this.fetchhomefeaturesList();
            this.form && this.form.reset();
        }
       
       handleEditSubmit(event, values) {  
            this.props.editHomeFeatures(values);
            console.log('This Values',values); 
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchhomefeaturesList();
            alert("Home And Features Updated Successfully"); 
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchhomefeaturesList();
        });
       }

       handleChange(status) {
        this.setState({status});
        }

       handlePageClick = (e, index) => {
        this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}     
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchhomefeaturesList();
                this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}     
            });
        }

    render() {
        let homefeaturesListMap = [];
        let paginator;
        if (this.state.hasOwnProperty('homefeaturesList')) {
            homefeaturesListMap = this.state.homefeaturesList.data;
            
            paginator = {
                'currentPage':this.state.homefeaturesList.current_page,
                'pagesCount':this.state.homefeaturesList.last_page,
                'total':this.state.homefeaturesList.total,
                'to':this.state.homefeaturesList.to,
                'from':this.state.homefeaturesList.from,
            }
        }
        const {home_features_code,home_features_name_en,home_features_name_bn,home_features_id,status} = this.state.features;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
          console.log("this.props.success",this.props.success)
          if(this.props.success != ''){
            setTimeout(function() {
                window.location.reload();
            }, 1000);
             
          }
        return (
            <React.Fragment>
                    <div style={this.state.displayHide}> 
                        <h6 align="center">Loading...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Home and Features" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Home and Features List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Home and Features Code</th>
                                                        <th>Home and Features Name</th>
                                                        <th>Home and Features (Bangla)</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {homefeaturesListMap.map((features, index) =>
                                                    <tr key={features.home_features_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{features.home_features_code}</td>
                                                        <td>{features.home_features_name_en}</td>
                                                        <td>{features.home_features_name_bn}</td>
                                                        <td><Badge color={getBadge(features.status)}>{getStatusValue(features.status)}</Badge></td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(features) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                            <td><Link to={ `/features/map/create${features.home_features_id}`}><i className="fa fa-eye fa-2x"></i></Link></td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{home_features_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Home and Features Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="form-group">
                                               <AvField name="home_features_code" readOnly label="Home and Features Code" value={home_features_code} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group ">
                                               <AvField name="home_features_name_en" label={reqLabel('Home and Features Name')} value={home_features_name_en} className="form-control "  type="text"/>
                                               <AvField name="home_features_id" id="home_features_id" value={home_features_id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="home_features_name_bn" label="Home and Features Name(Bangla)" value={home_features_name_bn} className="form-control"  type="text" />
                                               <AvField name="home_features_id" id="home_features_id" value={home_features_id}  type="hidden"  />
                                           </div>
                                           <div>
                                                <Switch
                                                    name="status"
                                                    id="status"
                                                    value={status}
                                                    onChange={this.handleChange}
                                                    checked={this.state.status}
                                                    className="react-switch"
                                                    id="normal-switch"
                                                />
                                                <span style={{marginLeft:'20px'}}>{this.state.status ? 'Is Active' : 'Is Inactive'}</span>
                                                <AvField name="status" value={this.state.status}  type="hidden" />
                                            </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Home and Features</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label={reqLabel('Home and Features Name')}  id="home_features_name_en" name="home_features_name_en" required />
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Home and Features Name(Bangla)"  type="text" name="home_features_name_bn" id="home_features_name_bn" />
                                                 </div>
                                                <Switch
                                                    name="status"
                                                    id="status"
                                                    onChange={this.handleChange}
                                                    checked={this.state.status}
                                                    className="react-switch"
                                                    id="normal-switch"
                                                />
                                                <span>{this.state.status ? ' Is Active' : ' Is Inactive'}</span>
                                                <AvField name="status" value={this.state.status}  type="hidden" />
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.HomeFeatures;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createHomeFeatures,editHomeFeatures })(HomeFeaturesCreate));
