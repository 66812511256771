import { PRODUCT_ERROR,PRODUCT_SUCCESS,EDIT_PRODUCT,SHOW_PRODUCT,CREATE_PRODUCT } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createProduct = (product) => {
    return {
        type: CREATE_PRODUCT,
        payload: { product }
    }
}

export const showProduct = (product_id) => {
    return {
        type: SHOW_PRODUCT,
        payload: { product_id }
    }
}

export const editProduct = (product) => {
    return {
        type: EDIT_PRODUCT,
        payload: { product }
    }
}



export const productSuccess = (response) => {
    return {
        type: PRODUCT_SUCCESS,
        payload: response.data.message
    }
}


export function productShow(product_id){
    return getApi().get('users/id/'+product_id)
}

export function productImageUpload(params,image_id){
    if(image_id){
        return getApi().put('product/image/edit/'+image_id,params)
    }else{
        return getApi().post('product/image/upload',params);
    }
}

export function productList(params){
    return getApi().get('product/list',{
        params: params
    })
}

export function searchBrandwithCategory(keyword){
    return getApi().get('product/searchs/'+keyword)
}


export function discountList(params){
    return getApi().get('discount/list',{
        params: params
    })
}

export const productError = (error) => {
    return {
        type: PRODUCT_ERROR,
        payload: error
    }
}
