import React, { Component } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Label, Input, Form } from "reactstrap";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { registerUser, apiError, registerUserFailed, getOTP, GetOTP, OTPValidation, registration } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import { setAuthorizationToken } from "../../services/httpService";

class EcommerceRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success_msg: false,
            readOnlyMobile: 0,
            obj: {

            },
            reg: 1,
            otpCheck: 1,
            value: '',
            visitorId: 0,
            name: localStorage.getItem('Name'),
            showOrderBtn: false,
            minutes: 1,
            seconds: 0,

        };
        this.showOrderButton = this.showOrderButton.bind(this);

        // handleValidSubmit
        // this.handleValidSubmit = this.handleValidSubmit.bind(this);
        // this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    // handleValidSubmit
    // handleValidSubmit(event, values) {
    //     this.props.registerUser(values);
    // }

    setStateFromInput = (event) => {
        // console
        this.state.obj[event.target.name] = event.target.value;
        // this.setState.obj(this.obj);

        // this.setState({ value: event.target.value });
    };

    componentDidMount() {
        // this.props.apiError("");
        // this.props.registerUserFailed("");
        // this.sendPhone();
    }

    sendPhone = () => {
        // event.preventDefault();
        // this.state.obj[event.target.name] = event.target.value;
        let data = {
            vehicle_owner_mobile: this.state.obj.mobile,
            vehicle_owner_mobile_operator: this.state.obj.operator,
            f_action_status: 2,
            f_user_type: 2
        }
        // GetOTP(data);
        // console.log('data', GetOTP(data));
        if (data.vehicle_owner_mobile_operator == "" || data.vehicle_owner_mobile_operator == null) {
            toast.error('Please Select your mobile operator', { position: toast.POSITION.TOP_RIGHT });
        }


        else {
            getOTP(data).then(response => {
                // console.log('sendPhone', response);
                if (response.success == true && response.data.status == true) {
                    // alert("Temporary OTP : " + response.data.otp)
                    this.setState({
                        reg: 1,
                        otpCheck: 0,
                        readOnlyMobile: 1
                    })


                    // this.myInterval = setInterval(() => {
                    //     const { seconds, minutes } = this.state

                    //     if (seconds > 0) {
                    //         this.setState(({ seconds }) => ({
                    //             seconds: seconds - 1
                    //         }))
                    //     }
                    //     if (seconds === 0) {
                    //         if (minutes === 0) {
                    //             clearInterval(this.myInterval);
                    //             this.setState({
                    //                 reg: 1,
                    //                 otpCheck: 0,
                    //                 readOnlyMobile: 1
                    //             })
                    //         } else {
                    //             this.setState(({ minutes }) => ({
                    //                 minutes: minutes - 1,
                    //                 seconds: 59
                    //             }))
                    //         }
                    //     }
                    // }, 1000)
                    // alert(response.data.data.message)
                    // this.setState.readOnlyMobile(1);
                    // this.setState.otpCheck(0);
                } else if (response.data.status == false) {      
                    this.setState({
                        reg: 1,
                        otpCheck: 1,
                    })
                    toast.error('User Already Exists!', { position: toast.POSITION.TOP_RIGHT });

                    // alert('User Already Exists!');
                }
            })
        }
    }

    sendOTP = () => {
        let data = {
            vehicle_owner_mobile: this.state.obj.mobile,
            vehicle_owner_mobile_operator: this.state.obj.operator,
            otp_code: this.state.obj.otp,
            f_action_status: 4,
            f_user_type: 2
        }

        if (data.otp_code == undefined || data.otp_code == null || data.otp_code == "") {
            toast.error('OTP is required!', { position: toast.POSITION.TOP_RIGHT });

            // alert('OTP is required ');
        } else {
            OTPValidation(data).then(response => {
                if (response.data.status == true) {
                    toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
                    // alert(response.data.message);
                    this.setState({
                        reg: 0,
                        otpCheck: 1,
                    })
                } else if (response.data.status == false) {
                    this.setState({
                        reg: 1,
                        otpCheck: 1,
                        readOnlyMobile: 0
                    })
                    // this.setState.readOnlyMobile(0);
                    toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });

                    // alert(response.data.message);
                }
            })
        }


    }

    showOrderButton() {
        this.setState({
            showOrderBtn: !this.state.showOrderBtn
        });
    }

    sendRegistrationData = () => {
        let data = {
            mobile: this.state.obj.mobile,
            vehicle_owner_mobile_operator: this.state.obj.operator,
            vehicle_owner_name_en: this.state.obj.fullName,
            vehicle_owner_email: this.state.obj.email || null,
            password: this.state.obj.password,
            f_action_status: 4,
            f_user_type: 2
        }
        if (data.vehicle_owner_name_en == undefined || data.vehicle_owner_name_en == null || data.vehicle_owner_name_en == "") {
            toast.error('Name is required', { position: toast.POSITION.TOP_RIGHT });
            // alert('Name is required ')
        } else if (data.password == undefined||data.password == null || data.password == "") {
            toast.error('Password is required ', { position: toast.POSITION.TOP_RIGHT });

            // alert('Password is required ')
        }
        else {
            registration(data).then(response => {


                if (response.success == true) {
                    toast.success("User Created Successfully!", { position: toast.POSITION.TOP_CENTER });
                    setTimeout(
                        this.props.history.push('/home'), 1000
                    );
                }
            })

        }

    }



    render() {
        // const { minutes, seconds } = this.state
        return (
            <React.Fragment>

                {/* <div className="account-pages my-5 pt-sm-5 ">
                   
                </div> */}
                {/* {this.state.success_msg ? (
                    <SweetAlert
                        title="Good job!"
                        success
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => this.setState({ success_msg: false })}
                    >
                    </SweetAlert>
                ) : null} */}
                <div className="account-pages my-5 pt-sm-5 ">
                    <Container>
                        <Row className="justify-content-center row my-5">
                            <Col lg={10}>
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Create your AutoT Account</h5>
                                                    <p>Hovata Technologies</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profileImg} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={logoImg}
                                                            alt=""
                                                            className="rounded-circle"
                                                            height="34"
                                                        />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal my-2"
                                            >
                                                {/* {this.props.user && this.props.user ? (
                                                    <Alert color="success">
                                                        Register User Successfully
                                                    </Alert>
                                                ) : null}
                                                {this.props.registrationError &&
                                                    this.props.registrationError ? (
                                                    <Alert color="danger">
                                                        {this.props.registrationError}
                                                    </Alert>
                                                ) : null} */}
                                                <Row>
                                                    <Col md="5">
                                                        <AvField type="number" name="mobile" onChange={this.setStateFromInput} className="form-control" id="mobile" placeholder="Mobile No"
                                                            required readOnly={this.state.readOnlyMobile} />
                                                        <small className="text-danger ml-1 mb-2">Required*</small>
                                                    </Col>
                                                    <Col md="4">
                                                        <AvField type="select" className="form-control" id="operator" name="operator" onChange={this.setStateFromInput} required readOnly={this.state.readOnlyMobile}>
                                                            <option value="" >Select Operator</option>
                                                            <option value="GrameenPhone">Grameen Phone</option>
                                                            <option value="Robi">Robi</option>
                                                            <option value="Airtel">Airtel</option>
                                                            <option value="Banglalink">Banglalink</option>
                                                            <option value="Teletalk">Teletalk</option>
                                                        </AvField>
                                                    </Col>

                                                    <Col md="3" style={(this.state.readOnlyMobile == 1) ? { display: 'none' } : {}}>
                                                        <a href="#" onClick={this.sendPhone} className="btn btn-success">Get OTP</a>
                                                    </Col>
                                                </Row>
                                            </AvForm>

                                            <AvForm style={(this.state.otpCheck == 1) ? { display: 'none' } : {}} className='my-2'>
                                                <Row>
                                                    <Col md="8">
                                                        <AvField type="number" onChange={this.setStateFromInput} className="form-control" name="otp" id="otp" placeholder="OTP"
                                                            required />
                                                        {/* {minutes === 0 && seconds === 0
                                                            ? <small className="font-weight-bold">I don't receive any code <a href="#" onClick={this.sendPhone} className="text-primary">Resend OTP</a> </small>
                                                            : <small className="font-weight-bold text-dark">Resend OTP After: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</small>
                                                        } */}
                                                        {/* <p>Resend OTP is </p> */}

                                                        <br />
                                                        <small className="text-danger ml-1">Required*</small>
                                                    </Col>
                                                    <Col md="4">
                                                        <a href="#" onClick={this.sendOTP} className="btn btn-warning">Verify</a>
                                                    </Col>
                                                </Row>
                                            </AvForm>

                                            <AvForm className='my-2' style={(this.state.reg == 1) ? { display: 'none' } : {}}>
                                                <Row>
                                                    <Col md="6">
                                                        <Label for="fullName">Full Name</Label>
                                                        <AvField type="text" className="form-control" onChange={this.setStateFromInput} id="fullName" name="fullName" placeholder="Enter your Name"
                                                            required />
                                                        <small className="text-danger ml-1 mb-2">Required*</small>
                                                    </Col>
                                                    <Col md="6">
                                                        <Label for="email">email</Label>
                                                        <AvField type="text" className="form-control" onChange={this.setStateFromInput} id="email" name="email" placeholder="Enter your Email" />
                                                    </Col>
                                                    <Col md="6">
                                                        <Label for="review">Password</Label>
                                                        <AvField type="password" className="form-control" id="password" onChange={this.setStateFromInput} name="password" placeholder="Enter your password" required />
                                                        <small className="text-danger ml-1 mb-2">Required*</small>
                                                    </Col>
                                                    <Col md="6" className="custom-control custom-checkbox">
                                                        <div className="col-md-12 custom-control custom-checkbox">
                                                            <input onClick={this.showOrderButton} type="checkbox" className="custom-control-input ml-5" id="customControlInline" required />
                                                            <label className="custom-control-label" htmlFor="customControlInline">I agree Autot's <Link to="/help/terms-conditions" target="_blank"> Terms of Use </Link>, <Link to="/help/privacy-policy" target="_blank">Privacy Policy</Link> and <Link to="/help/return-refund" target="_blank">Return and Refund Policies</Link></label>
                                                        </div>
                                                        {/* <div className="my-2"> */}
                                                        {/* </div> */}
                                                        {
                                                            this.state.showOrderBtn == false ?
                                                                <button className="btn btn-primary my-2" disabled>Create An Account</button> :
                                                                <a href="#" className="btn btn-primary my-2" onClick={this.sendRegistrationData}>Create An Account</a>
                                                        }

                                                    </Col>

                                                </Row>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                {/* <div className="mb-5 text-center">
                                    <p>
                                        Already have an account ?{" "}
                                        <Link
                                            to="/home"
                                            className="font-weight-medium text-primary"
                                        >
                                            {" "}
                                            Login
                                        </Link>{" "}
                                    </p>

                                </div> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
};
export default withRouter(EcommerceRegister);
