import AvField from 'availity-reactstrap-validation/lib/AvField';
import React, { Component } from 'react'
import { toast } from 'react-toastify';
import { Container, Row, Col, Table, Input, Nav, NavItem, NavLink, TabContent, TabPane, Card, Form, FormGroup, Label, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap";
import { cityList, districtList, paymentGateway, thanaList } from '../../store/actions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { productDetails } from '../../store/ecommerceProducts/actions';
import { createBuy, buyNow } from '../../store/buy/actions';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { AvForm } from 'availity-reactstrap-validation';
import { courierList, courierPricing } from '../../store/courier/actions';
import {userProfile} from '../../store/auth/profile/actions';


class BuyNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            selectedGroup: null,
            obj: {

            },

            district: 1,
            thana: 1,
            paymentGetwayValue: 0,
            f_district_id: 1,
            f_division_id: 1,
            showOrderBtn: false,
            emailBoxShow: "none",
            displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }

        }
        this.toggleTab = this.toggleTab.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.showOrderButton = this.showOrderButton.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleSelectGroup = selectedGroup => {
        this.setState({ selectedGroup });
    };

    componentDidMount() {
        this.fetchDivisionList();
        this.fetchCourierList();
        this.fetchPaymentGateway();
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
                this.setState({
                    user: obj.data,
                    user_name: obj.user_name,
                    email: obj.email,
                    user_id: obj.user_id,
                });
                this.state.user = obj.data
                // this.state.user_name = obj.user_name;
                // console.log('user', this.state.user);
                // console.log('user_name', this.state.user_name);
            }
        }
        this.userProfileInfo();
        let buyNow = JSON.parse(localStorage.getItem('buyNow'));
        let product_slug = buyNow == null || buyNow == undefined && buyNow.productArray[0].product_slug == undefined ? 0 : buyNow.productArray[0].product_slug;
        this.fetchProducts(product_slug);
    }
    showOrderButton() {
        this.setState({
            showOrderBtn: !this.state.showOrderBtn
        });
    }

    showEmailBox1() {
        this.setState({
            emailBoxShow: "block"
        })
    }
    showEmailBox2() {
        this.setState({
            emailBoxShow: "none"
        })
    }

    userProfileInfo=()=>{
        userProfile().then(response=>{
            this.setState({
                profile:response.data,
            })
            this.selectDistrict(this.state.profile.userDetails.f_division_id==null || this.state.profile.userDetails.f_division_id==""?"":this.state.profile.userDetails.f_division_id);
            this.selectThana(this.state.profile.userDetails.f_zilla_id==null|| this.state.profile.userDetails.f_zilla_id==""?"":this.state.profile.userDetails.f_zilla_id);
            this.setState({
                f_division_id: this.state.profile.userDetails.f_division_id==null || this.state.profile.userDetails.f_division_id==""?"":this.state.profile.userDetails.f_division_id,
                f_district_id: this.state.profile.userDetails.f_zilla_id==null|| this.state.profile.userDetails.f_zilla_id==""?"":this.state.profile.userDetails.f_zilla_id,
            })
        })
    }


    fetchProducts = (product_slug) => {
        // console.log('product_Slug', product_slug)
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        // let options = {
        //     page: this.state.page,
        //     per_page: this.state.per_page,
        //     search: this.state.search
        // }
        productDetails(product_slug).then((response) => {
            // console.log('products here', response);
            this.setState({
                productDetails: response.data,
            });
            this.state.productDetails = response.data;
            // console.log('products', this.state.productDetails)
            this.setState({
                displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
            });
        })
            .catch(err => {
                console.log(err)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }


    fetchDivisionList = () => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        cityList()
            .then((response) => {
                this.setState({
                    divisionList: response.data,
                });
                this.state.divisionList = response.data;
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }

    selectDistrict = (city_id) => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        districtList(city_id)
            .then((response) => {
                this.setState({
                    district: 0,
                    thana: 1,
                    districtList: response.data,
                })
                this.state.districtList = response.data;
                // console.log('district',this.state.districtList)
                // this.setState({f_district_id:this.state.districtList})
                // console.log('district',this.state.f_district_id);
                // console.log('district', this.state.districtList);
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }

    selectThana = (district_id) => {
        this.setState({
            displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
        });
        thanaList(district_id)
            .then((response) => {
                this.setState({
                    thana: 0,
                    thanaList: response.data,
                })
                this.state.thanaList = response.data;
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
            })
    }
    fetchCourierList = ()=>{
        courierList().then(response=>{
            this.setState({
                courierList:response.data,
            })
        })
    }


    fetchPaymentGateway = () => {
        paymentGateway().then(response => {
            this.setState({
                paymentGateway: response.data,
            })
            this.state.paymentGateway = response.data
        })
            .catch(err => {
                console.log(err);
            })
    }

    handleChangePaymentType = (e) => {
        // console.log(e.target.value);
        // const { name, value } = e.target;
        // console.log('name',e.target.value);
        this.state.obj[e.target.name] = e.target.value
        // console.log('hi', this.state.obj[e.target.name]);
        // console.log(this.state.paymentGateway[index].payment_gateway_name, 'payment_gateway_name');
        // this.setState(this.state.obj[name]=e.target.value )
    };

    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.selectDistrict(e.target.value);
            this.setState({
                f_division_id:e.target.value
            })
            this.state.f_division_id=e.target.value
        }
        if (e.target.name == 'f_district_id') {
            this.selectThana(e.target.value);
            this.setState({
                f_district_id:e.target.value
            })
            this.state.f_district_id=e.target.value
        }
        if(e.target.name == 'f_courier_id')
        {
            this.setState({
                f_courier_id: e.target.value,
            })
            this.state.f_courier_id = e.target.value;
        }
        if(this.state.f_division_id !=""||  this.state.f_district_id !=""|| this.state.f_courier_id!=""){
            let data = {
                f_division_id:this.state.f_division_id,
                f_district_id:this.state.f_district_id,
                f_courier_id: this.state.f_courier_id
            }
            // console.log('data', data)
            this.fetchCourierPricing(data);
        }
    }
    fetchCourierPricing = (data)=>{
        // console.log('data1654641',data);
        courierPricing(data).then(response=>{
            this.setState({
                courierPricing:response.data,
            })
            this.state.courierPricing = response.data
            // console.log('courierPricing', this.state.courierPricing)
        })
    }
    onChangeValue(event) {

        this.setState({
            paymentGetwayValue: event.target.value,
        })
        this.setState.paymentGetwayValue = event.target.value;
    }


    handleValidSubmit(event, values) {

        // console.log('values', values);
        // console.log('checkout val', values.f_payment_gateway_id);
        let buyProduct = JSON.parse(localStorage.getItem('buyNow'))

        let productData = {
            total_quantity: buyProduct == null || buyProduct == 0 || buyProduct == "" ? '' : buyProduct.total_quantity,
            coupon_discount: 0,
            coupon_no: 0,
            f_action_status: 1,
            // f_coupon_id: 0,
            f_payment_gateway_id: values.f_payment_gateway_id,
            // coupon_no: "",
            f_coupon_id: 0,
            regular_discount: 0,
            wallet_discount: 0,
            total_amount: values.price,
            final_amount: values.grandTotal,
            currency: "BDT",
            f_unit_id: values.f_unit_id,
            order_type: values.f_payment_gateway_id,
            name: values.name,
            email: values.email,
            phone: values.mobile,
            amount: values.price,
            address: values.address,
            f_courier_id:values.f_courier_id,
            f_division_id: values.f_division_id == "" || values.f_division_id == null || values.f_division_id == undefined ? 0 : values.f_division_id,
            f_district_id: values.f_district_id == "" || values.f_district_id == null || values.f_district_id == undefined ? 0 : values.f_district_id,
            f_thana_id: values.f_thana_id == "" || values.f_thana_id == null || values.f_thana_id == undefined ? 0 : values.f_thana_id,
            total_delivery_charge: values.total_delivery_charge==""?0:values.total_delivery_charge,
            productArray: [
                {
                    f_product_id: values.f_product_id,
                    product_map_id: values.product_map_id == null || values.product_map_id == undefined || values.product_map_id == '' ? 0 : values.product_map_id,
                    quantity: values.quantity,
                    unit_price: values.unit_price,
                    unit_type: values.unit_type,
                    total_amount: values.price,
                    currency: "BDT",
                    discount_percen: values.discount_amount == null || values.discount_amount == undefined || values.discount_amount == '' ? 0 : values.discount_amount,
                    discount_amount: values.discount_amount == null || values.discount_amount == undefined || values.discount_amount == '' ? 0 : values.discount_amount,
                    total_discount: values.discount_amount == null || values.discount_amount == undefined || values.discount_amount == '' ? 0 : values.discount_amount,
                    f_action_status: 1
                }]
        }
        // console.log('productData',productData);

        if (values.name == "" || values.name == null) {
            toast.error('Please Enter your Name!', { position: toast.POSITION.TOP_RIGHT });

            // alert('Please Enter your Name!');
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "1"
                });
            }
        } else if (values.mobile == "" || values.mobile == null) {
            toast.error('Please Enter your Valid Mobile No!', { position: toast.POSITION.TOP_RIGHT });
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "1"
                });
            }
        } else if (values.address == "" || values.address == null) {
            toast.error('Please write your Details Delivery Address!', { position: toast.POSITION.TOP_RIGHT });
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "1"
                });
            }
        }
  
        else if (values.f_division_id == "" || values.f_division_id == null) {
            toast.error('Please Select your Division', { position: toast.POSITION.TOP_RIGHT });
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "1"
                });
            }
        }
        else if (values.f_district_id == "" || values.f_district_id == null) {
            toast.error('Please Select your District', { position: toast.POSITION.TOP_RIGHT });
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "1"
                });
            }
        }
    
        else if (values.f_thana_id == "" || values.f_thana_id == null) {
            toast.error('Please Select your thana', { position: toast.POSITION.TOP_RIGHT });
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "1"
                });
            }
        }
        else if (values.f_courier_id == "" || values.f_courier_id == null) {
            toast.error('Please select your courier!', { position: toast.POSITION.TOP_RIGHT });
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "1"
                });
            }
        }
        else if (values.f_product_id == "" || values.f_product_id == null) {
            toast.error('Please Add a product!', { position: toast.POSITION.TOP_RIGHT });
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "2"
                });
            }
        }
        else if (values.f_payment_gateway_id == "" || values.f_payment_gateway_id == null) {
            // alert('Please Select An Payment Gateway!');
            toast.error('Please Select An Payment Gateway!', { position: toast.POSITION.TOP_RIGHT });
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "3"
                });
            }
        }
        else if (values.f_payment_gateway_id == 3 && values.email == "") {
            toast.error('Please write email Address!', { position: toast.POSITION.TOP_RIGHT });
            if (this.state.activeTab) {
                this.setState({
                    activeTab: "3"
                });
            }
        }



        else if (values.f_payment_gateway_id == 1 && values.address != "" && values.mobile != "" && values.name != "" && values.f_product_id != "" && values.f_courier_id != "") {
            let r = window.confirm("Are you sure you wish to Confirm this Order?");
            if (r == true) {
                buyNow(productData).then(response => {

                    if (response.success == true) {
                        toast.success(response.data.message, { position: toast.POSITION.TOP_CENTER });
                        setTimeout(
                            this.props.history.push({
                                pathname: '/order-success',
                                state: { orderData: response.data.data }
                            }, 2000),
                            JSON.parse(localStorage.removeItem('buyNow'))
                        );
                        this.form && this.form.reset();
                    } else {
                        toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
                        // alert(response.data.message);
                    }
                })
            }
        }

        else if (values.f_payment_gateway_id == 3 && values.address != "" && values.mobile != "" && values.name != "" && values.f_product_id != "" && values.f_courier_id != "") {
            let r = window.confirm("Are you sure you wish to Confirm this Order?");
            if (r == true) {
                this.setState({
                    displayHide: { display: 'block', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                });
                // console.log("productData-2", productData);

                buyNow(productData).then(response => {
                    // console.log('product data ssl', response)

                    if (response.data.ssl_response.status == 'success') {
                        window.location.href = response.data.ssl_response.data;
                        JSON.parse(localStorage.removeItem('buyNow'));
                    }
                    else {
                        toast.error("Transaction" + response.data.ssl_response.status, { position: toast.POSITION.TOP_RIGHT });
                    }
                    this.setState({
                        displayHide: { display: 'none', position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000, }
                    });
                })
            }
        }
    }


    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        const { selectedGroup } = this.state;
        let buyProduct = JSON.parse(localStorage.getItem('buyNow'));
        let courierList = [];
        if(this.state.hasOwnProperty('courierList')){
            courierList = this.state.courierList;
        }
        let divisionLists = [];
        if (this.state.hasOwnProperty('divisionList')) {
            divisionLists = this.state.divisionList;
        }

        let selectDistricts = [];
        if (this.state.hasOwnProperty('districtList')) {
            selectDistricts = this.state.districtList;
            // console.log('district',selectDistricts)
        }

        let thanaLists = [];
        if (this.state.hasOwnProperty('thanaList')) {
            thanaLists = this.state.thanaList;
        }

        let profileInfo = [];
        let profileInfoDetails = [];
        if(this.state.hasOwnProperty('profile')){
            profileInfo = this.state.profile;
            profileInfoDetails =this.state.profile.userDetails;
        }

        let userInformation = [];
        let addressMap = [];
        let division = [];
        let district = [];
        let thana = [];
        if (this.state.hasOwnProperty('user')) {
            userInformation = this.state.user.user;
            // console.log('userInformation', userInformation)
            // address = this.state.user.userInfo.address;

        }
        if (this.state.hasOwnProperty('user')) {
            addressMap = this.state.user.userInfo;
            division = this.state.user.userInfo.division;
            district = this.state.user.userInfo.district;
            thana = this.state.user.userInfo.thana;
            // console.log('address',addressMap)
            // address = this.state.user.userInfo.address;

        }

        let cartDetailsList = [];
        let cartInfo = [];
        if (this.state.hasOwnProperty('cartDetails')) {
            cartDetailsList = this.state.cartDetails.cart_product_details;
            cartInfo = this.state.cartDetails;
            // console.log('cart info', cartDetailsList);
        }
        let paymentGateways = [];
        if (this.state.hasOwnProperty('paymentGateway')) {
            paymentGateways = this.state.paymentGateway;
        }
        let courierPricing =[];

        if(this.state.hasOwnProperty('courierPricing')){
            courierPricing = this.state.courierPricing.data;
                // console.log('sdhasvdfksd', courierPricing);
            
        }
        if (this.props.success != undefined || this.props.success != null || this.props.success != " ") {
            localStorage.setItem('chk data', JSON.stringify(this.props.success));

            // console.log("dddd")

            // window.location.reload();
        }

        let productDetail = buyProduct;
        // console.log('productDetails', productDetail);
        if (productDetail == null || productDetail == undefined || productDetail == '') {
            productDetail = 0;
        }


        let productInfo = [];
        let productImage = [];
        if (this.state.hasOwnProperty('productDetails')) {
            productInfo = this.state.productDetails.products;
            if (productInfo == null || productInfo == '' || productInfo == undefined) {
                productImage = ''
            } else {
                productImage = this.state.productDetails.products.feature_image[0].feature_image;
            }
            // console.log('productInfo', productImage)
        }
        let price;
        if (productInfo == null || productInfo == '' || productInfo == undefined) {
            price = 0
        }
        else {
            price = productInfo.product_sale_price * productDetail.total_quantity;
        }
        // console.log('district',this.props.district);
        // console.log('district',this.props.division);
        // console.log('thana',this.props.thana);

        let f_district_id = [];
        let f_division_id = [];


        selectDistricts.slice(0, 1).map(division =>
            f_division_id = division.f_division_id
        )

        thanaLists.slice(0, 1).map(district =>
            f_district_id = district.f_district_id
        )



        let total_delivery_charge=0;
        let totalWeight = 0;
        let totalQuantity = 0;
        totalQuantity = totalQuantity + productDetail.total_quantity;

        if (productInfo == null || productInfo == '' || productInfo == undefined) {
            totalWeight = 0
        } else {
            if (productInfo.is_delivery_charge_free == null) {
                totalWeight = totalWeight + productInfo.product_weight*totalQuantity;
            }
        }


        if (f_district_id == 1 && f_division_id == 3) {
            if (totalWeight > 1000) {
                // console.log('courierPricing.base_price_upto_one_kg', courierPricing.base_price_upto_one_kg, totalWeight)
                total_delivery_charge = (courierPricing == null || courierPricing == "" ? 0 : (courierPricing.base_price_upto_one_kg * 1) + (((Math.ceil((totalWeight-1000)/1000))*courierPricing.additional_price_per_kg * 1)))
            }
            else if (totalWeight == 0) {
                // console.log('totalWeight', totalWeight)
                total_delivery_charge = 0;
                // console.log('total_delivery_charge', total_delivery_charge)
            }
            else {
                // console.log('courierPricing.base_price_upto_one_kg', courierPricing.base_price_upto_one_kg)
                total_delivery_charge = (courierPricing == null || courierPricing == "" ? 0 : courierPricing.base_price_upto_one_kg * 1);
            }
        }

        else if (f_district_id != 1 && f_division_id == 3) {
            if (totalWeight > 1000) {
                total_delivery_charge = (courierPricing == null || courierPricing == "" ? 0 : ((courierPricing.base_price_upto_one_kg * 1) + (((Math.ceil((totalWeight-1000)/1000))*courierPricing.additional_price_per_kg * 1))));
            }
            else if (totalWeight == 0) {
                total_delivery_charge = 0;
            }

            else {
                total_delivery_charge = (courierPricing == null || courierPricing == "" ? 0 : courierPricing.base_price_upto_one_kg * 1);
            }
        }
        else if (f_district_id == 0 || f_division_id == 0 || f_district_id == '' && f_division_id == '') {
            total_delivery_charge = 0;
        }
        else {
            if (totalWeight > 1000) {
                total_delivery_charge = (courierPricing == null || courierPricing == "" ? 0 : ((courierPricing.base_price_upto_one_kg * 1) + (((Math.ceil((totalWeight-1000)/1000))*courierPricing.additional_price_per_kg * 1))));
            }
            else if (totalWeight == 0) {
                total_delivery_charge = 0;
            }
            else {
                total_delivery_charge = (courierPricing == null || courierPricing == "" ? 0 : courierPricing.base_price_upto_one_kg * 1);
            }
        }
        // console.log('total_delivery_charge', total_delivery_charge);

        let grandTotal = price + total_delivery_charge;
        let product_sale_price;
        if (productInfo == null || productInfo == '' || productInfo == undefined) {
            product_sale_price = 0;
        } else {
            product_sale_price = productInfo.product_sale_price
        }

        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary content-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Ecommerce" breadcrumbItem="Checkout" />
                        <div className="checkout-tabs">
                            <Row>
                                <Col lg="2" md="12" sm="12">
                                    <Nav className="flex-column" pills>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '1' })}
                                                onClick={() => { this.toggleTab('1'); }}
                                            >
                                                <i className="bx bxs-truck d-block check-nav-icon mt-4 mb-2"></i>
                                                <p className="font-weight-bold mb-4">Delivery Info</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                onClick={() => { this.toggleTab('2'); }}
                                            >
                                                <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2"></i>
                                                <p className="font-weight-bold mb-4">Confirmation</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '3' })}
                                                onClick={() => { this.toggleTab('3'); }}
                                            >
                                                <i className="bx bx-money d-block check-nav-icon mt-4 mb-2"></i>
                                                <p className="font-weight-bold mb-4">Payment Info</p>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col lg="10" md="12" sm="12">

                                    <Card>
                                        <CardBody>
                                            <AvForm onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} className="form-horizontal">
                                                <TabContent activeTab={this.state.activeTab}>
                                                    <TabPane tabId="1">
                                                        <div>
                                                            <CardTitle>Delivery information</CardTitle>
                                                            <CardSubtitle className="mb-3">Fill all information below</CardSubtitle>

                                                            <FormGroup className="mb-4" row>
                                                                <Label htmlFor="billing-name" md="2" className="col-form-label">Name<span className="text-danger">*</span></Label>
                                                                <Col md="10">
                                                                    <AvField type="text" value={profileInfo.name} name="name" className="form-control" id="billing-name" placeholder="Enter your name" />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup className="mb-4" row>
                                                                <Label htmlFor="billing-phone" md="2" className="col-form-label">Phone<span className="text-danger">*</span></Label>
                                                                <Col md={10}>
                                                                    <AvField type="text" value={profileInfo.mobile} name="mobile" className="form-control" id="billing-phone" placeholder="Enter your Phone no." />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup className="mb-4" row>
                                                                <Label htmlFor="billing-address" md="2" className="col-form-label">Address<span className="text-danger">*</span></Label>
                                                                <Col md={10}>
                                                                    <AvField type="text" value={profileInfoDetails.address} name="address" className="form-control" id="billing-address" placeholder="Enter your Address no." />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup className="mb-4" row>
                                                                <Label md="2" className="col-form-label">Division<span className="text-danger">*</span></Label>
                                                                <Col md="10">
                                                                    <AvField type="select" className="form-control select2" name="f_division_id" id="f_division_id" value={profileInfoDetails.f_division_id} onChange={(e) => this.handleInputChange(e)} title="Division">
                                                                        <option value="">Select Division</option>
                                                                        {divisionLists.map(division =>
                                                                            <option value={division.division_id}>{division.division_name}</option>
                                                                        )}
                                                                    </AvField>
                                                                </Col>
                                                            </FormGroup>

                                                            <FormGroup className="mb-4" row>
                                                                <Label md="2" className="col-form-label">District<span className="text-danger">*</span></Label>
                                                                <Col md="10">
                                                                    <AvField type="select" className="form-control select2" name="f_district_id" id="f_district_id" value={profileInfoDetails.f_zilla_id} onChange={(e) => this.handleInputChange(e)} disabled={this.state.district == 1} title="District">
                                                                        <option value="">
                                                                            Select District</option>
                                                                        {selectDistricts.map(district => (
                                                                            <option value={district.district_id}>{district.district_name}</option>
                                                                        ))}
                                                                    </AvField>
                                                                </Col>
                                                            </FormGroup>

                                                            <FormGroup className="mb-4" row>
                                                                <Label md="2" className="col-form-label">Thana<span className="text-danger">*</span></Label>
                                                                <Col md="10">
                                                                    <AvField type="select" name="f_thana_id" id="f_thana_id" className="form-control select2" value={profileInfoDetails.f_thana_id} disabled={this.state.thana == 1} title="thana">
                                                                        <option value="">
                                                                            Select Thana</option>
                                                                        {thanaLists.map(thana => (
                                                                            <option value={thana.thana_id}>{thana.thana_name}</option>
                                                                        ))}
                                                                    </AvField>
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup className="mb-4" row>
                                                                <Label md="2" className="col-form-label">Courier<span className="text-danger">*</span></Label>
                                                                <Col md="10">
                                                                    {courierList.map(courier =>
                                                                        <div className="custom-control custom-radio custom-control-inline mr-4">
                                                                            <Input type="radio" value={courier.id} id="f_courier_id" name="f_courier_id" required onChange={e => this.handleInputChange(e)} />
                                                                            <label>{courier.name}</label>
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup className="mb-0" row>
                                                                <Label htmlFor="postalCode" md="2" className="col-form-label">Postal Code</Label>
                                                                <Col md="10">
                                                                    <AvField className="form-control" name="postalCode" rows="3" placeholder="Write your postal code" ></AvField>
                                                                </Col>
                                                            </FormGroup>

                                                        </div>

                                                        <Row className="my-4">
                                                            <Col sm="6">
                                                                <Link to="/home" className="btn text-muted d-none d-sm-inline-block btn-link">
                                                                    <i className="mdi mdi-arrow-left mr-1"></i> Back to Shopping</Link>
                                                            </Col>
                                                            <Col sm="6">
                                                                <div className="text-sm-right">
                                                                    <Button type="submit" className="btn btn-success" onClick={() => this.toggleTab("2")}>
                                                                        <i className="mdi mdi-truck-fast mr-1"></i> Proceed to Confirmation </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </TabPane>
                                                    <TabPane tabId="2" id="v-pills-confir" role="tabpanel">
                                                        <Card className="shadow-none border mb-0">
                                                            <CardBody>
                                                                <CardTitle className="mb-4">Order Summary</CardTitle>
                                                                <AvField type="hidden" value={grandTotal} name="grandTotal" id="grandTotal" />
                                                                <AvField type="hidden" value={productInfo == null || productInfo == '' || productInfo == undefined ? 0 : productInfo.f_unit_id} name="f_unit_id" id="f_unit_id" />
                                                                <AvField type="hidden" value={total_delivery_charge} name="total_delivery_charge" id="total_delivery_charge" />
                                                                <AvField type="hidden" value={price} name="price" id="price" />
                                                                <AvField type="hidden" value={productDetail != 0 ? productDetail.productArray[0].discount_amount : 0} name="discount_amount" id="discount_amount" />
                                                                <AvField type="hidden" value={productDetail != 0 ? productDetail.productArray[0].quantity : 0} name="quantity" id="quantity" />
                                                                <AvField type="hidden" value={productDetail != 0 ? productDetail.productArray[0].product_map_id : 0} name="product_map_id" id="product_map_id" />
                                                                <AvField type="hidden" value={productDetail != 0 ? productDetail.productArray[0].unit_price : 0} name="unit_price" id="unit_price" />
                                                                <AvField type="hidden" value={productDetail != 0 ? productDetail.productArray[0].unit_type : 0} name="unit_type" id="unit_type" />
                                                                <AvField type="hidden" value={productDetail != 0 ? productDetail.productArray[0].f_product_id : 0} name="f_product_id" id="f_product_id" />
                                                                <AvField type="hidden" value={this.state.f_courier_id} name="f_courier_id" id="f_courier_id" />
                                                                <AvField type="hidden" value={this.state.paymentGetwayValue} name="f_payment_gateway_id" id="f_payment_gateway_name" />
                                                                <div className="table-responsive">
                                                                    <Table className="table-centered mb-0 table-nowrap">
                                                                        <thead className="thead-light">
                                                                            <tr>
                                                                                <th scope="col">Product</th>
                                                                                <th scope="col">Product Details</th>
                                                                                {/* <th scope="col">Product Weight</th> */}
                                                                                <th scope="col">Price</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            <tr key={"_orderSummary_"}>
                                                                                <td scope="row">
                                                                                    {productInfo == null || productInfo == '' || productInfo == undefined ?
                                                                                        '' : <img src={imageLink + 'upload/Products/' + productInfo.product_id + '/featureImage/' + productImage} alt="product-img" title="product-img" className="avatar-md" />
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <h5 className="font-size-14 text-truncate">{productInfo == null || productInfo == '' || productInfo == undefined ? '' : <Link to={"/product-details/" + productInfo.product_slug}>{productInfo.product_name_en}</Link>}</h5>
                                                                                    <p className="text-muted mb-0">৳ {product_sale_price} x {productDetail.total_quantity}</p>
                                                                                    <p>{productInfo != undefined && productInfo.is_delivery_charge_free == 1 ? <i className="mdi mdi-truck-fast mr-1 text-success"> <span className='ml-1'>Delivery Free</span></i> : ''}  </p>
                                                                                </td>
                                                                                {/* <td> <p className="text-muted mb-0">{productInfo.product_weight}x {productDetail.total_quantity}gm</p></td> */}
                                                                                <td>৳ {price}</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <h6 className="m-0 text-right">Sub Total:</h6>
                                                                                </td>
                                                                                <td>
                                                                                    ৳ {price}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <h6 className="m-0 text-right">Delivery Charge:</h6>
                                                                                </td>
                                                                                <td>
                                                                                    ৳ {total_delivery_charge}
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <h6 className="m-0 text-right">Grand Total :</h6>
                                                                                </td>
                                                                                <td>
                                                                                    ৳ {grandTotal}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>

                                                                </div>
                                                            </CardBody>
                                                        </Card>

                                                        <Row className="my-4">
                                                            <Col sm="6">
                                                                <Link to="/home" className="btn text-muted d-none d-sm-inline-block btn-link">
                                                                    <i className="mdi mdi-arrow-left mr-1"></i> Back to Shopping</Link>
                                                            </Col>
                                                            <Col sm="6">
                                                                <div className="text-sm-right">
                                                                    <Button type="submit" className="btn btn-success" onClick={() => this.toggleTab("3")}>
                                                                        <i className="mdi mdi-truck-fast mr-1"></i> Proceed to Payment </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="3" id="v-pills-payment" role="tabpanel" aria-labelledby="v-pills-payment-tab">
                                                        <div>
                                                            <CardTitle>Payment information</CardTitle>
                                                            <CardSubtitle className="mb-3">Fill all information below</CardSubtitle>
                                                            <div>
                                                                <div>
                                                                    <div onChange={this.onChangeValue}>
                                                                        {paymentGateways.map(gateway => (
                                                                            <div className="custom-control custom-radio custom-control-inline mr-4">
                                                                                <Input type="radio" value={gateway.payment_gateway_id} name="payment_gateway_name" required onClick={() => gateway.payment_gateway_id == "3" ? this.showEmailBox1() : this.showEmailBox2()} />
                                                                                <label>{gateway.payment_gateway_name}</label>

                                                                            </div>
                                                                        ))}

                                                                    </div>
                                                                    <div>
                                                                        <FormGroup className="mb-4" >
                                                                            <Label htmlFor="billing-email-address" md="2" className="col-form-label">Email Address{this.state.emailBoxShow == 'none' ? "" : <span className="text-danger"> *</span>}</Label>
                                                                            <Col md="8">
                                                                                <AvField type="email" value={profileInfo.email} name="email" className="form-control" id="billing-email-address" placeholder="Enter your email" />
                                                                            </Col>
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="text-center border-bottom border border-primary my-3">
                                                                    <h3 className="mt-2 text-success">Grand Total: ৳{grandTotal}</h3>
                                                                    <h5 className="mt-2">Expected Date of Delivery</h5>
                                                                    <p className="text-danger font-weight-bold">{new Date(new Date().setDate(new Date().getDate() + 3)).toDateString()} TO {new Date(new Date().setDate(new Date().getDate() + 5)).toDateString()}</p>
                                                                    <p>VAT Included, Where Applicable</p>
                                                                    
                                                                </div>
                                                                <h6>Delivery Timeline</h6>
                                                                <div className="my-3">
                                                                    <ul class="list-unstyled">
                                                                        <li className="mb-2"><div className="d-flex align-items-center"><i className="bx bx-envelope fa-1x mx-1 text-info"></i><span>Inside Dhaka: 3-5 working days (max)</span></div></li>
                                                                        <li className="mb-2"><div className="d-flex align-items-center"><i className="bx bx-envelope fa-1x mx-1 text-info"></i><span>Outside Dhaka: 7-10 working days (max)</span></div></li>
                                                                    </ul>
                                                                </div>
                                                            </div>


                                                            {/* <h5 className="mt-5 mb-3 font-size-15">For card Payment</h5> */}
                                                            {/* <div className="p-4 border">
                                                                <Form>
                                                                    <FormGroup className="mb-0">
                                                                        <Label htmlFor="cardnumberInput">Card Number</Label>
                                                                        <Input type="text" className="form-control" id="cardnumberInput" placeholder="0000 0000 0000 0000" />
                                                                    </FormGroup>
                                                                    <Row>
                                                                        <Col lg="6">
                                                                            <FormGroup className="mt-4 mb-0">
                                                                                <Label htmlFor="cardnameInput">Name on card</Label>
                                                                                <Input type="text" className="form-control" id="cardnameInput" placeholder="Name on Card" />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="3">
                                                                            <FormGroup className=" mt-4 mb-0">
                                                                                <Label htmlFor="expirydateInput">Expiry date</Label>
                                                                                <Input type="text" className="form-control" id="expirydateInput" placeholder="MM/YY" />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="3">
                                                                            <FormGroup className="mt-4 mb-0">
                                                                                <Label htmlFor="cvvcodeInput">CVV Code</Label>
                                                                                <Input type="text" className="form-control" id="cvvcodeInput" placeholder="Enter CVV Code" />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </div>  */}
                                                            <div className="col-md-12 custom-control custom-checkbox">
                                                                <input onClick={this.showOrderButton} type="checkbox" className="custom-control-input ml-5" id="customControlInline" required />
                                                                <label className="custom-control-label" htmlFor="customControlInline">I agree Autot's <Link to="/help/terms-conditions" target="_blank"> Terms of Use </Link>, <Link to="/help/privacy-policy" target="_blank">Privacy Policy</Link> and <Link to="/help/return-refund" target="_blank">Return and Refund Policies</Link></label>
                                                            </div>
                                                        </div>

                                                        <Row className="my-4">
                                                            <Col sm="6">
                                                                <Link to="/home" className="btn text-muted d-none d-sm-inline-block btn-link">
                                                                    <i className="mdi mdi-arrow-left mr-1"></i> Back to Shopping</Link>
                                                            </Col>
                                                            <Col sm="6">
                                                                <div className="text-sm-right">
                                                                    {/* {this.state.showOrderBtn ? */}
                                                                    <Button type="submit" className="btn btn-success" disabled={this.state.showOrderBtn == false}>
                                                                        <i className="mdi mdi-truck-fast mr-1"></i> Confirm Order</Button>
                                                                    {/* } */}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </TabPane>
                                                </TabContent>

                                            </AvForm>
                                        </CardBody>
                                    </Card>

                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Buy;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createBuy })(BuyNow));