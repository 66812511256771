import React, { Component } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Label, Input, Form } from "reactstrap";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { registerUser, apiError, registerUserFailed, getOTP, GetOTP, OTPValidation, registration, forgetPass } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import { setAuthorizationToken } from "../../services/httpService";
import { toast } from "react-toastify";

class EcommerceResetPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success_msg: false,
            readOnlyMobile: 0,
            obj: {

            },
            reg: 1,
            otpCheck: 1,
            value: '',
            visitorId: 0,
            name: localStorage.getItem('Name'),

        };

        // handleValidSubmit
        // this.handleValidSubmit = this.handleValidSubmit.bind(this);
        // this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    // handleValidSubmit
    // handleValidSubmit(event, values) {
    //     this.props.registerUser(values);
    // }

    setStateFromInput = (event) => {
        // console
        this.state.obj[event.target.name] = event.target.value;
        // this.setState.obj(this.obj);

        // this.setState({ value: event.target.value });
    };

    componentDidMount() {
        // this.props.apiError("");
        // this.props.registerUserFailed("");
        // this.sendPhone();
    }

    sendPhone = () => {
        // event.preventDefault();
        // this.state.obj[event.target.name] = event.target.value;
        let data = {
            vehicle_owner_mobile: this.state.obj.mobile,
            vehicle_owner_mobile_operator: this.state.obj.operator,
            f_action_status: 2,
            f_user_type: 2,
            forgot_password: 'ForgotPass',
        }
        // GetOTP(data);
        // console.log('data', GetOTP(data));

        getOTP(data).then(response => {
            // console.log('sendPhone', response);
            if (response.success == true && response.data.status == true) {
                // alert("Temporary OTP : " + response.data.otp)
                this.setState({
                    reg: 1,
                    otpCheck: 0,
                    readOnlyMobile: 1
                })
                // alert(response.data.data.message)
                // this.setState.readOnlyMobile(1);
                // this.setState.otpCheck(0);
            } else if (response.data.status == false) {
                this.setState({
                    reg: 1,
                    otpCheck: 1,
                    readOnlyMobile: 0
                })
                toast.error('Your are not authorized user!', { position: toast.POSITION.TOP_RIGHT });
                // alert('Your are not authorized user!');
            }
        })
    }

    sendOTP = () => {
        let data = {
            vehicle_owner_mobile: this.state.obj.mobile,
            vehicle_owner_mobile_operator: this.state.obj.operator,
            otp_code: this.state.obj.otp,
            f_action_status: 2,
            f_user_type: 2
        }
        if (data.otp_code == undefined || data.otp_code == null || data.otp_code == "") {
            toast.error('OTP is required ', { position: toast.POSITION.TOP_RIGHT });
            // alert('OTP is required ');
        } else {
            OTPValidation(data).then(response => {
                if (response.data.status == true) {
                    toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });

                    // alert(response.data.message);
                    this.setState({
                        reg: 0,
                        otpCheck: 1,
                    })
                } else if (response.data.status == false) {
                    this.setState({
                        reg: 1,
                        otpCheck: 1,
                        readOnlyMobile: 0
                    })
                    // this.setState.readOnlyMobile(0);
                    toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });

                    // alert(response.data.message);
                }
            })
        }
    }

    sendForgetPassData = () => {
        let data = {
            mobile: this.state.obj.mobile,
            vehicle_owner_mobile_operator: this.state.obj.operator,
            password: this.state.obj.newPassword,
            f_action_status: 1,
            f_user_type: 2
        }
        if (data.password == undefined || data.password == null) {
            toast.error('Password is required ', { position: toast.POSITION.TOP_RIGHT });

            // alert('Password is required ')
        }
        else if (this.state.obj.newPassword == this.state.obj.confirmPassword) {
            // console.log('forget Pass', data);
            forgetPass(data).then(response => {
                if (response.success == true) {
                    toast.success('Password Successfully Reset!', { position: toast.POSITION.TOP_CENTER });

                    // alert("Password Successfully Reset!");
                    setTimeout(
                        this.props.history.push('/home'), 1000
                    );
                }
                else {
                    toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
                    // alert(response.data.message);
                }
            })
        }
        else {
            toast.error('Sorry Password Not Matched', { position: toast.POSITION.TOP_RIGHT });

            // alert('Sorry Password Not Matched')
        }
    }


    render() {
        return (
            <React.Fragment>

                {/* <div className="account-pages my-5 pt-sm-5 ">
                
                </div> */}
                {/* {this.state.success_msg ? (
                    <SweetAlert
                        title="Good job!"
                        success
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => this.setState({ success_msg: false })}
                    >
                    </SweetAlert>
                ) : null} */}
                <div className="account-pages my-5 pt-sm-5 ">
                    <Container>
                        <Row className="justify-content-center row my-5">
                            <Col lg={10}>
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Forget Password</h5>
                                                    <p>Hovata Technologies</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profileImg} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={logoImg}
                                                            alt=""
                                                            className="rounded-circle"
                                                            height="34"
                                                        />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal my-2"
                                            >
                                                {/* {this.props.user && this.props.user ? (
                                                    <Alert color="success">
                                                        Register User Successfully
                                                    </Alert>
                                                ) : null}
                                                {this.props.registrationError &&
                                                    this.props.registrationError ? (
                                                    <Alert color="danger">
                                                        {this.props.registrationError}
                                                    </Alert>
                                                ) : null} */}
                                                <Row>
                                                    <Col md="5">
                                                        <Input type="number" name="mobile" onChange={this.setStateFromInput} className="form-control" id="mobile" placeholder="Mobile No"
                                                            required="" readOnly={this.state.readOnlyMobile} />
                                                    </Col>
                                                    <Col md="4">
                                                        <AvField type="select" className="form-control" id="operator" name="operator" onChange={this.setStateFromInput} required="" readOnly={this.state.readOnlyMobile}>
                                                            <option value="" >Select Operator</option>
                                                            <option value="GrameenPhone">Grameen Phone</option>
                                                            <option value="Robi">Robi</option>
                                                            <option value="Airtel">Airtel</option>
                                                            <option value="Banglalink">Banglalink</option>
                                                            <option value="Teletalk">Teletalk</option>
                                                        </AvField>
                                                    </Col>

                                                    <Col md="3" style={(this.state.readOnlyMobile == 1) ? { display: 'none' } : {}}>
                                                        <a href="#" onClick={this.sendPhone} className="btn btn-success">Get OTP</a>
                                                    </Col>
                                                </Row>
                                            </AvForm>

                                            <AvForm style={(this.state.otpCheck == 1) ? { display: 'none' } : {}} className='my-2'>
                                                <Row>
                                                    <Col md="8">
                                                        <AvField type="number" onChange={this.setStateFromInput} className="form-control" name="otp" id="otp" placeholder="OTP"
                                                            required="" />
                                                    </Col>
                                                    <Col md="4">
                                                        <a href="#" onClick={this.sendOTP} className="btn btn-warning">Verify</a>
                                                    </Col>
                                                </Row>
                                            </AvForm>

                                            <AvForm className='my-2' style={(this.state.reg == 1) ? { display: 'none' } : {}}>
                                                <Row>
                                                    <Col md="6">
                                                        <Label for="review">New Password</Label>
                                                        <AvField type="password" className="form-control" id="newPassword" onChange={this.setStateFromInput} name="newPassword" placeholder="Enter new password" required="" />
                                                    </Col>
                                                    <Col md="6">
                                                        <Label for="review">Confirm Password</Label>
                                                        <AvField type="password" className="form-control" id="confirmPassword" onChange={this.setStateFromInput} name="confirmPassword" placeholder="Confirm password" required="" />
                                                    </Col>
                                                    <Col md="6" className="custom-control custom-checkbox">
                                                        {/* <div className="my-2"> */}
                                                        {/* </div> */}
                                                        <a href="#" className="btn btn-primary my-2" onClick={this.sendForgetPassData}>Reset Password</a>

                                                    </Col>

                                                </Row>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                {/* <div className="mb-5 text-center">
                                    <p>
                                        Go Back to Login?{" "}
                                        <Link
                                            to="/home"
                                            className="font-weight-medium text-primary"
                                        >
                                            {" "}
                                            Login
                                        </Link>{" "}
                                    </p>

                                </div> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
};
export default withRouter(EcommerceResetPass);
